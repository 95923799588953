import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiService } from './api/api.service';

@Injectable({
  providedIn: 'root',
})
export class UploadService {
  constructor(private httpClient: HttpClient, private apiService: ApiService) {}

  public upload(event, path) {
    const formData = new FormData();
    formData.append('attachment', event);
    return this.httpClient.post<any>(`${this.apiService.getApiUrl()}/upload/${path}`, formData);
  }
}
