<kt-button
  (handle)='onDownload()'
  [color]='color'
  [onlyIcon]='onlyIcon'
  [loading]='isDownloading()'
  [changeToOnHover]='textOnHove ? text : undefined '
  [ngClass]="{ 'spinner spinner-center spinner-md spinner-primary': loading }"
>

  <i class='fas' [ngClass]="icon" [ngStyle]="getStyles()"></i>
  {{ onlyIcon && !textOnHove || textOnHove ? '' : text }}
</kt-button>
