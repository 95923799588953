import { Observable } from 'rxjs';
import { ApiService } from '../../api.service';
import { LengthAwarePaginator } from 'src/app/core/model/common/length-aware-paginator.model';
import { CreateRole, GetRoleResult, GetRoleWithPermissionsResult } from 'src/app/core/model/common';

export class RolesRoute {
  constructor(public apiService: ApiService) {
  }

  getUrl() {
    return `${this.apiService.getApiUrl()}/roles`;
  }

  getAll(params?: any): Observable<LengthAwarePaginator<GetRoleResult[]>> {
    return this.apiService.getHttp().get<LengthAwarePaginator<GetRoleResult[]>>(this.getUrl(), { params });
  }

  get(id: string): Observable<GetRoleWithPermissionsResult> {
    return this.apiService.getHttp().get<GetRoleWithPermissionsResult>(`${this.getUrl()}/${id}`);
  }

  create(data: CreateRole): Observable<GetRoleResult> {
    return this.apiService.getHttp().post<GetRoleResult>(this.getUrl(), data);
  }

  update(id: string, data: CreateRole): Observable<GetRoleResult> {
    return this.apiService.getHttp().put<GetRoleResult>(`${this.getUrl()}/${id}`, data);
  }

  delete(id: string): Observable<GetRoleResult> {
    return this.apiService.getHttp().delete<GetRoleResult>(`${this.getUrl()}/${id}`);
  }
}
