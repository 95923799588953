import { DateRange, MatDatepicker } from '@angular/material/datepicker';
import { AbstractControl, FormControl } from '@angular/forms';
import { AfterViewInit, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'kt-inline-ranged-date-picker',
  templateUrl: './inline-ranged-date-picker.component.html',
})
export class InlineRangedDatePickerComponent {

  @Input() selectedRangeValue: DateRange<Date> | undefined;
  @Input() maxDate: Date | undefined;
  @Input() minDate: Date | undefined;
  @Output() selectedRangeValueChange = new EventEmitter<DateRange<Date>>();

  selectedChange(m: any) {
    if (!this.selectedRangeValue?.start || this.selectedRangeValue?.end) {
      this.selectedRangeValue = new DateRange<Date>(m, null);
    }
    else {
      const start = this.selectedRangeValue.start;
      const end = m;
      if (end < start) {
        this.selectedRangeValue = new DateRange<Date>(end, start);
      }
      else {
        this.selectedRangeValue = new DateRange<Date>(start, end);
      }
    }
    this.selectedRangeValueChange.emit(this.selectedRangeValue);
  }
}
