import { ApiService } from '@core/services/api/api.service';
import { LengthAwarePaginator } from '@model/common/length-aware-paginator.model';
import { CreateOrUpdateCard, GetCardResult, GetCardSnapshotResult } from '@model/tenant/cards';
import { Observable } from 'rxjs';
import { BaseCrud } from 'src/app/core/services/api/routes/helpers/baseCrud';
import { InvoiceAdvance } from '@model/tenant/cards/invoice-advance.model';
import { AccountPayableModel } from '@model/accountsPayable/accountPayable.model';
import { UploadAttachmentModel } from '@model/uploadAttachment.model';
import { CardBillStatRoute } from '@core/services/api/routes/tenancy/card/card-bill-stat.route';

export class CardRoute extends BaseCrud {

  public bill: CardBillStatRoute;

  constructor(public apiService: ApiService) {
    super(apiService);
    this.bill = new CardBillStatRoute(this);
  }

  getUrl(): string {
    return `${this.apiService.getApiUrl()}/cards`;
  }

  getAll(params: any = { per_page: 99999 }): Observable<LengthAwarePaginator<GetCardResult[]>> {
    return this.apiService.getHttp().get<LengthAwarePaginator<GetCardResult[]>>(this.getUrl(), { params });
  }

  get(id: string): Observable<GetCardResult> {
    return this.apiService.getHttp().get<GetCardResult>(`${this.getUrl()}/${id}`);
  }

  create(data: CreateOrUpdateCard): Observable<GetCardResult> {
    return this.apiService.getHttp().post<GetCardResult>(this.getUrl(), data);
  }

  update(id: string, data: CreateOrUpdateCard): Observable<GetCardResult> {
    return this.apiService.getHttp().put<GetCardResult>(this.url(id), data);
  }

  setActive(id: string): Observable<GetCardResult> {
    return this.apiService.getHttp().post<GetCardResult>(this.url(id, 'active'), {});
  }

  setInactive(id: string): Observable<GetCardResult> {
    return this.apiService.getHttp().post<GetCardResult>(this.url(id, 'inactive'), {});
  }

  invoiceAdvance(id: string, data: InvoiceAdvance, receipt: UploadAttachmentModel): Observable<void> {
    return this._create(data, [ receipt ], this.url(id, 'invoice'));
  }

  // /cards/{card}/invoice/{cardBill}/{account}
  deleteInvoice(cardId: string, cardBillId: string, accountId: string): Observable<any> {
    return this.apiService.getHttp().delete<any>(this.url(cardId, 'invoice', cardBillId, accountId));
  }

  history(id: string): Observable<AccountPayableModel[]> {
    return this.apiService.getHttp().get<AccountPayableModel[]>(this.url(id, 'history'));
  }

  delete(id: string): Observable<void> {
    return this.apiService.getHttp().delete<void>(this.url(id));
  }
}
