import { ApiService } from '../../api.service';
import { Injectable } from '@angular/core';
import { BaseCrud } from '@core/services/api/routes/helpers/baseCrud';
import { DataImport } from '@model/tenant/migration';
import { iPaginationResponse } from '../../interfaces/pagination-response';
import { Observable } from 'rxjs';
import { ImportAction } from '@enum/tenant/migration';
import { FileInput } from 'ngx-material-file-input';

@Injectable({
  providedIn: 'root',
})
export class DataImportRoute extends BaseCrud {
  constructor(public api: ApiService) {
    super(api);
  }

  getUrl() {
    return `${this.api.getApiUrl()}/data-migration/import`;
  }

  list(params?: any) {
    return this.api.getHttp().get<DataImport[]>(this.getUrl(), { params });
  }

  exampleUrl(type: string) {
    return this.url('example') + '?type=' + type;
  }

  get(id) {
    return this.api.getHttp().get<DataImport>(this.url(id));
  }

  getData(id,
          page = 1,
          per_page = 25,
          search = '',
  ) {
    return this.api.getHttp().get(`${this.url(id)}/data?page=${page}&per_page=${per_page}&search=${search}`);
  }

  getErrors(id,
            page = 1,
            per_page = 25,
            search = '',
  ) {
    return this.api.getHttp().get(`${this.url(id)}/errors?page=${page}&per_page=${per_page}&search=${search}`);
  }

  store(data: any) {
    return this.api.getHttp().post(this.getUrl(), data);
  }

  updateFile(id: string, data: any) {
    return this.api.getHttp().post(this.url(id, 'update-file'), data);
  }

  importData(id: string) {
    return this.api.getHttp().post(`${this.url(id)}/import`, {});
  }

  cancel(id) {
    return this.api.getHttp().post(`${this.url(id)}/cancel`, {});
  }

  getAll(
    page = 1,
    per_page = 25,
    active = 1,
    search = '',
    sort: any = { sort_column: 'name', sort_direction: 'asc' },
  ): Observable<iPaginationResponse<DataImport>> {
    return this._getAll(
      `page=${page}&per_page=${per_page}&active=${active}&search=${search}&sort_column=${sort.sort_column}&sort_direction=${sort.sort_direction}`,
    );
  }

}
