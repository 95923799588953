import { CanActivateFn } from '@angular/router';
import { AppModule } from 'src/app/app.module';
import { TenancyService } from '@core/services/common';

export const RedirectToTenantGuard: CanActivateFn = (route, state) => {

  const tenancyService = AppModule.injector.get(TenancyService);
  const basePath = tenancyService.basePath.substring(1);

  const search = window.location.search.length > 0 ? window.location.search : '';
  window.location.href = window.location.origin + `/${basePath}${window.location.pathname}${search}`;

  return false;
};
