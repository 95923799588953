export class LogRequest {
  action?: string;
  end_date?: Date | string;
  page: number;
  per_page: number;
  search?: string;
  sort_column?: string;
  sort_direction?: string;
  start_date?: Date | string;
  user?: string;

  constructor(data: LogRequest) {
    this.action = data.action;
    this.end_date = data.end_date;
    this.page = data.page;
    this.per_page = data.per_page;
    this.search = data.search;
    this.sort_column = data.sort_column;
    this.sort_direction = data.sort_direction;
    this.start_date = data.start_date;
    this.user = data.user;
  }
}
