export const centralMenu = [
  {
    title: 'Início',
    root: true,
    icon: 'fas fa-home',
    page: '/admin/dashboard',
    translate: 'MENU.DASHBOARD',
    bullet: 'dot',
  },
  {
    section: 'Administrativo',
    permission: ['*', 'servicePlan.view', 'companies.view'],
  },
  {
    title: 'Empresas',
    root: false,
    icon: 'fas fa-building',
    page: '/admin/empresas',
    translate: 'MENU.DASHBOARD',
    permission: ['*', 'companies.view'],
    bullet: 'dot',
  },
  {
    title: 'Planos de serviço',
    root: true,
    icon: 'flaticon2-avatar',
    page: '/admin/planos',
    translate: 'MENU.DASHBOARD',
    permission: ['*', 'servicePlan.view'],
    bullet: 'dot',
  },
  {
    section: 'Configurações',
    permission: ['*'],
  },
  {
    title: 'Whatsapp',
    root: true,
    icon: 'fa-brands fa-whatsapp',
    page: '/admin/configuracao/whatsapp',
    permission: ['*'],
    bullet: 'dot',
  },
  { section: '.' },
  { section: '.' },
  { section: '.' },
  { section: '.' },
  { section: '.' },
  { section: '.' },
  { section: '.' },
  { section: '.' },
  {
    section: 'Histórico',
    permission: ['*'],
  },
  {
    title: 'Ações',
    permission: ['*'],
    root: true,
    icon: 'fas fa-book',
    bullet: 'dot',
    page: '/admin/historicos/acoes',
  },
  { section: '.' },
  { section: '.' },
];
