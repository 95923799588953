import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ApiService } from '@core/services/api';
import { BehaviorSubject, Observable, tap } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppModule } from 'src/app/app.module';
import { CheckSubdomainRes } from '@core/services/api/iResponse/check-domain';

@Injectable({
  providedIn: 'root',
})
export class TenancyService {
  private _apiUrl: string = environment.apiUrl;
  private _subdomain: string = undefined;
  private _company = new BehaviorSubject<CheckSubdomainRes>(null);

  public static pathTenant = '/painel';
  public static pathAdmin = '/admin';

  public static subdomainIndex = 0;

  get company(): Observable<CheckSubdomainRes> {
    return this._company.asObservable();
  }

  get apiUrl(): string {
    return this._apiUrl;
  }

  get subdomain(): string {
    return this._subdomain;
  }

  get isSubdomain(): boolean {
    return !!this._subdomain;
  }

  get basePath(): string {
    return this._subdomain ? TenancyService.pathTenant : TenancyService.pathAdmin;
  }

  get isTenant(): boolean {
    return this.isSubdomain;
  }

  checkSubdomain(subdomain: string): Observable<boolean> {
    const api = AppModule.injector.get(ApiService);

    return api.tenantRoute.checkSubdomain(subdomain).pipe(
      tap(_ => this._company.next(_)),
      map(_ => _.active),
    );
  }

  public makeUrl(subdomain?: string): void {
    const protocol = environment.apiUrl.split('://')[0];
    const configuredPort = environment.apiUrl.split(':')[2];
    const port = configuredPort ? `:${configuredPort}` : '';

    if (subdomain) {
      const url = this.fixUrl(environment.apiUrl);
      this._apiUrl = `${protocol}://${subdomain}.${url}${port}/`;
      this._subdomain = subdomain;
    }
    else {
      const url = this.fixUrl(environment.centralApiUrl);
      this._apiUrl = `${protocol}://${url}${port}/`;
    }
  }

  public appUrl(subdomain?: string): string {
    const protocol = window.location.protocol;
    const port = window.location.port;
    const isEighty = port === '80' || port === '443';
    const portString = isEighty ? '' : `:${port}`;

    if (subdomain) {
      const url = this.fixUrl(environment.appUrl);
      return `${protocol}//${subdomain}.${url}${portString}/`;
    }
    else {
      const url = this.fixUrl(environment.centralUrl);
      return `${protocol}//${url}${portString}/`;
    }
  }

  public makeSubdomain(hostname: string): string {
    const url = this.fixUrl(environment.centralUrl);
    if (hostname === url) {
      return;
    }

    const parts = hostname.split('.');
    const isLocalHost = parts.length === 1;
    const isIpAddress = Array.from(parts).filter(x => !isNaN(Number(x))).length === parts.length;

    const isNotDomain = isLocalHost || isIpAddress;
    if (isNotDomain) {
      return;
    }
    return parts[TenancyService.subdomainIndex];
  }

  private fixUrl(url: string) {
    return url
      .replace('http://', '')
      .replace('https://', '')
      .replace(/:\d+/, '');
  }
}
