import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NotificationType } from '@enum/common/notification-type.enum';
import ErrorHandler from './error-handle.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  constructor(private _snack: MatSnackBar) {
  }

  /**
   * Display message on screen, set duration and alert type.
   */
  public lol() {
    alert('lol');
  }

  public open(message: string, type?: NotificationType | number, duration?: number) {
    const _type = type !== undefined && typeof type !== 'number' ? type : NotificationType.DANGER;
    const _duration = typeof type === 'number' ? type : duration !== undefined ? duration : 5000;

    this._snack.open(message, null, {
      duration: _duration,
      horizontalPosition: 'right',
      verticalPosition: 'top',
      panelClass: [ _type ],
    });
  }

  public error(message: string, duration?: number) {
    return this.open(message, NotificationType.DANGER, duration);
  }

  public success(message: string, duration?: number) {
    return this.open(message, NotificationType.SUCCESS, duration);
  }

  public warning(message: string, duration?: number) {
    return this.open(message, NotificationType.WARNING, duration);
  }

  public info(message: string, duration?: number) {
    return this.open(message, NotificationType.INFO, duration);
  }

  public parseApiErrors(err: HttpErrorResponse) {
    ErrorHandler.getErrors(err).forEach(error => this.error(error));
  }

  public pipeApiErrors<T>() {
    return (observable: Observable<T>) => observable.pipe(
      catchError(err => {
          this.parseApiErrors(err);
          throw err;
        },
      ));
  }

}
