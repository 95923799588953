import { Observable } from 'rxjs';
import { ClientsModel } from 'src/app/core/model/clients.model';
import { EntityHasDocuments } from '../helpers/entityHasDocuments';
import { ApiService } from '../../api.service';
import { Injectable } from '@angular/core';
import { LengthAwarePaginator } from 'src/app/core/model/common/length-aware-paginator.model';
import { ClientRequest } from 'src/app/core/model/tenant/client';
import { iPaginationResponse } from '../../interfaces/pagination-response';
import { BaseCrud } from '@core/services/api/routes/helpers/baseCrud';
import { AccessKey } from '../../../../../views/pages/tenant/configuration/access-key/models/access-key.model';

@Injectable({
  providedIn: 'root',
})
export class AccessKeyRoute extends BaseCrud {


  constructor(public api: ApiService) {
    super(api);
  }

  getUrl() {
    return `${this.api.getApiUrl()}/access-keys`;
  }

  list() {
    return this.api.getHttp().get<AccessKey[]>(this.getUrl());
  }

  get(id) {
    return this.api.getHttp().get<AccessKey[]>(this.url(id));
  }

  permissions() {
    return this.api.getHttp().get<any>(this.url('permissions'));
  }

  store(data) {
    return this.api.getHttp().post(this.getUrl(), data);
  }

  update(id, data) {
    return this.api.getHttp().put(this.url(id), data);
  }

  delete(id) {
    return this.api.getHttp().delete(this.url(id));
  }
}
