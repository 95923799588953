<!--begin::Navigation-->
<ul class="navi navi-hover">
    <li class="navi-header pb-1">
        <span class="text-primary text-uppercase font-weight-bold">Add new:</span>
    </li>
    <li class="navi-item">
        <a href="#" class="navi-link">
            <span class="navi-icon"><i class="flaticon2-graph-1"></i></span>
            <span class="navi-text">Order</span>
        </a>
    </li>
    <li class="navi-item">
        <a href="#" class="navi-link">
            <span class="navi-icon"><i class="flaticon2-calendar-4"></i></span>
            <span class="navi-text">Event</span>
        </a>
    </li>
    <li class="navi-item">
        <a href="#" class="navi-link">
            <span class="navi-icon"><i class="flaticon2-layers-1"></i></span>
            <span class="navi-text">Report</span>
        </a>
    </li>
    <li class="navi-item">
        <a href="#" class="navi-link">
            <span class="navi-icon"><i class="flaticon2-calendar-4"></i></span>
            <span class="navi-text">Post</span>
        </a>
    </li>
    <li class="navi-item">
        <a href="#" class="navi-link">
            <span class="navi-icon"><i class="flaticon2-file-1"></i></span>
            <span class="navi-text">File</span>
        </a>
    </li>
</ul>
<!--end::Navigation-->
