import { BaseApi } from '../api/base-api';
import { AccessTokenResult, GetCurrentSessionResult } from '../../model/common';
import { BehaviorSubject, Observable, Subscription, finalize, iif, map, mergeMap, of, switchMap, tap } from 'rxjs';
import { TenancyService } from './tenancy.service';
import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { ApiService } from '../api';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private _currentUser = new BehaviorSubject<GetCurrentSessionResult>(null);
  private storageKey: string;

  isLoading = new BehaviorSubject<boolean>(false);
  currentUserObserver: Observable<GetCurrentSessionResult>;

  constructor(
    public apiService: ApiService,
    public tenancyService: TenancyService,
  ) {
    this.storageKey = `${this.tenancyService.subdomain ? this.tenancyService.subdomain : 'main'}.${environment.authTokenKey}`;

    this.getUserByToken().subscribe();
    this.currentUserObserver = this._currentUser.asObservable();
  }

  get currentUserLoaded(): boolean {
    return this._currentUser.value != null;
  }

  get currentUser(): GetCurrentSessionResult {
    return this._currentUser.value;
  }

  set currentUser(value: GetCurrentSessionResult) {
    this._currentUser.next(value);
  }

  login(email: string, password: string) {
    return this.apiService.auth.login(email, password).pipe(
      map((response) => new AccessTokenResult(response)),
      tap((result: AccessTokenResult) => {
        this.authFromLocalStorage = result.access_token;
      }),
      switchMap(() => this.getUserByToken()),
    );
  }

  loginByAccess(token: string) {
    this.authFromLocalStorage = token;
    this.getUserByToken().subscribe();
  }

  forgotPassword(email: string) {
    return this.apiService.auth.forgotPassword(email);
  }

  checkToken(token: string) {
    return this.apiService.auth.checkToken(token);
  }

  recoveryPassword(token: string, newPassword: string) {
    return this.apiService.auth.recoveryPassword(token, newPassword);
  }

  getUserByToken(): Observable<GetCurrentSessionResult> {
    const auth = this.authFormLocalStorage;
    if (!auth) {
      return of(undefined);
    }

    this.isLoading.next(true);
    return this.apiService.currentSession.user().pipe(
      mergeMap((user) => this.checkIsAuthenticated(user)),
      map((authenticated: GetCurrentSessionResult | boolean) => {
        if (authenticated) {
          this.currentUser = authenticated as GetCurrentSessionResult;
          return authenticated as GetCurrentSessionResult;
        }
        return undefined;
      }),
      finalize(() => this.isLoading.next(false)),
    );
  }

  private checkIsAuthenticated(user: GetCurrentSessionResult): Observable<GetCurrentSessionResult | boolean> {
    return iif(() => !!user, of(user), this.onLogoutHandler().pipe(map(() => false)));
  }

  onLogoutHandler(): Observable<void> {
    return new Observable((observer) => {
      localStorage.removeItem(this.storageKey);
      this.currentUser = undefined;
      observer.next();
    });
  }

  set authFromLocalStorage(authToken: string) {
    if (authToken) {
      localStorage.setItem(this.storageKey, authToken);
    }
  }

  get authFormLocalStorage(): string {
    return localStorage.getItem(this.storageKey);
  }
}
