import { ApiService } from '../../api.service';
import { Injectable } from '@angular/core';
import { BaseCrud } from '@core/services/api/routes/helpers/baseCrud';
import { DataExport } from '@model/tenant/migration';
import { iPaginationResponse } from '../../interfaces/pagination-response';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DataExportRoute extends BaseCrud
{
  constructor(public api: ApiService) {
    super(api);
  }

  getUrl() {
    return `${this.api.getApiUrl()}/data-migration/export`;
  }

  list(params?: any) {
    return this.api.getHttp().get<DataExport[]>(this.getUrl(), { params });
  }

  get(id) {
    return this.api.getHttp().get<DataExport[]>(this.url(id));
  }

  store(data) {
    return this.api.getHttp().post(this.getUrl(), data);
  }

  cancel(id) {
    return this.api.getHttp().post(`${this.url(id)}/cancel`, {});
  }

  download(id) {
    return this.api.getHttp().get(`${this.url(id)}/download`, { responseType: 'blob' });
  }

  urlDownload(id)
  {
    return `${this.getUrl()}/${id}/download`;
  }

  getAll(
    page = 1,
    per_page = 25,
    active = 1,
    search = '',
    sort: any = { sort_column: 'name', sort_direction: 'asc' },
  ): Observable<iPaginationResponse<DataExport>> {
    return this._getAll(
      `page=${page}&per_page=${per_page}&active=${active}&search=${search}&sort_column=${sort.sort_column}&sort_direction=${sort.sort_direction}`,
    );
  }

}
