import { ApiService } from "../../api.service";
import { iResponseCurrencyQuotation } from "../../iResponse/iResponseCurrencyQuotation";
import { BaseCrud } from "../helpers/baseCrud";

export class CurrencyQuotation extends BaseCrud {
  constructor(public api: ApiService) {
    super(api);
  }

  getUrl() {
    return `${this.api.getApiUrl()}/currecy-quotation`;
  }

  updateQuotations() {
    let http = this.api.getHttp();
    return http.put<iResponseCurrencyQuotation[]>(`${this.getUrl()}/update-all`, {});
  }

  getAll() {
    return this._getAll<iResponseCurrencyQuotation[]>();
  }
}
