<button
  mat-icon-button
  [matTooltip]="disabled ? this.disabledTitle : this.btnTitle"
  matTooltipClass="tooltip-dark"
  *ngIf='onlyIcon'
  (click)='onClick()'
  [disabled]='disabled || loading'
  [ngClass]="{ 'spinner spinner-center spinner-md spinner-primary': loading }"
  [class]='class'
  [color]='color'
>
  <ng-container [ngTemplateOutlet]='btnContent'></ng-container>
</button>


<button
  mat-raised-button
  [matTooltip]="disabled ? this.disabledTitle : this.btnTitle"
  matTooltipClass="tooltip-dark"
  *ngIf='!onlyIcon'
  (click)='onClick()'
  [disabled]='disabled || loading'
  [ngClass]="{ 'spinner spinner-center spinner-md spinner-primary': loading }"
  [class]='class'
  [color]='color'
  (mouseenter)='onHoverIn()'
  (mouseleave)='onHoverOut()'
>
  <div class='d-flex align-items-center gap-1'>
    <ng-container [ngTemplateOutlet]='btnContent'></ng-container>
  </div>
</button>

<ng-template #btnContent>
  <ng-container *ngIf='!hovered'>
    <ng-content></ng-content>
  </ng-container>
  <ng-container *ngIf='hovered'>
    {{ changeToOnHover }}
  </ng-container>
</ng-template>
