export class GetCurrentSessionResult {
  id: string;
  name: string;
  email: string;
  email_verified_at: Date;
  phone?: string;
  two_factor: boolean;
  two_factor_registered: boolean;
  two_factor_checked: boolean;
  role: string;
  google2fa_secret?: string;
  active: boolean;
  created_at: Date;

  company: {
    name: string;
    fantasy_name: string;
    identifier: string;
    cnpj: string;
    fantasy_name_sms: string;
  };

  constructor(data: GetCurrentSessionResult) {
    Object.assign(this, data);
  }
}
