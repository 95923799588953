import { Component, EventEmitter, Input, Output } from '@angular/core';
import { LayoutUtilsService } from 'src/app/core/_base/crud';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'kt-button-delete',
  templateUrl: './button-delete.component.html',
})
export class ButtonDeleteComponent {

  @Input() entity: any;
  @Output() delete = new EventEmitter();

  @Input() loading = new BehaviorSubject(false);
  @Input() isLoading = false;
  @Input() disabled = false;
  @Input() iconOnly = true;

  @Input() btnTitle = 'Deletar';
  @Input() btnDisabledTitle = 'Deletar';


  @Input() custom = false;

  @Input() title = '';
  @Input() description = '';
  @Input() waitDescription = 'Excluindo.';

  constructor(
    private layoutUtils: LayoutUtilsService,
  ) {

  }

  clickOnButton(): void {
    if (!this.entity) {
      return this.delete.emit();
    }

    const title = this.title ? this.title : `Excluir - ${this.handleTitle()}`;
    const description = this.description ? this.description : `Você tem certeza que deseja excluir?`;

    const dialogRef = this.layoutUtils.deleteElement(title, description, this.waitDescription);
    dialogRef.afterClosed().subscribe(res => {
      if (!res) {
        return;
      }

      this.delete.emit(this.entity);
    });
  }

  handleTitle(): string {
    return (this.entity.name ? this.entity.name : this.entity.statement) ?? '';
  }
}
