import { Observable } from 'rxjs';
import { CentralApiService } from '../../central-api.service';
import { GetEmailTypeResult, GetPhoneTypeResult } from 'src/app/core/model/common';
import { GetAddressTypeResult } from 'src/app/core/model/central';
import { LengthAwarePaginator } from '@model/common/length-aware-paginator.model';

export class TypesRoute {
  constructor(public apiService: CentralApiService) {
  }

  getAllEmailTypes(): Observable<LengthAwarePaginator<GetEmailTypeResult[]>> {
    return this.apiService.getHttp().get<LengthAwarePaginator<GetEmailTypeResult[]>>(
      `${this.apiService.getApiUrl()}/email-types`,
      { params: { per_page: 10e6 } },
    );
  }

  getAllPhoneTypes(): Observable<LengthAwarePaginator<GetPhoneTypeResult[]>> {
    return this.apiService.getHttp().get<LengthAwarePaginator<GetPhoneTypeResult[]>>(
      `${this.apiService.getApiUrl()}/phone-types`,
      { params: { per_page: 10e6 } },
    );
  }

  getAllAddressTypes(): Observable<GetAddressTypeResult[]> {
    return this.apiService.getHttp().get<GetAddressTypeResult[]>(
      `${this.apiService.getApiUrl()}/address-types`,
    );
  }
}
