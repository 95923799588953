import { Directive, OnDestroy, ElementRef } from '@angular/core';
import * as textMask from "vanilla-text-mask/dist/vanillaTextMask.js";

@Directive({
  selector: '[ktMaskDate]'
})
export class MaskDataDirective implements OnDestroy {

  maskedInputController;

  constructor(private element: ElementRef) {
    this.maskedInputController = textMask.maskInput({
      guide: false,
      inputElement: this.element.nativeElement,
      mask: [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/],
    });
  
  }
  

  ngOnDestroy() {
    this.maskedInputController.destroy();
  }

}