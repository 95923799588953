<kt-button [matMenuTriggerFor]='filterOptions'>
  {{ selectedOption }}
</kt-button>

<mat-menu #filterOptions='matMenu'>
  <button
    mat-menu-item
    *ngFor='let _ of (options | async)'
    [ngClass]='{ selected: selectedOption === _}'
    (click)='handleOption(_)'>
    {{ _ }}
  </button>
</mat-menu>
