import { Component, Input, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'kt-button-loop',
  templateUrl: './button-loop.component.html'
})
export class ButtonLoopComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
