import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import moment from 'moment';
import { Filter } from '@module/table/models/filter/filter.model';
import { DashboardFilter } from 'src/app/views/pages/tenant/dashboard/enum/filters.enum';
import { BaseComponent } from '@core/services/helpers/base-component.service';
import { OnPush } from '@core/macros/angular';

@Component({
  selector: 'kt-year-selector',
  templateUrl: './year-selector.component.html',
  styleUrls: [ './year-selector.component.scss' ],
  ...OnPush,
})
export class YearSelectorComponent extends BaseComponent implements OnInit {

  @Input() maxYear: number;
  @Input() minYear: number;
  @Input() startSelected: number;

  @Output() selectOption = new EventEmitter<string>();

  options = new BehaviorSubject<string[]>([]);

  selectedOption: string = null;

  ngOnInit(): void {
    this.createOptions();
  }

  private createOptions(): void {

    const date = moment();

    if (this.maxYear) {
      date.set('year', this.maxYear);
    }
    const length = (this.minYear ? date.year() - this.minYear : 5) + 1;

    const years = Array.from({ length }, (_, i) => i + 1)
      .map((_) => {
        return _ == 1 ? date.format('YYYY') : date.subtract(1, 'years').format('YYYY');
      });

    this.options.next(years);

    this.handleOption(this.startSelected ? this.startSelected : this.options.value[0]);
  }

  handleOption(year: string | number) {
    if (this.selectedOption === year) {
      return;
    }

    this.selectedOption = year as string;
    this.selectOption.emit(year as string);
  }
}
