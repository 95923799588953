<div class="card card-custom" [ngClass]="cssClasses">
    <!-- Header -->
    <div class="card-header border-0 mt-4">
        <h3 class="card-title align-items-start flex-column">
            <span class="font-weight-bolder text-dark">
                Atividades recentes
            </span>
        </h3>
        <div class="card-toolbar">
            <div ngbDropdown [placement]="'bottom-right'" class="dropdown dropdown-inline" title="Filtros">
                <a ngbDropdownToggle class="btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <i class="ki ki-bold-more-ver"></i>
                </a>
                <div ngbDropdownMenu class="dropdown-menu dropdown-menu-md dropdown-menu-right">
                    <kt-dropdown1></kt-dropdown1>
                </div>
            </div>
        </div>
    </div>
    <!-- Body -->
    <div class="card-body pt-4">
        <div class="timeline timeline-5 mt-3">
            <!--begin::Item-->
            <div class="timeline-item align-items-start">
                <!--begin::Label-->
                <div class="timeline-label font-weight-bolder text-dark-75 font-size-lg text-right pr-3">08:42</div>
                <!--end::Label-->

                <!--begin::Badge-->
                <div class="timeline-badge">
                    <i class="fa fa-genderless text-success icon-xxl"></i>
                </div>
                <!--end::Badge-->

                <!--begin::Text-->
                <div class="timeline-content text-dark-50">
                    Outlines of the recent activities that happened last weekend
                </div>
                <!--end::Text-->
            </div>
            <!--end::Item-->

            <!--begin::Item-->
            <div class="timeline-item align-items-start">
                <!--begin::Label-->
                <div class="timeline-label font-weight-bolder text-dark-75 font-size-lg text-right pr-3">3 hr</div>
                <!--end::Label-->

                <!--begin::Badge-->
                <div class="timeline-badge">
                    <i class="fa fa-genderless text-danger icon-xxl"></i>
                </div>
                <!--end::Badge-->

                <!--begin::Content-->
                <div class="timeline-content d-flex">
                    <span class="mr-4 font-weight-bolder text-dark-75">AEOL meeting with</span>

                    <!--begin::Section-->
                    <div class="d-flex align-items-start mt-n2">
                        <!--begin::Symbol-->
                        <a href="#" class="symbol symbol-35 symbol-light-success mr-2">
                            <span class="symbol-label">
                                <img alt="" src="./assets/media/svg/avatars/004-boy-1.svg" class="h-75 align-self-end" />
                            </span>
                        </a>
                        <!--end::Symbol-->

                        <!--begin::Symbol-->
                        <a href="#" class="symbol symbol-35 symbol-light-success">
                            <span class="symbol-label">
                                <img alt="" src="./assets/media/svg/avatars/002-girl.svg" class="h-75 align-self-end" />
                            </span>
                        </a>
                        <!--end::Symbol-->
                    </div>
                    <!--end::Section-->
                </div>
                <!--end::Content-->
            </div>
            <!--end::Item-->

            <!--begin::Item-->
            <div class="timeline-item align-items-start">
                <!--begin::Label-->
                <div class="timeline-label font-weight-bolder text-dark-75 font-size-lg text-right pr-3">14:37</div>
                <!--end::Label-->

                <!--begin::Badge-->
                <div class="timeline-badge">
                    <i class="fa fa-genderless text-info icon-xxl"></i>
                </div>
                <!--end::Badge-->

                <!--begin::Desc-->
                <div class="timeline-content font-weight-bolder text-dark-75">
                    Submit initial budget -
                    <a href="#" class="text-primary">USD 700</a>.
                </div>
                <!--end::Desc-->
            </div>
            <!--end::Item-->

            <!--begin::Item-->
            <div class="timeline-item align-items-start">
                <!--begin::Label-->
                <div class="timeline-label font-weight-bolder text-dark-75 font-size-lg text-right pr-3">16:50</div>
                <!--end::Label-->

                <!--begin::Badge-->
                <div class="timeline-badge">
                    <i class="fa fa-genderless text-danger icon-xxl"></i>
                </div>
                <!--end::Badge-->

                <!--begin::Text-->
                <div class="timeline-content text-dark-50">
                    Stakeholder meeting scheduling.
                </div>
                <!--end::Text-->
            </div>
            <!--end::Item-->

            <!--begin::Item-->
            <div class="timeline-item align-items-start">
                <!--begin::Label-->
                <div class="timeline-label font-weight-bolder text-dark-75 font-size-lg text-right pr-3">17:30</div>
                <!--end::Label-->

                <!--begin::Badge-->
                <div class="timeline-badge">
                    <i class="fa fa-genderless text-success icon-xxl"></i>
                </div>
                <!--end::Badge-->

                <!--begin::Text-->
                <div class="timeline-content text-dark-50">
                    Project scoping & estimations with stakeholders.
                </div>
                <!--end::Text-->
            </div>
            <!--end::Item-->

            <!--begin::Item-->
            <div class="timeline-item align-items-start">
                <!--begin::Label-->
                <div class="timeline-label font-weight-bolder text-dark-75 font-size-lg text-right pr-3">21:03</div>
                <!--end::Label-->

                <!--begin::Badge-->
                <div class="timeline-badge">
                    <i class="fa fa-genderless text-warning icon-xxl"></i>
                </div>
                <!--end::Badge-->

                <!--begin::Desc-->
                <div class="timeline-content font-weight-bolder text-dark-75">
                    New order placed <a href="#" class="text-primary">#XF-2356</a>.
                </div>
                <!--end::Desc-->
            </div>
            <!--end::Item-->

            <!--begin: Item-->
            <div class="timeline-item align-items-start">
                <!--begin::Label-->
                <div class="timeline-label font-weight-bolder text-dark-75 font-size-lg text-right pr-3">21:07</div>
                <!--end::Label-->

                <!--begin::Badge-->
                <div class="timeline-badge">
                    <i class="fa fa-genderless text-danger icon-xxl"></i>
                </div>
                <!--end::Badge-->

                <!--begin::Text-->
                <div class="timeline-content text-dark-50">
                    Company BBQ to celebrate the last quater achievements and goals.
                </div>
                <!--end::Text-->
            </div>
            <!--end: Item-->

            <!--begin::Item-->
            <div class="timeline-item align-items-start">
                <!--begin::Label-->
                <div class="timeline-label font-weight-bolder text-dark-75 font-size-lg text-right pr-3">20:30</div>
                <!--end::Label-->

                <!--begin::Badge-->
                <div class="timeline-badge">
                    <i class="fa fa-genderless text-info icon-xxl"></i>
                </div>
                <!--end::Badge-->

                <!--begin::Text-->
                <div class="timeline-content text-dark-50">
                    Marketing campaign planning with customer.
                </div>
                <!--end::Text-->
            </div>
            <!--end::Item-->
        </div>
        <!--end: Items-->
    </div>
</div>
