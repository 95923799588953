import { Observable } from 'rxjs';
import { CentralApiService } from '../../central-api.service';
import { CreateServicePlan, GetServicePlanResult } from 'src/app/core/model/central/servicePlans';
import { LengthAwarePaginator } from 'src/app/core/model/common/length-aware-paginator.model';

export class ServicePlansRoute {
  constructor(public apiService: CentralApiService) {
  }

  getUrl(): string {
    return `${this.apiService.getApiUrl()}/service-plans`;
  }

  getAll(params?: any): Observable<LengthAwarePaginator<GetServicePlanResult[]>> {
    return this.apiService.getHttp().get<LengthAwarePaginator<GetServicePlanResult[]>>(this.getUrl(), { params });
  }

  get(id: string): Observable<GetServicePlanResult> {
    return this.apiService.getHttp().get<GetServicePlanResult>(`${this.getUrl()}/${id}`);
  }

  create(data: CreateServicePlan): Observable<GetServicePlanResult> {
    return this.apiService.getHttp().post<GetServicePlanResult>(this.getUrl(), data);
  }

  update(id: string, data: CreateServicePlan): Observable<GetServicePlanResult> {
    return this.apiService.getHttp().put<GetServicePlanResult>(`${this.getUrl()}/${id}`, data);
  }

  active(id: string): Observable<GetServicePlanResult> {
    return this.apiService.getHttp().post<GetServicePlanResult>(`${this.getUrl()}/${id}/active`, {});
  }

  inactive(id: string): Observable<GetServicePlanResult> {
    return this.apiService.getHttp().post<GetServicePlanResult>(`${this.getUrl()}/${id}/inactive`, {});
  }

  delete(id: string): Observable<GetServicePlanResult> {
    return this.apiService.getHttp().delete<GetServicePlanResult>(`${this.getUrl()}/${id}`);
  }
}
