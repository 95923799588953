import { Observable } from 'rxjs';
import { InternsModel } from 'src/app/core/model/interns.model';
import { EmployeesGraduationLevels } from 'src/app/core/model/employeesGraduationLevels';
import { SituationCourse } from 'src/app/core/model/SituationCourse';
import { EmployeesWorkspaces } from 'src/app/core/model/employeesWorkspaces.model';
import { UploadAttachmentModel } from 'src/app/core/model/uploadAttachment.model';
import { EntityHasDocuments } from '../helpers/entityHasDocuments';
import { ApiService } from '../../api.service';
import { iResponseEmployeeAttachments } from '../../iResponse/iEmployeesResponse';
import { LengthAwarePaginator } from 'src/app/core/model/common/length-aware-paginator.model';

export class Interns extends EntityHasDocuments {
  documentPath = 'interns-documents';

  constructor(public api: ApiService) {
    super(api);
  }

  getUrl() {
    return `${this.api.getApiUrl()}/interns`;
  }

  create(entityInfo: any, files?: UploadAttachmentModel[]): Observable<any> {
    return this._create<any>(entityInfo, files);
  }

  update(entityInfo: any, files?: UploadAttachmentModel[]): Observable<any> {
    return this._update<any>(entityInfo, files);
  }

  getAll(params?: any): Observable<LengthAwarePaginator<InternsModel[]>> {
    return this.api.getHttp().get<LengthAwarePaginator<InternsModel[]>>(`${this.getUrl()}`, { params });
  }

  get(entityId: string) {
    return this._get<InternsModel>(entityId);
  }

  delete(entityId: string) {
    return this._delete<any>(entityId);
  }

  workspaces() {
    let http = this.api.getHttp();
    return http.get<EmployeesWorkspaces[]>(`${this.api.getApiUrl()}/employees-workspaces`);
  }

  graduationLevels() {
    let http = this.api.getHttp();
    return http.get<EmployeesGraduationLevels[]>(`${this.api.getApiUrl()}/employees-graduation-levels`);
  }

  situationsCourse() {
    let http = this.api.getHttp();
    return http.get<SituationCourse[]>(`${this.api.getApiUrl()}/interns-current-situations-course`);
  }

  attachments(employeeId: string) {
    let http = this.api.getHttp();
    return http.get<iResponseEmployeeAttachments>(`${this.api.getApiUrl()}/employees-attachments/${employeeId}`);
  }

  active(internId: string): Observable<any> {
    let customUrl = `${this.api.getApiUrl()}/interns-active/${internId}`;
    return this._update<any>(null, null, customUrl);
  }

  deleteDocument(internId: string, documentId: string) {
    return this.api.getHttp().delete(this.url(internId, 'documents', documentId));
  }
}
