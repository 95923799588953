import { BalanceModel } from 'src/app/core/model/balance/balance.model';
import { BaseCrud } from '../helpers/baseCrud';
import { ApiService } from '../../api.service';
import moment from 'moment';
import { BankSlipIntegrationReport } from '@model/tenant/integration-reports/bank-slip-integration-report';

export class IntegrationReportsRoute extends BaseCrud {

  constructor(public api: ApiService) {
    super(api);
  }

  getUrl() {
    return `${this.api.getApiUrl()}/integration-reports`;
  }

  bankslip() {
    const http = this.api.getHttp();
    return http.get<BankSlipIntegrationReport>(this.url('bankslip'));
  }
}
