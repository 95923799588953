import { Component, Input } from '@angular/core';
import { DownloadService } from '@core/services/common/network/download.service';
import { AutoUnsubscribe } from '@shared-helpers/auto-unsubscribe';
import { MaterialButtonColors } from '@core/types/material-button-colors';
import { catchError } from 'rxjs/operators';
import { ApiErrorHandler } from '@core/services';

@Component({
  selector: 'kt-button-open-file',
  templateUrl: './button-open-file.component.html',
})
export class ButtonOpenFileComponent extends AutoUnsubscribe {

  @Input() link: string;
  @Input() onlyIcon = false;
  @Input() textOnHove = false;
  @Input() color: MaterialButtonColors = 'primary';

  downloading = false;

  constructor(private downloadService: DownloadService) {
    super();
  }

  open() {
    this.downloading = true;
    this.sub = this.downloadService
      .downloadAndOpen(this.link)
      .pipe(
        catchError(() => {
          this.downloading = false;
          return [];
        }),
      )
      .subscribe(() => this.downloading = false);
  }
}
