import { EventEmitter, Injectable } from '@angular/core';
import { FilterField } from './core/filterField';
import { FilterFieldType } from './enums/filterFieldType';

@Injectable({
  providedIn: 'root'
})
export class AdvancedFilterService {

  private _filterFields: FilterField[] = [];
  private _updatedValues: any = {};

  public onApplyFilters = new EventEmitter<any>();
  public onResetField = new EventEmitter<any>();

  constructor(

  ) { }

  get fields() {
    return this._filterFields;
  }

  get filters() {
    return this._filterFields.reduce((filteredValues, field) => {
      if (field.value !== null && field.value !== undefined) {
        if (field.type == FilterFieldType.Date) {
          filteredValues[field.name] = field.value.toISOString();
        } else {
          filteredValues[field.name] = field.value;
        }
      }
      return filteredValues;
    }, {}) as any;
  }
  getFieldByName(name: string) {
    return this._filterFields.find(field => field.name == name);
  }

  clearFilters() {
    this._filterFields = this.fields.map(field => {
      field.value = field.startValue;
      return field;
    });

    this._updatedValues = {};
  }

  clearFields() {
    this._filterFields = [];
  }

  addField(field: FilterField) {
    this._filterFields.push(field);
    return this;
  }

  resetField(field: FilterField) {
    let indexOfField = this._filterFields.findIndex(filterField => filterField.name == field.name);
    this._filterFields[indexOfField].value = field.startValue;
    this._updatedValues[field.name] = field.startValue;
    this.onResetField.emit(field);
    return this;
  }

  patchValues(newValues) {
    this._updatedValues = {};
    Object.keys(newValues).forEach(key => {
      this._updatedValues[key] = newValues[key]
    });
  }
  applyFilter() {
    Object.keys(this._updatedValues).forEach(key => {
      let field = this.getFieldByName(key);
      field.value = this._updatedValues[key];
    });
    this.onApplyFilters.emit(this.filters);
  }
}