import { HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AppModule } from 'src/app/app.module';
import { NotificationService } from '@core/services/notification.service';

export class ApiErrorHandler {

  public static withAlertAndReload(timeout = 6e3) {
    return (obs: Observable<any>) => obs.pipe(
      this.withAlert(timeout),
      catchError((error) => {
        setTimeout(() => {
          this.notification.error('Recarregando a página...');
          setTimeout(() => window.location.reload(), 1300);
        }, timeout);
        throw error;
      }),
    );
  }

  public static withAlert(duration = 6e3) {
    return (obs: Observable<any>) => obs.pipe(
      catchError((error) => {
          const errors = ApiErrorHandler.getErrors(error);
          this.notification.error(errors, duration);
          throw error;
        },
      ),
    );
  }

  public static getErrors(error: HttpErrorResponse) {
    const data = error.error;
    const parseError = ApiErrorHandler.parseErrorFunctions[error.status] || (() => [ 'Erro desconhecido' ]);

    return parseError(data);
  }

  private static get notification() {
    return AppModule.injector.get(NotificationService);
  }

  private static parseErrorFunctions = {
    400: ApiErrorHandler.parseBadRequest,
    403: ApiErrorHandler.parseForbidden,
    422: ApiErrorHandler.parseUnprocessableEntity,
    500: ApiErrorHandler.parseInternalServerError,
  };

  private static parseUnprocessableEntity({ message, errors }) {
    switch (message) {
      case 'The given data was invalid.':
        return ApiErrorHandler.parseErrors(errors);
    }

    if (errors) {
      return ApiErrorHandler.parseErrors(errors);
    }
  }

  private static parseBadRequest({ success, message }) {
    if (!success) {
      return [ message ];
    }
  }

  private static parseForbidden() {
    return [ 'Você não tem permissão.' ];
  }

  private static parseErrors(errors: Record<string, string[]>) {
    return Object.keys(errors).map((key) => errors[key]);
  }

  private static parseInternalServerError({ message }) {
    if (message) {
      return [ message ];
    }
  }
}
