import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'kt-button-save',
  templateUrl: './button-save.component.html',
  styleUrls: ['button-save.style.scss']
})
export class ButtonSaveComponent {
  @Input() loading = new BehaviorSubject(false);
  @Input() isLoading = false;
  @Input() disabled = false;

  @Output() handle = new EventEmitter<void>();

  onClick() {
    if (this.disabled) {
      return;
    }

    this.handle.emit();
  }
}
