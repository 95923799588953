import { Observable } from "rxjs";
import { CentralApiService } from "../../central-api.service";
import { GetCepResult, GetStateResult, GetStateWithCitiesResult } from "src/app/core/model/central";

export class StateAndCitiesRoute {
    constructor(public apiService: CentralApiService) { }

    getUrl(): string {
        return `${this.apiService.getApiUrl()}/states`;
    }

    getAll(): Observable<GetStateResult[]> {
        return this.apiService.getHttp().get<GetStateResult[]>(this.getUrl());
    }

    get(id: string): Observable<GetStateResult[]> {
        return this.apiService.getHttp().get<GetStateResult[]>(`${this.getUrl()}/${id}`);
    }

    getWithCities(id: string): Observable<GetStateWithCitiesResult> {
        return this.apiService.getHttp().get<GetStateWithCitiesResult>(`${this.getUrl()}/${id}/cities`);
    }

    getZipcode(zipcode: string): Observable<GetCepResult> {
        return this.apiService.getHttp().get<GetCepResult>(`${this.apiService.getApiUrl()}/cep/${zipcode}`);
    }
}