export enum NotificationType {
    PRIMARY = "bg-primary",
    INFO = "bg-info",
    WARNING = "bg-warning",
    SUCCESS = "bg-success",
    SECONDARY = "bg-secondary",
    DARK = "bg-dark",
    LIGHT = "bg-light",
    DANGER = "bg-danger"
}
