import { Observable } from "rxjs";
import { ApiService } from "../..";
import { LengthAwarePaginator } from "src/app/core/model/common/length-aware-paginator.model";
import { GetLogResult } from "src/app/core/model/common";

export class LogsRoute {
    constructor(
        public apiService: ApiService
    ) {
    }

    getUrl(): string {
        return `${this.apiService.getApiUrl()}/logs`;
    }

    getAll(params?: any): Observable<LengthAwarePaginator<GetLogResult[]>> {
        return this.apiService.getHttp().get<LengthAwarePaginator<GetLogResult[]>>(this.getUrl(), { params });
    }

    get(id: string): Observable<GetLogResult> {
        return this.apiService.getHttp().get<GetLogResult>(`${this.getUrl()}/${id}`);
    }
}