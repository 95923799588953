<!-- begin:: Brand -->
<div class='brand flex-column-auto' [ngClass]='brandClasses' id='kt-aside'>
  <!--begin::Logo-->
  <a href='javascript:;' routerLink='/' class='brand-logo'>
    <img alt='logo' [attr.src]='headerLogo' width='140' />
  </a>
  <!--end::Logo-->

  <ng-container *ngIf='asideSelfMinimizeToggle'>
    <!--begin::Toggle-->
    <button class='brand-toggle btn btn-sm px-0 d-none d-lg-block' id='kt_aside_toggle' (click)='toggleAsideClick()'>
      <i class='fas text-white' [ngClass]='{ "fa-angles-right": isMinimized, "fa-angles-left": !isMinimized }'></i>
      <!--      <span class="svg-icon svg-icon-xl" [inlineSVG]="'./assets/media/svg/icons/Navigation/Angle-double-left.svg'"></span>-->
    </button>
    <!--end::Toolbar-->
  </ng-container>
</div>
<!-- end:: Brand -->
