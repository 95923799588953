import { Observable } from 'rxjs';
import { UploadAttachmentModel } from 'src/app/core/model/uploadAttachment.model';
import { BaseCrud } from '../helpers/baseCrud';
import { ApiService } from '../../api.service';
import { iResponseListUsers, iResponseShowUser } from '../../iResponse/iUsersReponse';
import { iResponseAttachmentResult } from '../../iResponse/iResponseAttachments';
import { iPaginationResponse } from '../../interfaces/pagination-response';
import { User } from '@model/tenant/user/user.model';

export class Users extends BaseCrud {
  constructor(public api: ApiService) {
    super(api);
  }

  getUrl() {
    return `${this.api.getApiUrl()}/users`;
  }

  getAll(
    page = 1,
    per_page = 25,
    active = 1,
    search = '',
    sort: any = { sort_column: 'name', sort_direction: 'asc' },
  ): Observable<iPaginationResponse<User>> {
    return this._getAll(
      `page=${page}&per_page=${per_page}&active=${active}&search=${search}&sort_column=${sort.sort_column}&sort_direction=${sort.sort_direction}`,
    );
  }

  get(userid: string): Observable<iResponseShowUser> {
    return this._get<iResponseShowUser>(userid);
  }

  create(entityInfo: any, files?: UploadAttachmentModel[]): Observable<any> {
    return this._create<any>(entityInfo, files);
  }

  update(entityInfo: any, files?: UploadAttachmentModel[]): Observable<any> {
    return this._update<any>(entityInfo, files);
  }

  delete(userId: string) {
    return this._delete<any>(userId);
  }

  attachments(userId: number) {
    let http = this.api.getHttp();
    return http.get<iResponseAttachmentResult>(`${this.getUrl()}/${userId}/attachments`);
  }

  getInterns() {
    let http = this.api.getHttp();
    return http.get<any>(`${this.getUrl()}/interns`);
  }

  getEmployees() {
    let http = this.api.getHttp();
    return http.get<any>(`${this.getUrl()}/employees`);
  }

  getEmployeesAssociates() {
    let http = this.api.getHttp();
    return http.get<any>(`${this.getUrl()}/employees/associates`);
  }
}
