import { Observable } from 'rxjs';
import { CentralApiService } from '@core/services/api';
import { BaseCrud } from '@core/services/api/routes/helpers/baseCrud';


export type WhatsappStatusResponse = {
  authed: boolean;
  connected: boolean;
  started_at: string;
};

export class ConfigurationRoute extends BaseCrud {
  constructor(public apiService: CentralApiService) {
    super(apiService);
  }

  getUrl(): string {
    return `${this.apiService.getApiUrl()}/configuration`;
  }

  whatsappQrCode(): Observable<string> {
    return this.apiService.getHttp().get<string>(this.url('whatsapp-qrcode'), {
      responseType: 'text' as 'json',
    });
  }

  whatsappStatus(): Observable<WhatsappStatusResponse> {
    return this.apiService.getHttp().get<WhatsappStatusResponse>(this.url('whatsapp-status'));
  }

  whatsappLogout(): Observable<void> {
    return this.apiService.getHttp().post<void>(this.url('whatsapp-logout'), {});
  }

  whatsappRestart(): Observable<void> {
    return this.apiService.getHttp().post<void>(this.url('whatsapp-restart'), {});
  }
}
