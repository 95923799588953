import { BaseCrud } from '@core/services/api/routes/helpers/baseCrud';
import { ApiService } from '@core/services/api';
import { GetCardSnapshotResult } from '@model/tenant/cards';
import { Observable } from 'rxjs';
import { PeriodicValue } from '@model/tenant/dashboard';

export class CardRoute extends BaseCrud {
  constructor(public apiService: ApiService) {
    super(apiService);
  }

  getUrl(): string {
    return `${this.apiService.getApiUrl()}/stats/cards`;
  }

  getMonthlyExpenseSnapshot(params: any): Observable<PeriodicValue[]> {
    return this.apiService.getHttp().get<PeriodicValue[]>(this.url('monthly-snapshot'), { params });
  }

  getMonthExpenseSnapshot(params: any): Observable<GetCardSnapshotResult[]> {
    return this.apiService.getHttp().get<GetCardSnapshotResult[]>(this.url('month-snapshot'), { params });
  }

}
