// Angular
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'kt-widget8-trends',
  templateUrl: './widget8-trends.component.html'
})
export class Widget8TrendsComponent {
  @Input() cssClasses = '';

  constructor() {
  }
}
