import { Component, EventEmitter, Input, Output } from '@angular/core';
import { LayoutUtilsService } from 'src/app/core/_base/crud';

type Entity = { id: number | string, name: string, statement?: string, active: number | boolean };

@Component({
  selector: 'kt-activate-and-inactivate-button',
  templateUrl: './activate-and-inactivate-button.component.html',
})
export class ActivateAndInactivateButtonComponent {

  @Input() entity: Entity;
  @Output() activeOrInactive = new EventEmitter();

  constructor(
    private layoutUtilsService: LayoutUtilsService,
  ) {
  }

  get isActive() {
    return this.entity.active === 1 || this.entity.active === true;
  }

  clickOnButton() {
    const title = `${this.entity.active ? 'Inativar' : 'Ativar'} - ${this.handleTitle()}`;
    const description = `Você tem certeza que deseja ${this.entity.active ? 'inativar' : 'ativar'}?`;
    const waitDescription = `${this.entity.active ? 'Inativando' : 'Ativando'} ...`;

    const dialogRef = this.layoutUtilsService.deleteElement(title, description, waitDescription);
    dialogRef.afterClosed().subscribe(res => {
      if (!res) {
        return;
      }

      this.activeOrInactive.emit(this.entity);
    });
  }

  handleTitle() {
    return this.entity.name === undefined ? this.entity.statement : this.entity.name;
  }
}
