import { BaseCrud } from '@core/services/api/routes/helpers/baseCrud';
import { BankConciliationRoute } from '@core/services/api/routes/tenancy/conciliation/bank-conciliation.route';
import { OfxGroupType } from 'src/app/views/pages/tenant/finances/pages/recon/enum/ofx-group-type';
import { OfxGroup } from 'src/app/views/pages/tenant/finances/pages/recon/pages/bank/models/ofx/ofx-group';
import { GroupForDir } from 'src/app/views/pages/tenant/finances/pages/recon/enum/group-for-dir';

export class OfxGroupsRoute extends BaseCrud {
  constructor(public base: BankConciliationRoute) {
    super(base.api);
  }

  get http() {
    return this.base.api.getHttp();
  }

  getUrl() {
    return this.base.getUrl();
  }

  list(reconId: string, ofxId: string) {
    return this.http.get<OfxGroup[]>(this.url(reconId, 'ofx', ofxId, 'groups'));
  }

  store(reconId: string, ofxId: string, type: OfxGroupType, dir: GroupForDir, entities: string[]) {
    return this.http.post<OfxGroup>(this.url(reconId, 'ofx', ofxId, 'groups'), {
      type,
      dir,
      entities,
    });
  }

  show(reconId: string, ofxId: string, groupId: string) {
    return this.http.get<OfxGroup>(this.url(reconId, 'ofx', ofxId, 'groups', groupId));
  }

  delete(reconId: string, ofxId: string, groupId: string) {
    return this.http.delete(this.url(reconId, 'ofx', ofxId, 'groups', groupId));
  }

  addItem(reconId: string, ofxId: string, groupId: string, entityId: string) {
    return this.http.post(this.url(reconId, 'ofx', ofxId, 'groups', groupId, 'items'), {
      entity_id: entityId,
    });
  }

  syncMultiItem<T>(reconId: string, ofxId: string, groupId: string, entities: string[]) {
    return this.http.post<T>(this.url(reconId, 'ofx', ofxId, 'groups', groupId, 'items'), {
      multiple: true,
      entities,
    });
  }

  removeItem(reconId: string, ofxId: string, groupId: string, entityId: string) {
    return this.http.delete(this.url(reconId, 'ofx', ofxId, 'groups', groupId, 'items', entityId));
  }
}
