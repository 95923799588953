import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgLetDirective } from './directives/ng-let.directive';
import { ButtonViewComponent } from './components/buttons/button-view/button-view.component';
import { ButtonEditComponent } from './components/buttons/button-edit/button-edit.component';
import { ButtonDeleteComponent } from './components/buttons/button-delete/button-delete.component';
import { InlineSVGModule } from 'ng-inline-svg';
import { ArrowRightIndicatorComponent } from './components/misc/arrow-right-indicator/arrow-right-indicator.component';
import { ButtonSaveComponent } from './components/buttons/button-save/button-save.component';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { ButtonBackComponent } from './components/buttons/button-back/button-back.component';
import { ButtonCloseComponent } from './components/buttons/button-close/button-close.component';
import { FileIconComponent } from './components/icons/file-icon/file-icon.component';
import { PortletModule } from '../views/partials/content/general/portlet/portlet.module';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { ButtonFilterComponent } from './components/buttons/button-filter/button-filter.component';
import { AdvancedFilterComponent } from './advancedFilter/components/advanced-filter/advanced-filter.component';
import {
  AdvancedFilterVerticalComponent,
} from './advancedFilter/components/advanced-filter-vertical/advanced-filter-vertical.component';
import { FilterChipsComponent } from './advancedFilter/components/filter-chips/filter-chips.component';
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { MatChipsModule } from '@angular/material/chips';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatCardModule } from '@angular/material/card';
import {
  ActivateAndInactivateButtonComponent,
} from './components/activate-and-inactivate-button/activate-and-inactivate-button.component';
import { SelectSearchComponent } from './components/select-search/select-search.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { FiscalRpsNumberPipe } from './pipes/fiscal-rps-number.pipe';
import { ButtonLoopComponent } from './components/buttons/button-loop/button-loop.component';
import { ButtonUploadComponent } from './components/buttons/button-upload/button-upload.component';
import { ButtonDownloadComponent } from './components/buttons/button-download/button-download.component';
import { ButtonPrintComponent } from './components/buttons/button-print/button-print.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { ButtonCheckComponent } from './components/buttons/button-check/button-check.component';
import { PageLoadingSpinnerComponent } from './components/loading/page-loading-spinner/page-loading-spinner.component';
import { CustomDatePickerComponent } from './components/custom-date-picker/custom-date-picker.component';
import { MaskDataDirective } from './directives/mask-data.directive';
import { TestBadgeComponent } from './components/test-badge/test-badge.component';
import { ButtonVisibleComponent } from './components/buttons/button-visible/button-visible.component';
import { ButtonVisibleOffComponent } from './components/buttons/button-visible-off/button-visible-off.component';
import { InputModule } from './input/input.module';
import { DescriptionPipe } from './pipes/description.pipe';
import { ButtonCreateComponent } from 'src/app/shared/components/buttons/button-create/button-create.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { PluralPipe } from './pipes/plural.pipe';
import { ButtonComponent } from './components/buttons/button/button.component';
import {
  ThButtonIconGroupComponent,
} from 'src/app/shared/components/buttons/th-button-icon-group/th-button-icon-group.component';
import { CardLetterPipe } from 'src/app/shared/pipes/card-letter.pipe';
import {
  InlineRangedDatePickerComponent,
} from 'src/app/shared/components/inline-ranged-date-picker/inline-ranged-date-picker.component';
import { ButtonHistoryComponent } from 'src/app/shared/components/buttons/button-history/button-history.component';
import { LabelOutlineComponent } from 'src/app/shared/components/label/label-outline/label-outline.component';
import { ButtonOpenFileComponent } from 'src/app/shared/components/buttons/button-open-file/button-open-file.component';
import { YearSelectorComponent } from 'src/app/shared/components/year-selector/year-selector.component';
import { MatMenuModule } from '@angular/material/menu';
import { ButtonCancelComponent } from 'src/app/shared/components/buttons/button-cancel/button-cancel.component';
import { DocumentNumberPipe } from 'src/app/shared/pipes/document-number.pipe';
import { StrokedButtonComponent } from 'src/app/shared/components/buttons/stroked-button/stroked-button.component';
import { BasicButtonComponent } from 'src/app/shared/components/buttons/basic-button/basic-button.component';

const components = [
  ActivateAndInactivateButtonComponent,
  AdvancedFilterComponent,
  AdvancedFilterVerticalComponent,
  ArrowRightIndicatorComponent,
  ButtonBackComponent,
  ButtonCheckComponent,
  ButtonCheckComponent,
  ButtonCloseComponent,
  ButtonComponent,
  ButtonCreateComponent,
  ButtonDeleteComponent,
  ButtonCancelComponent,
  ButtonDownloadComponent,
  ButtonEditComponent,
  ButtonFilterComponent,
  ButtonHistoryComponent,
  ButtonLoopComponent,
  ButtonPrintComponent,
  ButtonSaveComponent,
  ButtonUploadComponent,
  ButtonViewComponent,
  ButtonVisibleComponent,
  ButtonVisibleOffComponent,
  BasicButtonComponent,
  StrokedButtonComponent,
  CardLetterPipe,
  CustomDatePickerComponent,
  CustomDatePickerComponent,
  DescriptionPipe,
  FileIconComponent,
  FilterChipsComponent,
  FiscalRpsNumberPipe,
  DocumentNumberPipe,
  InlineRangedDatePickerComponent,
  MaskDataDirective,
  NgLetDirective,
  PageLoadingSpinnerComponent,
  PageLoadingSpinnerComponent,
  PluralPipe,
  SelectSearchComponent,
  TestBadgeComponent,
  TestBadgeComponent,
  ThButtonIconGroupComponent,
  LabelOutlineComponent,
  ButtonOpenFileComponent,
  YearSelectorComponent,
];

@NgModule({
  declarations: components,
  imports: [
    CommonModule,
    CurrencyMaskModule,
    FormsModule,
    InlineSVGModule,
    InputModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    PortletModule,
    ReactiveFormsModule,
    MatMenuModule,
    MatTooltipModule,
  ],
  exports: [
    ...components,
    InputModule,
  ],
})
export class SharedModule {
}
