import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { AuthService } from '../auth.service';
import { iif, map, mergeMap, Observable, of } from 'rxjs';
import { GetCurrentSessionResult } from 'src/app/core/model/common';
import { TenancyService } from '../tenancy.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard {

  constructor(
    private authService: AuthService,
    private tenancyService: TenancyService,
    private router: Router,
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    return this.authService.currentUserObserver.pipe(
      mergeMap((user: GetCurrentSessionResult) => this.checkIsNotAuthenticated(user)),
      map((result: UrlTree | boolean) => result),
    );
  }

  private checkIsNotAuthenticated(user: GetCurrentSessionResult): Observable<boolean | UrlTree> {
    const subdomain = this.tenancyService.makeSubdomain(window.location.hostname);

    return iif(() => !user, of(this.router.parseUrl(subdomain ? 'painel/auth/login' : 'admin/auth/login')), of(true));
  }
}