import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { TenancyService } from '../common';
import { AbstractAuthRoute, AbstractCurrentSessionRoute } from './routes/common';

export abstract class BaseApi {

  public auth: AbstractAuthRoute;
  public currentSession: AbstractCurrentSessionRoute;

  constructor(
    public http: HttpClient,
    public tenancyService: TenancyService,
  ) {
  }

  getAuthorizationToken() {
    const storageKey = `${this.tenancyService.subdomain ? this.tenancyService.subdomain : 'main'}.${environment.authTokenKey}`;
    return localStorage.getItem(storageKey);
  }

  logout() {
    const storageKey = `${this.tenancyService.subdomain ? this.tenancyService.subdomain : 'main'}.${environment.authTokenKey}`;
    localStorage.removeItem(storageKey);
  }

  getHttp(): HttpClient {
    return this.http;
  }

  getApiUrl(): string {
    return `${this.tenancyService.apiUrl}${this.tenancyService.subdomain ? 'tenant-api/v1' : 'api/v1'}`;
  }
}
