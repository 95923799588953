<!--<a title="Visualizar" class="btn btn-icon btn-light btn-hover-default btn-sm">-->
<!--  <i class="fas fa-eye fas-primary"></i>-->
<!--  <span-->
<!--    [inlineSVG]="'./assets/media/svg/icons/General/view-details.svg'"-->
<!--    cacheSVG="true"-->
<!--    class="svg-icon svg-icon-md svg-icon-default">-->
<!--  </span>-->
<!--</a>-->

<button mat-icon-button color='primary'>
  <i class='fas fa-eye'></i>
</button>
