<button mat-icon-button color='primary' *ngIf='iconOnly; else historyButton'>
  <i class='fa fa-history'></i>
</button>

<ng-template #historyButton>
  <button
    mat-raised-button
    class='mr-2'
    color='primary'
    [disabled]='(loading | async)'
    [ngClass]='{"spinner spinner-center spinner-md spinner-primary" : (loading | async)}'
  >
    <mat-icon>density_medium</mat-icon>
    Histórico
  </button>
</ng-template>
