// Angular
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AlertControl } from 'src/app/helpers/alertControl';

@Component({
  selector: 'kt-alert',
  templateUrl: './alert.component.html',
})
export class AlertComponent implements OnInit {
  // Public properties
  @Input() alertControl: AlertControl;
  @Input() type: 'primary' | 'accent' | 'warn' = 'warn';
  @Input() duration = 10000;
  @Input() showCloseButton = true;
  @Input() messages: string[] = [];
  @Output() close = new EventEmitter<boolean>();

  /**
   * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
   */

  get show() {
    if (this.alertControl) {
      this.messages = this.alertControl.errorMessages;
      return this.alertControl?.showAlert.value;
    }

    return true;
  }

  ngOnInit() {
    if (this.duration === 0) {
      return;
    }

    setTimeout(() => {
      this.closeAlert();
    }, this.duration);
  }

  /**
   * close alert
   */
  closeAlert() {
    this.alertControl && this.alertControl.onAlertClose();

    this.close.emit();
  }
}
