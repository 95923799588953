import { HttpErrorResponse, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { ApiService } from './api.service';
import { NotificationService } from '@core/services/notification.service';
import { NotificationType } from '@enum/common/notification-type.enum';

@Injectable()
export class ApiInterceptor implements HttpInterceptor {

  constructor(
    private apiService: ApiService,
    private notificationService: NotificationService,
  ) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    const token = this.apiService.getAuthorizationToken();

    const authReq = req.clone({
      headers: req.headers.set('Authorization', 'Bearer ' + token),
    });

    return next.handle(authReq).pipe(
      tap({ error: this.errorHandler.bind(this) }),
    );
  }

  errorHandler(error: any) {
    if (error instanceof HttpErrorResponse) {
      const properties = {
        // [0]: 'Problemas de conexão. Verifique sua internet, por favor.',
        [429]: 'Muitas requisições seguidas.',
      };

      if (properties[error.status]) {
        this.notificationService.open(properties[error.status], NotificationType.DANGER);
      }

      const token = this.apiService.getAuthorizationToken();
      if (error.status === 401 && token) {
        this.notificationService.open('Sua sessão expirou, faça login novamente. Redirecionando para o login.', NotificationType.DANGER);
        this.apiService.logout();
        setTimeout(() => {
          window.location = '/' as any;
        }, 5000);
      }

      console.error(`An error in the api has occurred: ${error.error.message}, status: ${error.status}`, error);
    }
  }
}
