import { Observable } from "rxjs";
import { CentralApiService } from "../../central-api.service";
import { GetServicePlanModuleResult } from "src/app/core/model/central";

export class ServiceModulesRoute {
    constructor(public apiService: CentralApiService) { }

    getUrl(): string {
        return `${this.apiService.getApiUrl()}/service-modules`;
    }

    getAll(): Observable<GetServicePlanModuleResult[]> {
        return this.apiService.getHttp().get<GetServicePlanModuleResult[]>(this.getUrl());
    }
}