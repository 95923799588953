import { TenantPerm } from '@enum/tenant/permission/tenant-perm.enum';
import { CommonPerm } from '@enum/common/permission/common-perm.enum';

export const AdministrativeSection = [
  {
    section: 'Administrativo',
    permission: [
      '*',
      TenantPerm.Clients_View,
      TenantPerm.Providers_View,
      TenantPerm.Employees_View, TenantPerm.Interns_View,
      CommonPerm.Users_View,
      TenantPerm.AdminDocuments_View,
    ],
  },
  {
    title: 'Clientes',
    permission: ['*', TenantPerm.Clients_View],
    root: true,
    icon: 'flaticon2-avatar',
    page: '/painel/listagem/clientes',
    translate: 'MENU.DASHBOARD',
    bullet: 'dot',
  },
  {
    title: 'Fornecedores',
    permission: ['*', TenantPerm.Providers_View],
    root: true,
    icon: 'fas fa-handshake',
    page: '/painel/listagem/fornecedores',
    translate: 'MENU.DASHBOARD',
    bullet: 'dot',
  },
  {
    title: 'Colaboradores',
    root: true,
    icon: 'fas fa-users',
    bullet: 'dot',
    permission: ['*', TenantPerm.Employees_View, TenantPerm.Interns_View],
    submenu: [
      {
        title: 'Estagiários',
        permission: ['*', TenantPerm.Interns_View],
        root: true,
        page: '/painel/listagem/estagiarios',
        bullet: 'dot',
      },
      {
        title: 'Funcionários',
        permission: ['*', TenantPerm.Employees_View],
        root: true,
        page: '/painel/listagem/funcionarios',
        bullet: 'dot',
      },
    ],
  },
  {
    title: 'Usuários do sistema',
    permission: ['*', CommonPerm.Users_View],
    icon: 'fas fa-user-pen',
    page: '/painel/listagem/usuarios',
    bullet: 'dot',
  },
  {
    title: 'Documentos',
    permission: ['*', TenantPerm.AdminDocuments_View],
    root: true,
    icon: 'fas fa-file-lines',
    bullet: 'dot',
    page: '/painel/documentos-administrativos',
  }
];
