import { Injectable } from '@angular/core';
import { ApiService } from '../../api';
import { tap } from 'rxjs/operators';
import printJS from 'print-js';
import { HttpEvent, HttpEventType } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class DownloadService {

  private options = {
    responseType: 'blob',
    observe: 'response',
  };

  progressOptions = {
    reportProgress: true,
    observe: 'events',
  };

  constructor(private api: ApiService) {
  }

  public file(link: string, fileName: string, progress = false) {
    return this.api.getHttp().get(link, this.getOptions(progress)).pipe(
      tap((response: HttpEvent<HttpEventType>) => {
        if (response.type !== HttpEventType.Response) {
          return;
        }

        const blob = this.parseResponseToBlob(response);
        this.downloadBlob(blob, fileName);
      }),
    );
  }

  public printPdf(link: string, progress = false) {
    return this.api.getHttp().get(link, this.getOptions(progress)).pipe(
      tap((response: HttpEvent<HttpEventType>) => {
        if (response.type !== HttpEventType.Response) {
          return;
        }

        const userAgent = navigator.userAgent.toLowerCase();

        const blobUrl = window.URL.createObjectURL(this.parseResponseToBlob(response));
        // Chrome based browsers
        if (userAgent.indexOf('chrome') > -1 && userAgent.indexOf('edg') === -1 && userAgent.indexOf('opr') === -1) {
          printJS({
            printable: blobUrl,
            type: 'pdf',
            showModal: false,
          });
          return;
        }

        // Not Chrome based browsers
        const newWindow = window.open(blobUrl, '_blank');

        newWindow.onload = () => {
          newWindow.focus();
          setTimeout(() => newWindow.print(), 1000);
        };

      }),
    );
  }

  public downloadAndOpen(link: string, progress = false) {
    return this.api.getHttp().get(link, this.getOptions(progress)).pipe(
      tap((response: HttpEvent<HttpEventType>) => {
        if (response.type !== HttpEventType.Response) {
          return;
        }

        window.open(window.URL.createObjectURL(this.parseResponseToBlob(response)), '_blank').focus();
      }),
    );
  }

  private getOptions(progress = false): unknown {
    let options = this.options;

    if (progress) {
      options = { ...options, ...this.progressOptions };
    }

    return options;
  }

  private parseResponseToBlob(response: any) {
    const contentType = response.headers.get('content-type');
    return new Blob([response.body], { type: contentType });
  }

  private downloadBlob(blob: Blob, filename: string) {
    const element = this.createElementA();
    element.href = window.URL.createObjectURL(blob);
    element.download = filename;
    element.click();
    element.remove();
  }

  private createElementA() {
    const element = document.createElement('a');
    element.hidden = true;
    document.body.appendChild(element);
    return element;
  }
}
