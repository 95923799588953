import { ApiService } from '../../api.service';
import { Injectable } from '@angular/core';
import { BaseCrud } from '@core/services/api/routes/helpers/baseCrud';
import { BackupData } from 'src/app/views/pages/tenant/configuration/backup-data/models/backup-data.model';
import { iPaginationResponse } from '../../interfaces/pagination-response';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BackupDataRoute extends BaseCrud {


  constructor(public api: ApiService) {
    super(api);
  }

  getUrl() {
    return `${this.api.getApiUrl()}/backups`;
  }

  getAll(
    page = 1,
    per_page = 25,
    search = '',
    sort: any = { sort_column: 'name', sort_direction: 'asc' },
  ): Observable<iPaginationResponse<BackupData>> {
    return this._getAll(
      `page=${page}&per_page=${per_page}&search=${search}&sort_column=${sort.sort_column}&sort_direction=${sort.sort_direction}`,
    );
  }

  store(data) {
    return this.api.getHttp().post(this.getUrl(), data);
  }

  download(id)
  {
    return this.api.getHttp().get(this.url(id, 'download'));
  }

  attachmentUrl(id)
  {
    return this.url(id, 'download');
  }
}
