import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AutoUnsubscribe } from '../../helpers/auto-unsubscribe';

@Component({
  selector: 'kt-select-search',
  templateUrl: './select-search.component.html',
})
export class SelectSearchComponent extends AutoUnsubscribe implements OnInit {
  
  @Input() fieldDisplayName: string;
  @Input() selectedId: any;
  @Input() selectList: BehaviorSubject<any[]>;
  @Output() changeId = new EventEmitter();
  @Output() changeValue = new EventEmitter();

  @Input() filterFunction: (selectList: any[], value: string) => any[];
  @Input() displayNameFunction: (entity: any) => string;

  public filteredEntities: any[];
  public form: FormGroup;

  constructor(
    public formBuilder: FormBuilder
  ) {
    super();
  }

  ngOnInit(): void {

    this.form = this.formBuilder.group({
      selectEntity: this.selectedId
    });

    let controls = this.form.controls;
    this.sub = controls.selectEntity.valueChanges.pipe(
      tap(newValue => {
        if (!isNaN(newValue)) this.changeId.emit(newValue);
        this.changeValue.emit(newValue);
        this.filterEntities(newValue);
      })
    ).subscribe();

    if (this.selectList) {
      this.sub = this.selectList.pipe(
        tap(newValue => {
          if (!this.selectedId) controls.selectEntity.setValue('');
          this.filteredEntities = newValue;
        })
      ).subscribe();
    }

  }

  filterEntities(value) {
    let selectList = this.selectList.getValue();
    if (!selectList) return;

    if (!this.filterFunction) {
      if (!isNaN(value)) value = `${value}`;
      this.filteredEntities = selectList.filter(entity => entity.name.toLowerCase().indexOf(value.toLowerCase()) === 0);
      return;
    }
    this.filteredEntities = this.filterFunction(selectList, value);
  }

  displayEntityName(value) {
    let selectList = this.selectList.getValue();
    if (!selectList) return;

    let entity = selectList.find(entity => entity.id == value);
    if (!this.displayNameFunction) {
      return entity && entity.name;
    }

    return entity && this.displayNameFunction(entity);
  }
}
