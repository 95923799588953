import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'kt-button-filter',
  templateUrl: './button-filter.component.html',
})
export class ButtonFilterComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
