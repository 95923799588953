import { ApiService } from 'src/app/core/services/api/api.service';
import { BaseCrud } from 'src/app/core/services/api/routes/helpers/baseCrud';
import { CashboxFlowPreview } from '@model/tenant/dashboard';
import { Observable } from 'rxjs';

export class CashflowRoute extends BaseCrud {
  constructor(public apiService: ApiService) {
    super(apiService);
  }

  getUrl() {
    return `${this.apiService.getApiUrl()}/stats/cash-flow`;
  }

  getCashFlowPreview(params: any): Observable<CashboxFlowPreview[]> {
    return this.apiService.getHttp().get<CashboxFlowPreview[]>(this.url('daily-preview') + this.toQuery(params));
  }
}
