import { Observable } from 'rxjs';
import { PhoneTypeModel } from 'src/app/core/model/phoneType.model';
import { BaseCrud } from '../helpers/baseCrud';
import { ApiService } from '../../api.service';

export class PhoneType extends BaseCrud {
  constructor(public api: ApiService) {
    super(api);
  }

  getUrl() {
    return `${this.api.getApiUrl()}/phone-types`;
  }

  getAll(params): Observable<any> {
    return this.api.getHttp().get<PhoneTypeModel[]>(this.url(), { params });
  }

  get(id: string): Observable<any> {
    return this._get<PhoneTypeModel>(id);
  }

  create(entity: PhoneTypeModel): Observable<any> {
    return this._create<PhoneTypeModel>(entity);
  }

  update(entity: PhoneTypeModel): Observable<any> {
    return this._update<PhoneTypeModel>(entity);
  }

  delete(id: string) {
    return this._delete<any>(id);
  }
}
