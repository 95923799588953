import { Observable } from 'rxjs';
import { ApiService } from '../..';
import { CreateOrUpdateCategory, GetCategoryResult } from 'src/app/core/model/tenant';
import { LengthAwarePaginator } from '@model/common/length-aware-paginator.model';

export class CategoryRoute {
  constructor(public apiService: ApiService) {
  }

  getUrl(): string {
    return `${this.apiService.getApiUrl()}/categories`;
  }

  list(params?: any): Observable<LengthAwarePaginator<GetCategoryResult[]>> {
    return this.apiService.getHttp().get<LengthAwarePaginator<GetCategoryResult[]>>(this.getUrl(), { params });
  }

  get(id: string): Observable<GetCategoryResult> {
    return this.apiService.getHttp().get<GetCategoryResult>(`${this.getUrl()}/${id}`);
  }

  create(data: CreateOrUpdateCategory): Observable<GetCategoryResult> {
    return this.apiService.getHttp().post<GetCategoryResult>(this.getUrl(), data);
  }

  update(id: string, data: CreateOrUpdateCategory): Observable<GetCategoryResult> {
    return this.apiService.getHttp().put<GetCategoryResult>(`${this.getUrl()}/${id}`, data);
  }

  toggleStatus(id: string): Observable<GetCategoryResult> {
    return this.apiService.getHttp().put<GetCategoryResult>(`${this.getUrl()}/${id}/toggle-status`, {});
  }
}
