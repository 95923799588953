import { Injectable } from '@angular/core';
import { BaseApi } from './base-api';
import { HttpClient } from '@angular/common/http';
import {
    AuthRoute,
    CompaniesRoute,
    CurrentSessionRoute,
    LogsRoute,
    ServiceModulesRoute,
    ServicePlansRoute,
    StateAndCitiesRoute,
    TypesRoute,
} from './routes/central';
import { TenancyService } from '../common';
import { ConfigurationRoute } from '@core/services/api/routes/central/configuration.route';

@Injectable({
    providedIn: 'root'
})
export class CentralApiService extends BaseApi {

    public companies: CompaniesRoute;
    public serviceModules: ServiceModulesRoute;
    public servicePlans: ServicePlansRoute;
    public stateAndCities: StateAndCitiesRoute;
    public types: TypesRoute;
    public configuration: ConfigurationRoute;
    public logsRoute: LogsRoute;

    constructor(
        public http: HttpClient,
        public tenancyService: TenancyService,
    ) {
        super(http, tenancyService);

        this.auth = new AuthRoute(this);
        this.currentSession = new CurrentSessionRoute(this);

        this.companies = new CompaniesRoute(this);
        this.serviceModules = new ServiceModulesRoute(this);
        this.servicePlans = new ServicePlansRoute(this);
        this.stateAndCities = new StateAndCitiesRoute(this);
        this.configuration = new ConfigurationRoute(this);
        this.types = new TypesRoute(this);
        this.logsRoute = new LogsRoute(this);
    }
}