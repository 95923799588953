import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { FilterFieldType } from '../../enums/filterFieldType';
import { AdvancedFilterService } from '../../advanced-filter.service';
import { AutoUnsubscribe } from 'src/app/shared/helpers/auto-unsubscribe';
import { debounceTime, distinct, startWith, tap } from 'rxjs/operators';
import { Subscription } from 'rxjs';

@Component({
  selector: 'kt-advanced-filter-vertical',
  templateUrl: './advanced-filter-vertical.component.html',
  styleUrls: ['./advanced-filter-vertical.component.scss']
})
export class AdvancedFilterVerticalComponent extends AutoUnsubscribe implements OnInit {

  filterForm: FormGroup;
  fieldType = FilterFieldType;
  formSubscription: Subscription;
  
  @Output() onClose = new EventEmitter();

  constructor(
    public formBuilder: FormBuilder,
    public advancedFilterService: AdvancedFilterService
  ) {
    super();
  }

  ngOnInit(): void {
    this.createForm();
    this.sub = this.advancedFilterService.onResetField.pipe(
      tap(field => {
        this.filterForm.controls[field.name].setValue(field.startValue);
      })
    ).subscribe();
  }

  close(){
    this.onClose.emit();
  }

  createForm() {
    let newFormGroup = this.advancedFilterService.fields.reduce((newGroup, field) => {
      newGroup[field.name] = field.startValue;
      return newGroup;
    }, {});
    this.filterForm = this.formBuilder.group(newFormGroup);

    this.advancedFilterService.patchValues(this.filterForm.value);
    this.subscribeToFormChanges();
  }

  subscribeToFormChanges() {
    if (this.formSubscription) this.formSubscription.unsubscribe();

    this.formSubscription = this.filterForm.valueChanges
      .pipe(
        tap(newValues => this.advancedFilterService.patchValues(newValues)),
        startWith(''),
        distinct(),
        debounceTime(250),
        tap(() => {
          this.advancedFilterService.applyFilter();
        })
      )
      .subscribe();
  }

  clearFilter() {
    this.advancedFilterService.clearFilters();
    this.createForm();
    this.advancedFilterService.applyFilter();
  }
}
