import { isArray } from 'lodash';
import { UploadAttachmentModel } from 'src/app/core/model/uploadAttachment.model';
import { ApiService } from '../../api.service';
import { CentralApiService } from '@core/services/api';

export abstract class BaseCrud {
  constructor(public api: ApiService | CentralApiService) {
  }

  getUrl() {
    return `${this.api.getApiUrl()}/`;
  }

  get basePath() {
    return this.getUrl();
  }

  protected _create<T>(createInfo, filesToUpload?: UploadAttachmentModel[], customUrl?: string) {
    createInfo = this.parseToFormData(createInfo, filesToUpload);
    return this.api
      .getHttp()
      .post<T>(customUrl ? customUrl : this.getUrl(), createInfo);
  }

  protected _update<T>(updateInfo, filesToUpload?: UploadAttachmentModel[], customUrl?: string) {
    const updateUrl = customUrl ? customUrl : `${this.getUrl()}/${updateInfo.id}`;

    updateInfo = this.parseToFormData(updateInfo, filesToUpload);
    if (filesToUpload) {
      updateInfo.append('_method', 'PUT');
      return this.api
        .getHttp()
        .post<T>(updateUrl, updateInfo);
    }
    return this.api
      .getHttp()
      .put<T>(updateUrl, updateInfo);
  }

  protected _getAll<T>(filterQuery?: string, customUrl?: string) {
    return this.api
      .getHttp()
      .get<T>(`${customUrl ? customUrl : this.getUrl()}${filterQuery ? '?' + filterQuery : ''}`);
  }

  protected _get<T>(entityId: number | string, filterQuery?: string, customUrl?: string) {
    return this.api
      .getHttp()
      .get<T>(
        `${customUrl ? customUrl : `${this.getUrl()}/${entityId}`}${filterQuery ? '?' + filterQuery : ''}`,
      );
  }

  protected _getUuid<T>(entityId: string, filterQuery?: string, customUrl?: string) {
    return this.api
      .getHttp()
      .get<T>(
        `${customUrl ? customUrl : `${this.getUrl()}/${entityId}`}${filterQuery ? '?' + filterQuery : ''}`,
      );
  }

  protected _delete<T>(entityId: number | string, customUrl?: string) {
    return this.api
      .getHttp()
      .delete<T>(`${customUrl ? customUrl : `${this.getUrl()}/${entityId}`}`);
  }

  private parseToFormData(objectToParse: any, filesToParse?: UploadAttachmentModel[]): object | FormData {
    if (!filesToParse) {
      return objectToParse;
    }

    const formData = new FormData();

    for (const file of filesToParse) {
      if (isArray(file.fileData)) {
        for (const key in file.fileData) {
          formData.append(`${file.fileKey}[${key}]`, file.fileData[key], file.fileData[key].name);
        }
      }
      else {
        const fileType = this.getFileType(file.fileData);
        if (fileType) {
          formData.append(file.fileKey, fileType, fileType.name);
        }
      }
    }

    for (const key in objectToParse) {
      if (objectToParse[key] !== undefined && objectToParse[key] !== null) {
        formData.append(key, typeof objectToParse[key] === 'object' ? JSON.stringify(objectToParse[key]) : objectToParse[key]);
      }
    }
    return formData;
  }

  getFileType(file): File {
    if (file instanceof File) {
      return file;
    }

    if (file && file.files && file.files.length > 0) {
      return file.files[0];
    }
  }

  protected url(...path: (string[] | string[][])) {
    let pathString = '';

    if (path.length === 0) {
      return this.basePath;
    }

    if (path.length === 1 && Array.isArray(path[0])) {
      path = path[0];
    }

    if (path.length === 1 && typeof path[0] === 'string') {
      pathString = path[0];
    }

    if (Array.isArray(path)) {
      pathString = path.join('/');
    }

    if (this.basePath === '') {
      throw new Error('Base path not set');
    }

    if (!this.basePath.endsWith('/') && pathString.length > 0) {
      let addSlash = '';

      if (!pathString.startsWith('/') && pathString.startsWith('?')) {
        addSlash = '';
      }

      if (!pathString.startsWith('/') && !pathString.startsWith('?')) {
        addSlash = '/';
      }
      return this.basePath + addSlash + pathString;
    }

    return this.basePath + pathString;
  }

  protected toQuery(obj: object) {
    if (!obj) {
      return '';
    }

    return '?' + Object.keys(obj).map(key => `${key}=${obj[key]}`).join('&');
  }
}
