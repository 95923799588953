import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import * as objectPath from 'object-path';
import { MenuConfigService } from './menu-config.service';

@Injectable()
export class MenuAsideService {
  menuListSubdomain$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  menuList$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);

  constructor(private menuConfigService: MenuConfigService) {
    this.loadMenu();
  }

  loadMenu() {
    this.menuListSubdomain$.next(objectPath.get(this.menuConfigService.getMenus(), 'aside.subdomain_items'));
    this.menuList$.next(objectPath.get(this.menuConfigService.getMenus(), 'aside.items'));
  }
}
