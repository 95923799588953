export enum TenantPerm {
  AccessKeys_View = 'access_keys.view',
  AccessKeys_Create = 'access_keys.create',
  AccessKeys_Update = 'access_keys.update',
  AccessKeys_Delete = 'access_keys.delete',

  Accounts_View = 'accounts.view',
  Accounts_Create = 'accounts.create',
  Accounts_Update = 'accounts.update',
  Accounts_Delete = 'accounts.delete',

  AccountingDocuments_View = 'accounting_documents.view',
  AccountingDocuments_Create = 'accounting_documents.create',
  AccountingDocuments_Update = 'accounting_documents.update',
  AccountingDocuments_Delete = 'accounting_documents.delete',

  AccountingReports_View = 'accounting_reports.view',
  AccountingReports_Create = 'accounting_reports.create',
  AccountingReports_Update = 'accounting_reports.update',
  AccountingReports_Delete = 'accounting_reports.delete',

  AccountingSheets_View = 'accounting_sheets.view',
  AccountingSheets_Create = 'accounting_sheets.create',
  AccountingSheets_Update = 'accounting_sheets.update',
  AccountingSheets_Delete = 'accounting_sheets.delete',

  AccountsPayable_Invoice = 'accounts_payable.invoice',
  AccountsPayable_Receipt = 'accounts_payable.receipt',
  AccountsPayable_SkipInvoice = 'accounts_payable.skip_invoice',
  AccountsPayable_SkipReceipt = 'accounts_payable.skip_receipt',

  AdminDocuments_View = 'admin_documents.view',
  AdminDocuments_Create = 'admin_documents.create',
  AdminDocuments_Update = 'admin_documents.update',
  AdminDocuments_Delete = 'admin_documents.delete',

  BackupData_View = 'backup_data.view',
  BackupData_Create = 'backup_data.create',

  BankRecons_View = 'bank_recons.view',
  BankRecons_Update = 'bank_recons.update',

  BankSlip_View = 'bank_slip.view',
  BankSlip_Emit = 'bank_slip.emit',
  BankSlip_Print = 'bank_slip.print',
  BankSlip_Notify = 'bank_slip.notify',
  BankSlip_Configure = 'bank_slip.configure',

  Cards_View = 'cards.view',
  Cards_Create = 'cards.create',
  Cards_Update = 'cards.update',
  Cards_Delete = 'cards.delete',

  CardRecons_View = 'card_recons.view',
  CardRecons_Update = 'card_recons.update',

  Cashboxes_View = 'cashboxes.view',
  Cashboxes_Create = 'cashboxes.create',
  Cashboxes_Update = 'cashboxes.update',
  Cashboxes_Delete = 'cashboxes.delete',

  Categories_View = 'categories.view',
  Categories_Create = 'categories.create',
  Categories_Update = 'categories.update',
  Categories_Delete = 'categories.delete',

  Clients_View = 'clients.view',
  Clients_Create = 'clients.create',
  Clients_Update = 'clients.update',
  Clients_Delete = 'clients.delete',

  Comments_View = 'comments.view',
  Comments_Create = 'comments.create',
  Comments_Update = 'comments.update',
  Comments_Delete = 'comments.delete',

  Dashboard_Summary = 'dashboard.summary',
  Dashboard_Cashbox = 'dashboard.cashbox',
  Dashboard_Delinquency = 'dashboard.delinquency',
  Dashboard_Cashflow = 'dashboard.cashflow',
  Dashboard_Card = 'dashboard.card',
  Dashboard_Results = 'dashboard.results',
  Dashboard_InOut = 'dashboard.in_out',
  Dashboard_InByCategory = 'dashboard.in_by_category',
  Dashboard_OutByCategory = 'dashboard.out_by_category',

  DataMigration_View = 'data_migration.view',
  DataMigration_Create = 'data_migration.create',

  Documents_View = 'documents.view',
  Documents_Create = 'documents.create',
  Documents_Update = 'documents.update',
  Documents_Delete = 'documents.delete',
  Documents_Download = 'documents.download',

  DocumentProfiles_View = 'document_profiles.view',
  DocumentProfiles_Create = 'document_profiles.create',
  DocumentProfiles_Update = 'document_profiles.update',
  DocumentProfiles_Delete = 'document_profiles.delete',

  EmailTypes_View = 'email_types.view',
  EmailTypes_Create = 'email_types.create',
  EmailTypes_Update = 'email_types.update',
  EmailTypes_Delete = 'email_types.delete',

  Employees_View = 'employees.view',
  Employees_Create = 'employees.create',
  Employees_Update = 'employees.update',
  Employees_Delete = 'employees.delete',

  FiscalRps_View = 'fiscal_rps.view',
  FiscalRps_Emit = 'fiscal_rps.emit',
  FiscalRps_Print = 'fiscal_rps.print',
  FiscalRps_Cancel = 'fiscal_rps.cancel',
  FiscalRps_Configure = 'fiscal_rps.configure',

  Interns_View = 'interns.view',
  Interns_Create = 'interns.create',
  Interns_Update = 'interns.update',
  Interns_Delete = 'interns.delete',

  Payments_View = 'payments.view',
  Payments_Create = 'payments.create',
  Payments_Update = 'payments.update',
  Payments_Delete = 'payments.delete',
  Payments_AdvancedFields = 'payments.advanced_fields',

  PhoneTypes_View = 'phone_types.view',
  PhoneTypes_Create = 'phone_types.create',
  PhoneTypes_Update = 'phone_types.update',
  PhoneTypes_Delete = 'phone_types.delete',

  Providers_View = 'providers.view',
  Providers_Create = 'providers.create',
  Providers_Update = 'providers.update',
  Providers_Delete = 'providers.delete',

  StandardLaunch_View = 'std_launch.view',
  StandardLaunch_Create = 'std_launch.create',
  StandardLaunch_Update = 'std_launch.update',
  StandardLaunch_Delete = 'std_launch.delete',

  Subsidiaries_View = 'subsidiaries.view',
  Subsidiaries_Create = 'subsidiaries.create',
  Subsidiaries_Update = 'subsidiaries.update',
  Subsidiaries_Delete = 'subsidiaries.delete',

  Templates_View = 'templates.view',
  Templates_Create = 'templates.create',
  Templates_Update = 'templates.update',
  Templates_Delete = 'templates.delete',

  Workflows_View = 'workflows.view',
  Workflows_Create = 'workflows.create',
  Workflows_Update = 'workflows.update',
  Workflows_Delete = 'workflows.delete',

  Layout_Edit = 'layout.edit',
}
