import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { AutoUnsubscribe } from 'src/app/shared/helpers/auto-unsubscribe';

@Component({
  selector: 'kt-entity-combo-select',
  templateUrl: './entity-combo-select.component.html',
})
export class EntityComboSelectComponent extends AutoUnsubscribe implements OnInit {

  @Input() errorMessageOnInput = null;

  @Input() selectedEntityType: string;
  @Input() selectedEntityId: string;

  @Input() entitieType: any[];
  @Input() entityTypeFieldName = 'Tipo';
  @Input() entitieNameFunction: any;
  @Input() isLoadingEntities: BehaviorSubject<boolean>;
  @Input() isEditing: boolean;

  @Input() entities: BehaviorSubject<{ id: number; name: string }[]>;

  @Output() changeEntityType = new EventEmitter();
  @Output() changeEntityId = new EventEmitter();

  filteredEntities: any;

  selectedEnityName: string;
  form: FormGroup;

  constructor(
    public formBuilder: FormBuilder,
  ) {
    super();
  }

  ngOnInit(): void {

    this.form = this.formBuilder.group({
      owner_type: [this.selectedEntityType],
      owner_id: [this.selectedEntityId],
    });    

    this.sub = this.form.controls.owner_type.valueChanges.subscribe(newValue => {
      if(this.form.controls.owner_type.value != newValue) this.form.controls.owner_id.setValue(null);

      this.form.controls.owner_id.setErrors(null);
      this.form.controls.owner_id.markAsUntouched();
      this.changeEntityType.emit(newValue);
      this.selectedEnityName = this.entitieNameFunction(newValue);
    });
    this.sub = this.form.controls.owner_id.valueChanges.subscribe(newValue => {
      this.changeEntityId.emit(newValue);
      this.filterEntities(newValue);
    });

    this.sub = this.isLoadingEntities.subscribe(isLoading => {
      if (isLoading == false) this.filteredEntities = this.entities.getValue();
    });

    if (this.selectedEntityType) {
      this.form.controls.owner_type.updateValueAndValidity({ onlySelf: true, emitEvent: true });
    }

    if (this.isEditing) {
      this.form.controls.owner_id.disable();
      this.form.controls.owner_type.disable();
    }
  }

  displayEntityName(value) {
    let entity = this.entities.getValue().find(entity => entity.id == value);
    return entity && entity.name;
  }
  filterEntities(value) {
    if (!isNaN(value)) value = `${value}`;
    this.filteredEntities = this.entities.getValue().filter(entity => entity.name.toLowerCase().indexOf(value.toLowerCase()) === 0);
  }

}