import { Observable } from 'rxjs';
import { DocumentCategory } from '@model/documents/documentCategory';
import { BaseCrud } from '../helpers/baseCrud';
import { ApiService } from '../../api.service';
import { LengthAwarePaginator } from '@model/common/length-aware-paginator.model';
import { DocumentProfile } from '@model/documents/documentProfile';

export class DocumentCategories extends BaseCrud {
  constructor(public apiService: ApiService) {
    super(apiService);
  }

  getUrl(): string {
    return `${this.apiService.getApiUrl()}/documents-categories`;
  }

  list(params?: any): Observable<LengthAwarePaginator<DocumentCategory[]>> {
    return this.apiService.getHttp().get<LengthAwarePaginator<DocumentCategory[]>>(this.url(), { params });
  }

  profiles(entityId: string): Observable<DocumentProfile[]> {
    return this.apiService.getHttp().get<DocumentProfile[]>(this.url(entityId, 'profiles'));
  }
}
