import { CreateCompany, GetCompanyResult, UpdateCompany } from 'src/app/core/model/central';
import { Observable } from 'rxjs';
import { CentralApiService } from '../../central-api.service';
import { LengthAwarePaginator } from 'src/app/core/model/common/length-aware-paginator.model';
import { BaseCrud } from '@core/services/api/routes/helpers/baseCrud';
import { UploadAttachmentModel } from '@model/uploadAttachment.model';


type AccessResult = {
  token: string;
  url: string;
};

export class CompaniesRoute extends BaseCrud {
  constructor(public apiService: CentralApiService) {
    super(apiService);
  }

  getUrl(): string {
    return `${this.apiService.getApiUrl()}/company`;
  }

  getAll(params?: any): Observable<LengthAwarePaginator<GetCompanyResult[]>> {
    return this.apiService.getHttp().get<LengthAwarePaginator<GetCompanyResult[]>>(this.getUrl(), { params });
  }

  get(id: string): Observable<GetCompanyResult> {
    return this.apiService.getHttp().get<GetCompanyResult>(`${this.getUrl()}/${id}`);
  }

  create(data: CreateCompany): Observable<GetCompanyResult> {
    return this._create(data);
  }

  activeOrInactive(id: string): Observable<GetCompanyResult> {
    return this.apiService.getHttp().post<GetCompanyResult>(`${this.getUrl()}/${id}/active-or-inactive`, {});
  }

  access(id: string): Observable<AccessResult> {
    return this.apiService.getHttp().post<AccessResult>(this.url(id, 'access'), {});
  }


  update(id: string, data: UpdateCompany): Observable<GetCompanyResult> {
    return this._update(data);
  }

}
