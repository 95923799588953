<kt-button
  (handle)='print()'
  [onlyIcon]='onlyIcon'
  [changeToOnHover]='changeToOnHover'
  [loading]='isPrinting()'
  [disabled]='disabled'
  [ngClass]="{ 'spinner spinner-center spinner-md spinner-primary': loading }"
>
  <i class='fas fa-print' [ngStyle]="{ visibility: (isPrinting() || loading) ? 'hidden' : 'visible' }"></i>
  {{ onlyIcon || changeToOnHover ? '' : 'Imprimir' }}
</kt-button>
