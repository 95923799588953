<!--begin::Navigation-->
<ul class="navi navi-hover py-5">
    <li class="navi-item">
        <a href="#" class="navi-link">
            <span class="navi-icon"><i class="flaticon2-group"></i></span>
            <span class="navi-text">Enviar uma proposta</span>
        </a>
    </li>
</ul>
<!--end::Navigation-->
