<kt-portlet>
  <kt-portlet-header [headTitle]='data.title' />

  <kt-portlet-body [isLoading]='viewLoading'>

    <div class='d-flex flex-column gap-5 p-5'>
      <div class='form-group row'>
        <div class='col-lg-12'>
          {{ data.description }}
        </div>
      </div>
    </div>
  </kt-portlet-body>

  <kt-portlet-footer>
    <div ktPortletActions>
      <button mat-raised-button (click)='onNoClick()'>Não</button>&nbsp;
      <kt-button [loading]='viewLoading' (handle)='onYesClick()'>
        Sim
      </kt-button>

    </div>
  </kt-portlet-footer>
</kt-portlet>
