import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'kt-file-icon',
  templateUrl: './file-icon.component.html',
})
export class FileIconComponent implements OnInit {

  @Input() fileName: string;
  @Input() fileUrl: string;
  @Input() fileExtension: string;

  constructor(
  ) { }

  ngOnInit(): void {

  }

  getImageForExtension() {
    let ext = this.fileExtension;
    if(ext === 'jpeg') ext = 'jpg';
    
    return './assets/media/svg/files/' + ext + '.svg';
  }

}
