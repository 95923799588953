import { Observable } from 'rxjs';
import { BaseApi } from '../../base-api';
import { AccessTokenResult } from 'src/app/core/model/common';

export abstract class AbstractAuthRoute {
  constructor(
    public apiService: BaseApi,
  ) {
  }

  login(email: string, password: string): Observable<AccessTokenResult> {
    return this.apiService.getHttp().post<AccessTokenResult>(`${this.apiService.getApiUrl()}/token`, { email, password });
  }

  forgotPassword(email: string): Observable<AccessTokenResult> {
    return this.apiService.getHttp().post<AccessTokenResult>(`${this.apiService.getApiUrl()}/forgot-password`, { email });
  }
  checkToken(token: string): Observable<AccessTokenResult> {
    return this.apiService.getHttp().get<AccessTokenResult>(`${this.apiService.getApiUrl()}/check-token/${token}`);
  }

  recoveryPassword(token: string, newPassword: string): Observable<AccessTokenResult> {
    return this.apiService.getHttp().post<AccessTokenResult>(`${this.apiService.getApiUrl()}/recovery-password`, { token, newPassword });
  }
}
