import { GetRoleResult } from "./get-role.model";
import { Permission } from '@model/tenant/role/permission.model';

export class GetRoleWithPermissionsResult extends GetRoleResult {
    perms: Permission[];

    constructor(data: GetRoleWithPermissionsResult) {
        super(data);
        this.perms = data.perms?.map(p => new Permission(p));
    }
}
