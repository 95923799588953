import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'kt-button-close',
  templateUrl: './button-close.component.html',
})
export class ButtonCloseComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
