import { BaseCrud } from '../helpers/baseCrud';
import { ApiService } from '../../api.service';
import { CreateReport, Report } from '@model/tenant/report';
import { Observable } from 'rxjs';
import { LengthAwarePaginator } from '@model/common/length-aware-paginator.model';

export class ReportsRoute extends BaseCrud {

  constructor(public api: ApiService) {
    super(api);
  }

  getUrl() {
    return `${this.api.getApiUrl()}/reports`;
  }

  list(params?: any): Observable<LengthAwarePaginator<Report[]>> {
    return this.api.getHttp().get<LengthAwarePaginator<Report[]>>(this.url(), { params });
  }

  get(id: string): Observable<Report> {
    return this.api.getHttp().get<Report>(this.url(id));
  }

  create(data: CreateReport): Observable<Report> {
    return this.api.getHttp().post<Report>(this.url(), data);
  }

  downloadUrl(id: string): string {
    return this.url(id, 'download');
  }

  cancel(id: string): Observable<Report> {
    return this.api.getHttp().put<Report>(this.url(id, 'cancel'), {});
  }

  delete(id: string): Observable<void> {
    return this.api.getHttp().delete<void>(this.url(id));
  }
}
