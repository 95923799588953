import { ApiService } from 'src/app/core/services/api/api.service';
import { Observable } from 'rxjs';
import { BaseCrud } from '../helpers/baseCrud';
import { IFiscalRPSConfiguration } from 'src/app/views/pages/central/companies/interfaces/fiscal-rps-configuration';
import { UploadAttachmentModel } from '@model/uploadAttachment.model';
import { FiscalRpsIssqn } from '@core/services/api/interfaces/fiscal-rps-issqn';
import { BankSlipConfig } from '@core/services/api/interfaces/bank-slip-config';

export class ConfigurationRoute extends BaseCrud {
  constructor(public apiService: ApiService) {
    super(apiService);
  }

  getUrl(): string {
    return `${this.apiService.getApiUrl()}/configuration`;
  }

  getFiscalRps(): Observable<IFiscalRPSConfiguration> {
    return this.apiService.getHttp().get<IFiscalRPSConfiguration>(this.url('fiscal-rps'));
  }

  getFiscalRpsIssqn(): Observable<FiscalRpsIssqn> {
    return this.apiService.getHttp().get<FiscalRpsIssqn>(this.url('fiscal-rps-issqn'));
  }

  updateFiscalRps(data, files?: UploadAttachmentModel[]): Observable<IFiscalRPSConfiguration> {
    return this._create(data, files ? files : [], this.url('fiscal-rps'));
  }

  updateBankSlip(data, file?: UploadAttachmentModel): Observable<any> {
    return this._create(data, file ? [file] : [], this.url('bank-slip'));
  }

  getBankSlip(): Observable<BankSlipConfig> {
    return this.apiService.getHttp().get<BankSlipConfig>(this.url('bank-slip'));
  }

  getLayout(): Observable<any> {
    return this.apiService.getHttp().get<any>(this.url('template-layout'));
  }

  updateLayout(data: any, files): Observable<any> {
    return this._create(data, files ? files : [], this.url('template-layout'));
  }
}
