<button
  [disabled]="(loading | async) || isLoading || disabled"
  class="mr-2"
  color="primary"
  [ngClass]="{ 'spinner spinner-center spinner-md spinner-primary': (loading | async) || isLoading }"
  (click)='onClick()'
  mat-raised-button>
  <i class="fas fa-floppy-disk"></i>
   Salvar
</button>
