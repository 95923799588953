import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'kt-button-view',
  templateUrl: './button-view.component.html',
})
export class ButtonViewComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
