import { Observable } from 'rxjs';
import { ClientsModel } from 'src/app/core/model/clients.model';
import { EntityHasDocuments } from '../helpers/entityHasDocuments';
import { ApiService } from '../../api.service';
import { Injectable } from '@angular/core';
import { LengthAwarePaginator } from 'src/app/core/model/common/length-aware-paginator.model';
import { ClientRequest } from 'src/app/core/model/tenant/client';
import { iPaginationResponse } from '../../interfaces/pagination-response';

@Injectable({
  providedIn: 'root',
})
export class Clients extends EntityHasDocuments {
  documentPath = 'clients-documents';

  constructor(public api: ApiService) {
    super(api);
  }

  getUrl() {
    return `${this.api.getApiUrl()}/clients`;
  }

  create(clientInfo): Observable<any> {
    return this._create<any>(clientInfo);
  }

  update(clientInfo): Observable<any> {
    return this._update<any>(clientInfo);
  }

  download(clientId: string, documentId: string) {
    return this.api.getHttp().get(this.documentUrl(clientId, documentId));
  }

  toggleClientStatus(entityInfo: ClientsModel): Observable<any> {
    return this._update(null, null, `${this.getUrl()}/toggle-status/${entityInfo.id}`);
  }

  // tslint:disable-next-line:variable-name
  getAll(params?: any): Observable<iPaginationResponse<ClientsModel>> {
    return this.api.getHttp().get<iPaginationResponse<ClientsModel>>(`${this.getUrl()}`, { params });
  }

  get(clientId: string) {
    return this._get<any>(clientId);
  }

  delete(clientId: string) {
    return this._delete<any>(clientId);
  }

  deleteDocument(clientId: string, documentId: string) {
    return this.api.getHttp().delete(this.url(clientId, 'documents', documentId));
  }
}
