<div class="elevatedFilter">
    <div class="row">
        <form [formGroup]="filterForm" class="form form-group-seperator-dashed">
            <div class="form-section form-section-first">
                <div class="row justify-content-center">
                    <div class="col-md-12 mb-4">
                        <span [inlineSVG]="'./assets/media/svg/icons/General/Filter.svg'" cacheSVG="true"
                            class="svg-icon svg-icon-sm svg-icon-primary">
                        </span> Filtros
                    </div>
                    <div class="col-md-12">
                        <div *ngFor="let field of advancedFilterService.fields">
                            <ng-container [ngSwitch]="field.type">
                                <ng-container *ngSwitchCase="fieldType.Text" [ngTemplateOutlet]="textField"
                                    [ngTemplateOutletContext]="{field: field}">
                                </ng-container>
                                <ng-container *ngSwitchCase="fieldType.Date" [ngTemplateOutlet]="dateField"
                                    [ngTemplateOutletContext]="{field: field}">
                                </ng-container>
                                <ng-container *ngSwitchCase="fieldType.Select" [ngTemplateOutlet]="textSelect"
                                    [ngTemplateOutletContext]="{field: field}">
                                </ng-container>
                                <ng-container *ngSwitchCase="fieldType.BRL" [ngTemplateOutlet]="brlField"
                                    [ngTemplateOutletContext]="{field: field}">
                                </ng-container>
                                <ng-container *ngSwitchCase="fieldType.Checkbox" [ngTemplateOutlet]="checkBox"
                                    [ngTemplateOutletContext]="{field: field}">
                                </ng-container>
                            </ng-container>
                        </div>
                    </div>
                    <div class="d-flex justify-content-center">
                        <a href="javascript:void(0);" (click)="clearFilter()">Limpar filtro</a>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>

<ng-template #textField let-field="field">
    <div [formGroup]="filterForm">
        <mat-form-field class="mat-form-field-fluid">
            <mat-label>{{field.label}}</mat-label>
            <input [formControlName]="field.name" matInput type="text" maxlength="50" />
        </mat-form-field>
    </div>
</ng-template>

<ng-template #dateField let-field="field">
    <div [formGroup]="filterForm">
        <mat-form-field class="mat-form-field-fluid">
            <mat-label>{{field.label}}</mat-label>
            <input [formControlName]="field.name" matInput [matDatepicker]="pickerVenc" [placeholder]="field.label">
            <mat-datepicker-toggle matSuffix [for]="pickerVenc"></mat-datepicker-toggle>
            <mat-datepicker #pickerVenc></mat-datepicker>
        </mat-form-field>
    </div>
</ng-template>

<ng-template #textSelect let-field="field">
    <div [formGroup]="filterForm">
        <mat-form-field class="mat-form-field-fluid">
            <mat-label>{{field.label}}</mat-label>
            <mat-select [formControlName]="field.name" >
                <mat-option *ngFor="let option of field.selectOptions" [value]="option.value">
                    {{option.label}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
</ng-template>

<ng-template #brlField let-field="field">
    <div [formGroup]="filterForm">
        <mat-form-field class="mat-form-field-fluid">
            <mat-label>
                <mat-label>{{field.label}}</mat-label>
            </mat-label>
            <input [formControlName]="field.name" matInput currencyMask
                [options]="{ prefix: 'R$', thousands: '.', decimal: ',' }" />
        </mat-form-field>
    </div>
</ng-template>

<ng-template #checkBox let-field="field">
    <div [formGroup]="filterForm">
        <mat-checkbox [formControlName]="field.name" class="mat-form-field-fluid">{{field.label}}
        </mat-checkbox>
    </div>
</ng-template>
