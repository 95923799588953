import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'kt-active-toggle',
  templateUrl: './active-toggle.component.html',
  styleUrls: ['./active-toggle.component.scss'],
})
export class ActiveToggleComponent {
  @Output() onChange = new EventEmitter<boolean>();

  @Input() selected = true;

  change(event) {
    this.selected = event.checked;
    this.onChange.emit(this.selected);
  }
}
