import { BalanceModel } from 'src/app/core/model/balance/balance.model';
import { BaseCrud } from '../helpers/baseCrud';
import { ApiService } from '../../api.service';

export class Balance extends BaseCrud {
  constructor(public api: ApiService) {
    super(api);
  }

  getUrl() {
    return `${this.api.getApiUrl()}/balance`;
  }

  getAll(queryString: string = '') {
    return this._getAll<BalanceModel>(queryString);
  }

  export(parameters) {
    return `${this.getUrl()}/export?start_date=${parameters.start_date}&end_date=${parameters.end_date}&expectation=${parameters.expectation
      }&type=${parameters.type}`;
  }
}
