<!--<a title='Deletar' (click)='clickOnButton()' class='btn btn-icon btn-light btn-hover-default btn-sm'  *ngIf="iconOnly; else editButton">-->
<!--    <i class="fas fa-trash fas-primary"></i>-->
<!--</a>-->
<button
  mat-icon-button
  color='primary'
  [title]="disabled? this.btnDisabledTitle : this.btnTitle"
  (click)='clickOnButton()'
  [disabled]='(loading | async) || disabled'
  *ngIf='iconOnly; else editButton'
>
  <ng-container *ngIf='!((loading | async) || isLoading)'>
    <i class='fas fa-trash' *ngIf='!custom; else content'></i>
  </ng-container>
  <div class='d-flex justify-content-center' *ngIf='(loading | async) || isLoading'>
    <mat-spinner diameter='18'></mat-spinner>
  </div>
</button>

<ng-template #editButton>
  <button
    mat-raised-button
    color='primary'
    (click)='clickOnButton()'
    [disabled]='(loading | async) || disabled'
    [ngClass]="{ 'spinner spinner-center spinner-md spinner-primary': (loading | async) || isLoading }"
  >
    <div *ngIf='!custom; else content'>
      <i class='fas fa-trash'></i>
      Deletar
    </div>
  </button>
</ng-template>

<ng-template #content>
  <ng-content></ng-content>
</ng-template>
