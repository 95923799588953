import { BaseCrud } from '../helpers/baseCrud';
import { ApiService } from '../../api.service';
import { CreateData } from '@model/tenant/document-lote/create-data.model';
import { Observable } from 'rxjs';
import { DocumentLote } from '@model/tenant/document-lote/document-lote.model';
import { LengthAwarePaginator } from '@model/common/length-aware-paginator.model';

export class DocumentsRoute extends BaseCrud {

  constructor(public api: ApiService) {
    super(api);
  }

  getUrl() {
    return `${this.api.getApiUrl()}/documents-lote`;
  }

  list(params?: any): Observable<LengthAwarePaginator<DocumentLote[]>> {
    return this.api.getHttp().get<LengthAwarePaginator<DocumentLote[]>>(this.url(), { params });
  }

  get(id: string): Observable<DocumentLote> {
    return this.api.getHttp().get<DocumentLote>(this.url(id));
  }

  create(data: CreateData): Observable<DocumentLote> {
    return this.api.getHttp().post<DocumentLote>(this.url(), data);
  }

  downloadUrl(id: string) {
    return this.url(id, 'download');
  }

  download(id: string) {
    return this.api.getHttp().get(this.url(id, 'download'));
  }

  cancel(id: string) {
    return this.api.getHttp().post(this.url(id, 'cancel'), {});
  }

  delete(id: string) {
    return this.api.getHttp().delete(this.url(id));
  }
}
