<div class="card card-custom bg-gray-100" [ngClass]="cssClasses">
	<!-- Header -->
	<div class="card-header border-0 bg-danger py-5">
		<h3 class="card-title font-weight-bolder text-white">Sales Stat</h3>
		<div class="card-toolbar">
			<div ngbDropdown [placement]="'bottom-right'" class="dropdown dropdown-inline" title="Quick actions">
				<a  ngbDropdownToggle
					class="btn btn-transparent-white btn-sm font-weight-bolder dropdown-toggle px-5"
					data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
					Export
				</a>
				<div ngbDropdownMenu class="dropdown-menu dropdown-menu-sm dropdown-menu-right">
					<kt-dropdown2></kt-dropdown2>
				</div>
			</div>			
		</div>
	</div>
	<!-- Body -->
	<div class="card-body p-0 position-relative overflow-hidden">
        <!--begin::Chart-->
        <div id="kt_mixed_widget_1_chart" class="card-rounded-bottom bg-danger" [style.height]="'200px'">
        <apx-chart
            [series]="chartOptions.series"
            [chart]="chartOptions.chart"
            [xaxis]="chartOptions.xaxis"
            [yaxis]="chartOptions.yaxis"
            [dataLabels]="chartOptions.dataLabels"            
            [stroke]="chartOptions.stroke"
            [legend]="chartOptions.legend"
            [fill]="chartOptions.fill"
            [states]="chartOptions.states"
            [tooltip]="chartOptions.tooltip"
            [colors]="chartOptions.colors"
            [markers]="chartOptions.markers"
            [plotOptions]="chartOptions.plotOptions"
          ></apx-chart>

        </div>
        <!--end::Chart-->

        <!--begin::Stats-->
        <div class="card-spacer mt-n25">
            <!--begin::Row-->
            <div class="row m-0">
                <div class="col bg-light-warning px-6 py-8 rounded-xl mr-7 mb-7">
                    <span [inlineSVG]="'./assets/media/svg/icons/Media/Equalizer.svg'"
                        class="svg-icon svg-icon-3x svg-icon-warning d-block my-2"></span>
                    <a href="#" class="text-warning font-weight-bold font-size-h6">
                        Weekly Sales
                    </a>
                </div>
                <div class="col bg-light-primary px-6 py-8 rounded-xl mb-7">
                    <span [inlineSVG]="'./assets/media/svg/icons/Communication/Add-user.svg'"
                        class="svg-icon svg-icon-3x svg-icon-primary d-block my-2"></span>
                    <a href="#" class="text-primary font-weight-bold font-size-h6 mt-2">
                        New Users
                    </a>
                </div>
            </div>
            <!--end::Row-->
            <!--begin::Row-->
            <div class="row m-0">
                <div class="col bg-light-danger px-6 py-8 rounded-xl mr-7">
                    <span [inlineSVG]="'./assets/media/svg/icons/Design/Layers.svg'"
                        class="svg-icon svg-icon-3x svg-icon-danger d-block my-2"></span>
                    <a href="#" class="text-danger font-weight-bold font-size-h6 mt-2">
                        Item Orders
                    </a>
                </div>
                <div class="col bg-light-success px-6 py-8 rounded-xl">
                    <span [inlineSVG]="'./assets/media/svg/icons/Communication/Urgent-mail.svg'"
                        class="svg-icon svg-icon-3x svg-icon-success d-block my-2"></span>
                    <a href="#" class="text-success font-weight-bold font-size-h6 mt-2">
                        Bug Reports
                    </a>
                </div>
            </div>
            <!--end::Row-->
        </div>
        <!--end::Stats-->
    </div>
</div>