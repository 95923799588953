import { Observable } from 'rxjs';
import { ApiService } from '../../../api.service';
import {
  CreateDocument,
  GetAdministrativeDocumentAttachment,
  GetDocumentResult,
  UpdateDocument,
} from '@model/tenant/administrative-document';
import { LengthAwarePaginator } from '@model/common/length-aware-paginator.model';
import { BaseCrud } from '../../helpers/baseCrud';

export class Documents extends BaseCrud {
  constructor(public apiService: ApiService) {
    super(apiService);
  }

  getUrl(): string {
    return `${this.apiService.getApiUrl()}/administrative-documents`;
  }

  getAll(params?: any): Observable<LengthAwarePaginator<GetDocumentResult[]>> {
    return this.apiService.getHttp().get<LengthAwarePaginator<GetDocumentResult[]>>(`${this.getUrl()}`, { params });
  }

  get(id: string): Observable<GetDocumentResult> {
    return this.apiService.getHttp().get<GetDocumentResult>(`${this.getUrl()}/${id}`);
  }

  create(data: CreateDocument): Observable<GetDocumentResult> {
    const formData = new FormData();
    data.attachments.forEach(attachment => formData.append('attachments[]', attachment, attachment.name));
    formData.append('name', data.name);
    formData.append('description', data.description);

    return this.apiService.getHttp().post<GetDocumentResult>(`${this.getUrl()}`, formData);
  }

  update(id: string, data: UpdateDocument): Observable<GetDocumentResult> {
    return this.apiService.getHttp().put<GetDocumentResult>(`${this.getUrl()}/${id}`, data);
  }

  delete(id: string): Observable<GetDocumentResult> {
    return this.apiService.getHttp().delete<GetDocumentResult>(`${this.getUrl()}/${id}`);
  }

  toggleStatus(id: string): Observable<GetDocumentResult> {
    return this.apiService.getHttp().put<GetDocumentResult>(`${this.getUrl()}/${id}/toggle-status`, []);
  }

  downloadAttachment(documentId: string, attachmentId: string): Observable<any> {
    return this.apiService.getHttp().get(`${this.getUrl()}/${documentId}/download/${attachmentId}`, {
      responseType: 'blob',
    });
  }

  attachmentUrl(documentId: string, attachmentId: string): string {
    return this.url(documentId, 'download', attachmentId);
  }

  deleteAttachment(documentId: string, attachmentId: string): Observable<GetAdministrativeDocumentAttachment> {
    return this.apiService
      .getHttp()
      .delete<GetAdministrativeDocumentAttachment>(`${this.getUrl()}/${documentId}/attachment/${attachmentId}`);
  }

  storeAttachment(id: string, files: File[]): Observable<GetDocumentResult> {
    const formData = new FormData();
    files.forEach(file => formData.append('attachments[]', file, file.name));
    return this.apiService.getHttp().post<GetDocumentResult>(`${this.getUrl()}/${id}/attachment`, formData);
  }
}
