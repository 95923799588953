import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'kt-page-loading-spinner',
  templateUrl: './page-loading-spinner.component.html',
})
export class PageLoadingSpinnerComponent implements OnInit {

  @Input() size: number = 50;
  constructor() { }

  ngOnInit(): void {
  }

}
