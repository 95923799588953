import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'kt-button-upload',
  templateUrl: './button-upload.component.html',
})
export class ButtonUploadComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
