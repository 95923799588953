import { BehaviorSubject, Observable, catchError, throwError } from 'rxjs';
import { ApiErrorHandler } from '../core/services';

export class AlertControl {
  showAlert = new BehaviorSubject<boolean>(false);
  errorMessages: string[];
  interval = null;

  onAlertClose() {
    this.showAlert.next(false);
  }

  showError(message: string | string[], time = 0) {
    this.errorMessages = Array.isArray(message) ? message : [ message ];
    this.showAlert.next(true);

    if (time) {
      if (this.interval) clearTimeout(this.interval);

      this.interval = setTimeout(() => {
        this.showAlert.next(false);
      }, time);
    }
  }

  pipeError() {
    return (observable: Observable<any>) =>
      observable.pipe(
        catchError(_ => {
          this.showError(ApiErrorHandler.getErrors(_));
          return throwError(() => _);
        }),
      );
  }
}
