import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UserModel } from '../model/users-system.model';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ClientsModel } from '../model/clients.model';
import { StatesModel } from '../model/states.model';
import { CitiesModel } from '../model/cities.model';
import { EmployeesModel } from '../model/employees.model';
import { CepsModel } from '../model/ceps.model';
import { PermissionsModel } from '../model/permissions.model';
import { RolesModel } from '../model/roles.model';
import { ApiService } from './api/api.service';

@Injectable({
  providedIn: 'root',
})
export class UsersSystemService {
  constructor(private http: HttpClient, private apiService: ApiService) {
  }

  // SELECTS BOXES
  getStates(): Observable<StatesModel> {
    return this.http.get<StatesModel>(`${this.apiService.getApiUrl()}/states`);
  }

  getCepDetails(cep): Observable<CepsModel> {
    return this.http.get<CepsModel>(`${this.apiService.getApiUrl()}/ceps/${cep}`);
  }

  getCities(): Observable<CitiesModel> {
    return this.http.get<CitiesModel>(`${this.apiService.getApiUrl()}/cities`);
  }

  getPermissions(userId): Observable<PermissionsModel> {
    return this.http.get<PermissionsModel>(`${this.apiService.getApiUrl()}/users/${userId}/permissions`);
  }

  getPaymentCategory(arg): Observable<any> {
    return this.http.get<any>(`${this.apiService.getApiUrl()}/payment-categories/${arg}`);
  }

  getNegociationTypes(): Observable<any> {
    return this.http.get<any>(`${this.apiService.getApiUrl()}/negociation-types`);
  }

  getProductCategory(): Observable<any> {
    return this.http.get<any>(`${this.apiService.getApiUrl()}/product-categories`);
  }

  getProductMargins(): Observable<any> {
    return this.http.get<any>(`${this.apiService.getApiUrl()}/product-margins`);
  }

  getCityById(id: number): Observable<CitiesModel> {
    return this.http.get<CitiesModel>(`${this.apiService.getApiUrl()}/cities/by-state/${id}`);
  }

  getUserById(userId: number): Observable<UserModel> {
    return this.http.get<UserModel>(`${this.apiService.getApiUrl()}/users/${userId}`);
  }

  getUsers(): Observable<UserModel> {
    return this.http.get<UserModel>(`${this.apiService.getApiUrl()}/users`);
  }

  createUser(user: UserModel): Observable<UserModel> {
    return this.http.post<UserModel>(`${this.apiService.getApiUrl()}/users`, user);
  }

  deleteUser(user: number): Observable<UserModel> {
    const url = `${this.apiService.getApiUrl()}/users/${user}`;
    return this.http.delete<UserModel>(url);
  }

  updateUser(user: UserModel): Observable<UserModel> {
    return this.http.put<UserModel>(`${this.apiService.getApiUrl()}/users/${user.id}`, user);
  }

  // clientes
  getClients(): Observable<ClientsModel> {
    return this.http.get<ClientsModel>(`${this.apiService.getApiUrl()}/clients`);
  }

  searchClient(args: any): Observable<ClientsModel> {
    return this.http.get<ClientsModel>(`${this.apiService.getApiUrl()}/clients`, {
      params: new HttpParams()
        .set('filter', args.filter.words)
        .set('sortOrder', args.sortOrder)
        .set('pageNumber', args.pageNumber.toString())
        .set('pageSize', args.pageSize.toString()),
    });
  }

  createClient(client: ClientsModel): Observable<ClientsModel> {
    return this.http.post<ClientsModel>(`${this.apiService.getApiUrl()}/clients`, client);
  }

  getClientById(id: number): Observable<ClientsModel> {
    return this.http.get<ClientsModel>(`${this.apiService.getApiUrl()}/clients/${id}`);
  }

  deleteClient(id: number): Observable<ClientsModel> {
    return this.http.delete<ClientsModel>(`${this.apiService.getApiUrl()}/clients/${id}`);
  }

  updateClient(data: ClientsModel): Observable<ClientsModel> {
    return this.http.put<ClientsModel>(`${this.apiService.getApiUrl()}/clients/${data.id}`, data);
  }

  // funcionarios
  getEmployeesById(userId: number): Observable<EmployeesModel> {
    return this.http.get<EmployeesModel>(`${this.apiService.getApiUrl()}/employees/${userId}`);
  }

  getEmployees(): Observable<EmployeesModel> {
    return this.http.get<EmployeesModel>(`${this.apiService.getApiUrl()}/employees`);
  }

  createEmployees(user: EmployeesModel): Observable<EmployeesModel> {
    return this.http.post<EmployeesModel>(`${this.apiService.getApiUrl()}/employees`, user);
  }

  deleteEmployees(user: number): Observable<EmployeesModel> {
    return this.http.delete<EmployeesModel>(`${this.apiService.getApiUrl()}/employees/${user}`);
  }

  getEmployeeById(id: number): Observable<EmployeesModel> {
    return this.http.get<EmployeesModel>(`${this.apiService.getApiUrl()}/employees/${id}`);
  }

  updateEmployee(user: EmployeesModel): Observable<EmployeesModel> {
    return this.http.put<EmployeesModel>(`${this.apiService.getApiUrl()}/employees/${user.id}`, user);
  }

  searchEmployees(words: string, sort: string, order: string, page: number): Observable<EmployeesModel> {
    const url = `${this.apiService.getApiUrl()}/employees`;
    const requestUrl = `${url}?q=${words}&sort=${sort}&order=${order}&page=${page + 1}`;
    return this.http.get<EmployeesModel>(requestUrl);
  }
 
  //#region Permissões
  getAllPermissions(): Observable<PermissionsModel[]> {
    return this.http.get<PermissionsModel[]>(`${this.apiService.getApiUrl()}/permissions`);
  }

  //#endregion

  //#region Cargos
  createRole(roleInfo): Observable<any> {
    return this.http.post<any>(`${this.apiService.getApiUrl()}/roles`, roleInfo);
  }

  getAllRoles(): Observable<RolesModel[]> {
    return this.http.get<RolesModel[]>(`${this.apiService.getApiUrl()}/roles`);
  }

  getRole(roleId: number): Observable<RolesModel> {
    return this.http.get<RolesModel>(`${this.apiService.getApiUrl()}/roles/${roleId}`);
  }

  deleteRole(roleId: number): Observable<RolesModel> {
    return this.http.delete<RolesModel>(`${this.apiService.getApiUrl()}/roles/${roleId}`);
  }

  //#endregion
}
