import { Observable } from 'rxjs';
import { LengthAwarePaginator } from '@model/common/length-aware-paginator.model';
import { BaseCrud } from '@core/services/api/routes/helpers/baseCrud';


export class NotificationWorkflowRoute extends BaseCrud {

  getUrl(): string {
    return `${this.api.getApiUrl()}/notification-workflows`;
  }

  list(params?: any): Observable<LengthAwarePaginator<any[]>> {
    return this.api.getHttp().get<LengthAwarePaginator<any[]>>(this.getUrl(), { params });
  }

  get(id: string): Observable<any> {
    return this.api.getHttp().get<any>(this.url(id));
  }

  create(data: any): Observable<any> {
    return this.api.getHttp().post<any>(this.getUrl(), data);
  }

  update(id: string, data: any): Observable<any> {
    return this.api.getHttp().put<any>(this.url(id), data);
  }

  delete(id: string): Observable<any> {
    return this.api.getHttp().delete<any>(this.url(id));
  }
}
