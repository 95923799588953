import { ApiService } from 'src/app/core/services/api/api.service';
import { BaseCrud } from 'src/app/core/services/api/routes/helpers/baseCrud';
import { Observable } from 'rxjs';
import { ExpensePreviewByCategory, PeriodicValue } from '@model/tenant/dashboard';

export class ExpenseRoute extends BaseCrud {
  constructor(public apiService: ApiService) {
    super(apiService);
  }

  getUrl(): string {
    return `${this.apiService.getApiUrl()}/stats/expenses`;
  }

  getMonthlyExpenseSnapshot(params: any): Observable<PeriodicValue[]> {
    return this.apiService.getHttp().get<PeriodicValue[]>(this.url('monthly-snapshot'), { params });
  }

  getDailyExpenseSnapshot(params: any): Observable<PeriodicValue[]> {
    return this.apiService.getHttp().get<PeriodicValue[]>(this.url('daily-snapshot'), { params });
  }

  getMonthExpensePreview(): Observable<PeriodicValue> {
    return this.apiService.getHttp().get<PeriodicValue>(this.url('month-preview'));
  }

  getMonthCreditExpenseSnapshot(): Observable<PeriodicValue> {
    return this.apiService.getHttp().get<PeriodicValue>(this.url('month-credit-snapshot'));
  }

  getCategoriesExpensePreview(params: any): Observable<ExpensePreviewByCategory[]> {
    return this.apiService.getHttp().get<ExpensePreviewByCategory[]>(this.url('preview-by-category'), { params });
  }

  getMonthOverdueExpenseSnapshot(): Observable<PeriodicValue[]> {
    return this.apiService.getHttp().get<PeriodicValue[]>(this.url('monthly-overdue-snapshot'));
  }
}
