import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { MaterialButtonColors } from '@core/types/material-button-colors';

@Component({
  selector: 'kt-basic-button',
  templateUrl: './basic-button.component.html',
  styleUrls: [ './basic-button.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BasicButtonComponent {
  @Input() class = '';
  @Input() loading = false;
  @Input() onlyIcon = false;
  @Input() disabled = false;
  @Input() color: MaterialButtonColors = 'primary';
  @Input() changeToOnHover = '';

  @Output() handle = new EventEmitter<void>();

  hovered = false;

  onClick() {
    if (this.disabled) {
      return;
    }

    this.handle.emit();
  }

  onHoverIn() {
    if (this.changeToOnHover) {
      this.hovered = true;
    }
  }

  onHoverOut() {
    this.hovered = false;
  }

}
