import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'kt-test-badge',
  templateUrl: './test-badge.component.html',
  styleUrls: ['./test-badge.component.scss']
})
export class TestBadgeComponent implements OnInit {

  env = environment;

  constructor() { }

  ngOnInit(): void {
  }

}
