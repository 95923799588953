import { BaseCrud } from '../helpers/baseCrud';
import { ApiService } from '../../api.service';

export class Documents extends BaseCrud {
  constructor(public apiService: ApiService) {
    super(apiService);
  }

  getUrl(): string {
    return `${this.apiService.getApiUrl()}/documents`;
  }

  destroy(entityId: string) {
    return this.apiService.getHttp().delete(this.url(entityId));
  }
}
