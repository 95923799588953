<button mat-icon-button color='primary' (click)='clickOnButton()' *ngIf='iconOnly; else editButton'>
  <i class='fas fa-ban' *ngIf='!custom; else content'></i>
</button>
<ng-template #editButton>
  <button
    mat-raised-button
    color='primary'
    (click)='clickOnButton()'
    [disabled]='(loading | async) || disabled'
    [ngClass]="{ 'spinner spinner-center spinner-md spinner-primary': (loading | async) || isLoading }"
  >
    <div *ngIf='!custom; else content'>
      <i class='fas fa-trash'></i>
      Cancelar
    </div>
  </button>
</ng-template>

<ng-template #content>
  <ng-content></ng-content>
</ng-template>
