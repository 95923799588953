<!--begin: Search -->
<div class="topbar-item dropdown">
	<div class="topbar-wrapper">
		<div ngbDropdown placement="bottom" autoClose="outside" [ngClass]="{'quick-search-has-result': data?.length}" class="quick-search quick-search-dropdown quick-search-result-compact" id="kt_quick_search_default">
			<form method="get" class="quick-search-form">
				<div ngbDropdownToggle class="input-group" [ngClass]="{'kt-spinner kt-spinner--input kt-spinner--sm kt-spinner--brand kt-spinner--right': loading}">
					<div class="input-group-prepend">
						<span class="input-group-text">
							<i *ngIf="!useSVG" [ngClass]="icon"></i>
							<span class="kt-svg-icon" *ngIf="useSVG" [inlineSVG]="icon"></span>
						</span>
					</div>
					<input #searchInput (keyup)="search($event)" type="text" class="form-control" />
					<div class="input-group-append">
						<span class="input-group-text"><i (click)="clear($event)" [ngStyle]="{'display': 'flex'}" [hidden]="!data || !data?.length" class="la la-close kt-quick-search__close"></i></span>
					</div>
				</div>
			</form>
			<div ngbDropdownMenu *ngIf="data?.length" class="dropdown-menu p-0 m-0 dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-lg">
				<div [perfectScrollbar]="{wheelPropagation: false}" [ngStyle]="{'max-height': '40vh', 'position': 'relative'}" class="quick-search-wrapper">
					<kt-search-result [data]="data"></kt-search-result>
				</div>
			</div>
		</div>
	</div>
</div>
<!--end: Search -->
