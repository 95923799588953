import { Component, Input } from '@angular/core';

@Component({
  selector: 'kt-label-outline',
  templateUrl: './label-outline.component.html',
})
export class LabelOutlineComponent {

  @Input() color: string = 'primary';

}
