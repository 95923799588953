import { Component, Input } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'kt-button-history',
  templateUrl: './button-history.component.html',
})
export class ButtonHistoryComponent {
  @Input() loading = new BehaviorSubject(false);
  @Input() iconOnly = true;
}
