import { MatTableDataSource } from '@angular/material/table';
import { DocumentModel } from 'src/app/core/model/documents/document.model';
import { UploadAttachmentModel } from 'src/app/core/model/uploadAttachment.model';
import { ApiService } from '../../api.service';
import { BaseCrud } from './baseCrud';
import { FileInput } from 'ngx-material-file-input';

export abstract class EntityHasDocuments extends BaseCrud {
  public abstract documentPath: string;

  constructor(public api: ApiService) {
    super(api);
  }

  createWithDocuments(createInfo, documents: DocumentModel[]) {
    let files = null;
    let filesData = null;
    if (documents && documents.length > 0) {
      const filesMap = documents.map(document => ({
        document_profile_id: document.document_profile_id,
        file_post_name: 'document-' + document.document_profile_id,
        attachment: { ...document.attachment, fileKey: 'document-' + document.document_profile_id },
      }));

      files = filesMap.map(document => document.attachment);
      filesData = filesMap.map(({ document_profile_id, file_post_name }) => ({
        document_profile_id,
        file_post_name,
      }));
    }

    return this._create<any>({ ...createInfo, documentFilesMap: filesData }, files);
  }

  updateWithDocuments(updateInfo, documents: DocumentModel[]) {
    let files = null;
    let filesData = null;
    if (documents && documents.length > 0) {
      const filesMap = documents.map(document => ({
        document_profile_id: document.document_profile_id,
        file_post_name: 'document-' + document.document_profile_id,
        attachment: { ...document.attachment, fileKey: 'document-' + document.document_profile_id },
      }));

      files = filesMap.map(document => document.attachment);
      filesData = filesMap.map(({ document_profile_id, file_post_name }) => ({
        document_profile_id,
        file_post_name,
      }));
    }

    return this._update<any>({ ...updateInfo, documentFilesMap: filesData }, files);
  }

  createWithDocumentsTable(
    createInfo,
    documentTable?: MatTableDataSource<{ id: number; attachment: UploadAttachmentModel }>,
  ) {
    let files = null;
    let filesData = null;
    if (documentTable) {
      const tableData = documentTable.data.filter(document => document.attachment);
      const filesMap = tableData.map(document => ({
        document_profile_id: document.id,
        file_post_name: 'document-' + document.id,
        attachment: { ...document.attachment, fileKey: 'document-' + document.id },
      }));
      files = filesMap.map(document => document.attachment);
      filesData = filesMap.map(({ document_profile_id, file_post_name }) => ({
        document_profile_id,
        file_post_name,
      }));
    }
    return this._create<any>({ ...createInfo, documentFilesMap: filesData }, files);
  }

  documents(entityId: string) {
    const http = this.api.getHttp();
    return http.get<DocumentModel[]>(`${this.api.getApiUrl()}/${this.documentPath}/${entityId}`);
  }

  requiredDocuments(entityName: string, type: string = 'PF') {
    const http = this.api.getHttp();
    return http.get<DocumentModel[]>(`${this.api.getApiUrl()}/${entityName}-required-documents?type=${type}`);
  }

  documentUrl(entityId: string, documentId: string) {
    return this.url(entityId, 'documents', documentId, 'download');
  }

  addDocuments(entityId: string, documents: DocumentModel | DocumentModel[]) {
    const http = this.api.getHttp();
    return http.post<DocumentModel>(`${this.api.getApiUrl()}/${this.documentPath}/${entityId}`, documents);
  }

  uploadDocumentAttachment(entityId: string, documentId: string, file: UploadAttachmentModel, options = null) {
    const http = this.api.getHttp();
    const formData = new FormData();
    formData.append(file.fileKey, file.fileData.files[0], file.fileData.files[0].name);

    formData.append('document_id', `${documentId}`);

    return http.post<DocumentModel>(
      `${this.api.getApiUrl()}/${this.documentPath}/${entityId}/document/attachment`,
      formData,
      options,
    );
  }
}
