import { CreateCompany, UpdateCompany } from 'src/app/core/model/central';
import { Observable } from 'rxjs';
import { LengthAwarePaginator } from 'src/app/core/model/common/length-aware-paginator.model';
import { BaseCrud } from '@core/services/api/routes/helpers/baseCrud';
import { SubsidiaryModel } from '@model/tenant/subsidiary/subsidiary.model';
import { ApiService } from '@core/services/api';
import { UploadAttachmentModel } from '@model/uploadAttachment.model';

export class SubsidiariesRoute extends BaseCrud {
  constructor(public api: ApiService) {
    super(api);
  }

  getUrl(): string {
    return `${this.api.getApiUrl()}/subsidiaries`;
  }

  stats(params: any) {
    const q = this.toQuery(params);
    return this.api.getHttp().get<any>(this.url('stats') + q);
  }

  getAll(params?: any): Observable<LengthAwarePaginator<SubsidiaryModel[]>> {
    return this.api.getHttp().get<LengthAwarePaginator<SubsidiaryModel[]>>(this.getUrl(), { params });
  }

  get(id: string): Observable<SubsidiaryModel> {
    return this.api.getHttp().get<SubsidiaryModel>(`${this.getUrl()}/${id}`);
  }

  create(data: SubsidiaryModel, files?: UploadAttachmentModel[]): Observable<SubsidiaryModel> {
    return this._create(data, files ? files : []);
  }

  update(data: SubsidiaryModel, files?: UploadAttachmentModel[]): Observable<SubsidiaryModel> {
    return this._update(data, files ? files : []);
  }

  setActive(subsidiaryId: string, active: boolean): Observable<null> {
    return this._update({ active }, null, this.url(subsidiaryId, 'active'));
  }

  delete(id: string): Observable<null> {
    return this._delete(id);
  }
}
