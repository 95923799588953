<ng-container *ngIf="selfLayout !== 'blank'; else blankLayout">
  <div class='d-flex flex-column flex-root'>
    <div class='d-flex flex-row flex-column-fluid page'>
      <ng-container *ngIf='asideSelfDisplay'>
        <kt-aside-left></kt-aside-left>
      </ng-container>
      <div class='d-flex flex-column flex-row-fluid wrapper' id='kt_wrapper'>
        <kt-header-mobile></kt-header-mobile>
        <kt-header></kt-header>
        <div class='content d-flex flex-column flex-column-fluid' id='kt_content' [ngClass]='contentClasses'>
          <ng-container *ngIf='subheaderDisplay'>
            <kt-subheader></kt-subheader>
          </ng-container>
          <div ktContentAnimate class='d-flex flex-column-fluid'>
            <div [ngClass]='contentContainerClasses'>
              <router-outlet></router-outlet>
            </div>
          </div>
        </div>
      </div>
    </div>

    <kt-scroll-top></kt-scroll-top>
  </div>
</ng-container>

<ng-template #blankLayout>
  <div class='d-flex flex-column flex-root'>
    <router-outlet></router-outlet>
  </div>
</ng-template>
