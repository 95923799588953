import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fiscalRpsNumber'
})
export class FiscalRpsNumberPipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): unknown {
    if(!value) return '';
    let year = value.substr(0, 4);
    let stringNumber = value.substr(4);
    return `${year}/${parseInt(stringNumber)}`;
  }

}
