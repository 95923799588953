import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'documentNumber',
})
export class DocumentNumberPipe implements PipeTransform {

  transform(value: string, mask: string = '00000000'): unknown {
    if (!value) {
      return '';
    }

    const numberIsRecurrence = value.split('/').length > 1;
    if (numberIsRecurrence) {
      const [ number, parcel ] = value.split('/');
      const documentNumber = this.convertNumber(number);
      return `${documentNumber}-${parcel}`;
    }

    return this.convertNumber(value);
  }

  convertNumber(number: string): string {
    let numberString: string = number.toString();
    const zerosNeeded: number = 9 - numberString.length;
    return '0'.repeat(zerosNeeded) + numberString;
  }
}
