import { ReferenceModel } from 'src/app/core/model/reference.model';
import { BaseCrud } from '../helpers/baseCrud';
import { ApiService } from '../../api.service';

export class References extends BaseCrud {
  constructor(public api: ApiService) {
    super(api);
  }

  getUrl() {
    return `${this.api.getApiUrl()}/references`;
  }

  get() {
    return this._getAll<ReferenceModel[]>();
  }

  update(referenceId: string, isOpen: boolean) {
    let http = this.api.getHttp();
    return http.put(`${this.getUrl()}/${referenceId}`, {
      isOpen: isOpen ? 1 : 0,
    });
  }
}
