export const environment = {
  appUrl: 'https://financeiro.qa.inovedados.com',
  centralUrl: 'https://financeiro.qa.inovedados.com',
  appVersion: 'v1',
  appProduction: false,

  apiUrl: 'https://financeiro-api.qa.inovedados.com',
  centralApiUrl: 'https://financeiro-api.qa.inovedados.com',

  authTokenKey: 'authce9d77b308c149d5992a80073637e4d5',
  maxUploadSize: 20000000, //Bytes
};
