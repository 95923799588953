import { ApiService } from 'src/app/core/services/api/api.service';
import { BaseCrud } from 'src/app/core/services/api/routes/helpers/baseCrud';
import { Observable } from 'rxjs';
import { PeriodicValue } from '@model/tenant/dashboard';
import { StatsFilters } from '@model/tenant/stats/filters.model';
 
export class RevenueRoute extends BaseCrud {
  constructor(public apiService: ApiService) {
    super(apiService);
  }

  getUrl(): string {
    return `${this.apiService.getApiUrl()}/stats/revenues`;
  }

  getMonthlyRevenueSnapshot(params = {}): Observable<PeriodicValue[]> {
    return this.apiService.getHttp().get<PeriodicValue[]>(this.url('monthly-snapshot'), { params });
  }

  getDailyRevenueSnapshot(params = {}): Observable<PeriodicValue[]> {
    return this.apiService.getHttp().get<PeriodicValue[]>(this.url('daily-snapshot'), { params });
  }

  getMonthRevenuePreview(): Observable<PeriodicValue> {
    return this.apiService.getHttp().get<PeriodicValue>(this.url('month-preview'));
  }

  getCategoriesRevenuePreview(params = {}) {
    return this.apiService.getHttp().get(this.url('preview-by-category'), { params });
  }

  getMonthOverdueRevenueSnapshot(): Observable<PeriodicValue[]> {
    return this.apiService.getHttp().get<PeriodicValue[]>(this.url('monthly-overdue-snapshot'));
  }
}
