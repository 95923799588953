import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'kt-button-visible-off',
  templateUrl: './button-visible-off.component.html',
})
export class ButtonVisibleOffComponent implements OnInit {

  @Input() loading = false;
  constructor() { }

  ngOnInit(): void {
  }

}
