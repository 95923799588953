import { CommonPerm } from '@enum/common/permission/common-perm.enum';
import { TenantPerm } from '@enum/tenant/permission/tenant-perm.enum';

export const ConfigurationSection = [
  {
    section: 'Configurações',
    permission: [
      '*',
      TenantPerm.StandardLaunch_View, TenantPerm.Categories_View,
      TenantPerm.Subsidiaries_View,
      TenantPerm.EmailTypes_View, TenantPerm.PhoneTypes_View,
      TenantPerm.FiscalRps_Configure, TenantPerm.BankSlip_Configure,
      TenantPerm.DocumentProfiles_View,
      CommonPerm.Roles_View,
    ],
  },
  {
    title: 'Plano de contas',
    root: true,
    icon: 'fas fa-piggy-bank',
    bullet: 'dot',
    permission: ['*', TenantPerm.StandardLaunch_View, TenantPerm.Categories_View],
    submenu: [
      {
        title: 'Lançamento contábil',
        permission: ['*', TenantPerm.StandardLaunch_View],
        root: true,
        page: '/painel/lancamento-contabil',
        bullet: 'dot',
      },
      {
        title: 'Categorias',
        permission: ['*', TenantPerm.Categories_View],
        root: true,
        page: '/painel/categorias',
        bullet: 'dot',
      },
    ],
  },
  {
    title: 'Filiais',
    permission: ['*', TenantPerm.Subsidiaries_View],
    root: true,
    icon: 'fas fa-building',
    page: '/painel/fiscal/filiais/list',
    bullet: 'dot',
  },
  {
    title: 'Cobranças',
    root: true,
    icon: 'fas fa-mail-bulk',
    bullet: 'dot',
    permission: ['*', TenantPerm.Templates_View, TenantPerm.Workflows_View, TenantPerm.Layout_Edit],
    submenu: [
      {
        title: 'Layout',
        permission: [TenantPerm.Layout_Edit],
        root: true,
        bullet: 'dot',
        page: '/painel/configuracoes/cobranca/layout',
      },
      {
        title: 'Templates',
        permission: [TenantPerm.Templates_View],
        root: true,
        page: '/painel/configuracoes/cobranca/templates',
        bullet: 'dot',
      },
      {
        title: 'Fluxo de cobrança',
        permission: [TenantPerm.Workflows_View],
        root: true,
        page: '/painel/configuracoes/cobranca/fluxo',
        bullet: 'dot',
      },
    ],
  },
  {
    title: 'Parâmetros',
    permission: ['*', TenantPerm.EmailTypes_View, TenantPerm.PhoneTypes_View],
    root: true,
    bullet: 'dot',
    icon: 'fas fa-gear',
    submenu: [
      {
        title: 'Tipos de contato',
        root: true,
        bullet: 'dot',
        submenu: [
          {
            title: 'E-mails',
            permission: ['*', TenantPerm.EmailTypes_View],
            page: '/painel/configuracoes/tipo-email',
          },
          {
            title: 'Telefone',
            permission: ['*', TenantPerm.PhoneTypes_View],
            page: '/painel/configuracoes/tipo-telefone',
          },
        ],
      },
      {
        title: 'Financeiros',
        root: true,
        bullet: 'dot',
        permission: ['*', TenantPerm.FiscalRps_Configure, TenantPerm.BankSlip_Configure],
        submenu: [
          {
            title: 'Nota fiscal',
            permission: ['*', TenantPerm.FiscalRps_Configure],
            root: true,
            bullet: 'dot',
            page: '/painel/configuracoes/fiscal-rps',
          },
          {
            title: 'Boleto',
            permission: ['*', TenantPerm.BankSlip_Configure],
            root: true,
            bullet: 'dot',
            page: '/painel/configuracoes/boleto',
          },
        ],
      },
      {
        title: 'Perfil de documentos',
        permission: ['*', TenantPerm.DocumentProfiles_View],
        root: true,
        bullet: 'dot',
        page: '/painel/perfil-de-documentos',
      },
      {
        title: 'Cargos e permissões',
        permission: ['*', CommonPerm.Roles_View],
        root: true,
        page: '/painel/cargos',
        bullet: 'dot',
      },
    ],
  },
  {
    title: 'Api',
    permission: ['*', TenantPerm.AccessKeys_View],
    root: true,
    icon: 'fas fa-code',
    page: '/painel/configuracoes/chave-acesso-api',
    bullet: 'dot',
  },
  {
    title: 'Migração de dados',
    permission: ['*', TenantPerm.DataMigration_View],
    root: true,
    icon: 'fas fa-exchange-alt',
    bullet: 'dot',
    submenu: [
      {
        title: 'Importação',
        permission: ['*', TenantPerm.DataMigration_View],
        root: true,
        page: '/painel/configuracoes/migracao/importacao',
        bullet: 'dot',
      },
      {
        title: 'Exportação',
        permission: ['*', TenantPerm.DataMigration_View],
        root: true,
        page: '/painel/configuracoes/migracao/exportacao',
        bullet: 'dot',
      },
    ],
  },
  {
    title: 'Cópia de segurança',
    permission: ['*', TenantPerm.BackupData_View],
    root: true,
    icon: 'fas fa-database',
    page: '/painel/configuracoes/copia-de-seguranca',
    bullet: 'dot',
  },
];
