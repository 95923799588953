import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'kt-button-check',
  templateUrl: './button-check.component.html',
})
export class ButtonCheckComponent implements OnInit {

  @Input() loading = false;
  constructor() { }

  ngOnInit(): void {
  }

}
