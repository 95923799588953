import { GetEmailTypeResult } from "./get-email-type.model";

export class GetEmailResult {
    id: string;
    email: string;
    description?: string;
    type: GetEmailTypeResult;

    constructor(data: GetEmailResult) {
        this.id = data.id;
        this.email = data.email;
        this.description = data.description;
        this.type = new GetEmailTypeResult(data.type);
    }
}