import { Observable } from 'rxjs';
import { EmployeesModel } from 'src/app/core/model/employees.model';
import { EmployeesGraduationLevels } from 'src/app/core/model/employeesGraduationLevels';
import { EmployeesWorkspaces } from 'src/app/core/model/employeesWorkspaces.model';
import { UploadAttachmentModel } from 'src/app/core/model/uploadAttachment.model';
import { EntityHasDocuments } from '../helpers/entityHasDocuments';
import { ApiService } from '../../api.service';
import { iResponseEmployeeAttachments } from '../../iResponse/iEmployeesResponse';
import { LengthAwarePaginator } from 'src/app/core/model/common/length-aware-paginator.model';

export class Employees extends EntityHasDocuments {
  documentPath = 'employees-documents';
  attachmentPath = 'employees-attachments';

  constructor(public api: ApiService) {
    super(api);
  }

  getUrl() {
    return `${this.api.getApiUrl()}/employees`;
  }

  create(entityInfo: any, files?: UploadAttachmentModel[]): Observable<any> {
    return this._create<any>(entityInfo, files);
  }

  update(entityInfo: any, files?: UploadAttachmentModel[]): Observable<any> {
    return this._update<any>(entityInfo, files);
  }

  getAll(params?: any): Observable<LengthAwarePaginator<EmployeesModel[]>> {
    return this.api.getHttp().get<LengthAwarePaginator<EmployeesModel[]>>(`${this.getUrl()}`, { params });
  }

  get(entityId: string) {
    return this._get<EmployeesModel>(entityId);
  }

  getAllByOccupation(occupationId: string) {
    let http = this.api.getHttp();
    return http.get<EmployeesModel[]>(`${this.api.getApiUrl()}/employees-by-occupation/${occupationId}`);
  }

  delete(entityId: string) {
    return this._delete<any>(entityId);
  }

  workspaces() {
    let http = this.api.getHttp();
    return http.get<EmployeesWorkspaces[]>(`${this.api.getApiUrl()}/employees-workspaces`);
  }

  graduationLevels() {
    let http = this.api.getHttp();
    return http.get<EmployeesGraduationLevels[]>(`${this.api.getApiUrl()}/employees-graduation-levels`);
  }

  attachments(employeeId: string) {
    let http = this.api.getHttp();
    return http.get<iResponseEmployeeAttachments>(`${this.api.getApiUrl()}/employees-attachments/${employeeId}`);
  }

  addAttachment(entityId: string, attachment?: UploadAttachmentModel[]) {
    return this._create(entityId, attachment, `${this.api.getApiUrl()}/${this.attachmentPath}/${entityId}`);
  }

  active(employeeId: string, updateInfo: any): Observable<any> {
    let customUrl = `${this.api.getApiUrl()}/employees-active/${employeeId}`;
    return this._update<any>(updateInfo, null, customUrl);
  }

  deleteDocument(employeeId: string, documentId: string) {
    return this.api.getHttp().delete(this.url(employeeId, 'documents', documentId));
  }
}
