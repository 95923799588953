import { Component, Input, signal } from '@angular/core';
import { DownloadService } from '@core/services/common/network/download.service';
import { AutoUnsubscribe } from '../../../helpers/auto-unsubscribe';
import { MaterialButtonColors } from '@core/types/material-button-colors';

@Component({
  selector: 'kt-button-print',
  templateUrl: './button-print.component.html',
})
export class ButtonPrintComponent extends AutoUnsubscribe {

  @Input() isPrinting = signal(false);
  @Input() link: string;

  @Input() loading = false;
  @Input() onlyIcon = false;
  @Input() disabled = false;
  @Input() color: MaterialButtonColors = 'primary';
  @Input() changeToOnHover = '';

  constructor(private downloadService: DownloadService) {
    super();
  }

  print() {
    this.isPrinting.set(true);
    this.sub = this.downloadService.printPdf(this.link)
      .subscribe({
        next: () => this.isPrinting.set(false),
        error: () => this.isPrinting.set(false),
      });
  }
}
