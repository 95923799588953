import { Observable } from 'rxjs';
import { ApiService } from '../../api.service';
import { iPermissionsResponse } from '../../iResponse/iPermissionsResponse';
import { Module } from '@model/tenant/role/module.model';

export class Permissions {
  constructor(public api: ApiService) {
  }

  getUrl() {
    return `${this.api.getApiUrl()}/permissions`;
  }

  all(): Observable<Module[]> {
    return this.api
      .getHttp()
      .get<Module[]>(this.getUrl());
  }
}
