import { ApiService } from '../../api.service';
import { BaseCrud } from '../helpers/baseCrud';

export class Recurrence extends BaseCrud {
  constructor(public apiService: ApiService) {
    super(apiService);
  }

  getUrl() {
    return `${this.apiService.getApiUrl()}/recurrences`;
  }

  update(recurrenceId: string, updateRecurrence: boolean, updateAccounts: boolean, recurrenceInfo) {
    return this.apiService.getHttp().put(`${this.getUrl()}/${recurrenceId}`, {
      updateRecurrence: updateRecurrence ? 1 : 0,
      updateAccounts: updateAccounts ? 1 : 0,
      recurrence: recurrenceInfo,
    });
  }

  createRecurrence(createRecurrenceInfo) {
    return this.apiService.getHttp().post(`${this.getUrl()}`, createRecurrenceInfo);
  }

  updateTypeRecurrece(recurrenceId: string, type: string, installments) {
    return this.apiService.getHttp().put(`${this.getUrl()}/${recurrenceId}/type-of-recurrence`, { type, installments });
  }

  delete(recurrence_id: string, delete_open: boolean, delete_pending: boolean) {
    return this.api.getHttp().delete(this.url(recurrence_id), {
      body: { delete_open, delete_pending },
    });
  }

}
