import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'kt-arrow-right-indicator',
  templateUrl: './arrow-right-indicator.component.html',
})
export class ArrowRightIndicatorComponent implements OnInit {
  @Input() text: string;

  constructor() {}

  ngOnInit(): void {}
}
