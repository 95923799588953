export const locals = {
  bold: 'Negrito',
  italic: 'Itálico',
  code: 'Código',
  underline: 'Sublinhado',
  strike: 'Riscado',
  blockquote: 'Citação',
  bullet_list: 'Lista de Marcadores',
  ordered_list: 'Lista Numerada',
  heading: 'Título',
  h1: 'Título 1',
  h2: 'Título 2',
  h3: 'Título 3',
  h4: 'Título 4',
  h5: 'Título 5',
  h6: 'Título 6',
  align_left: 'Alinhar à Esquerda',
  align_center: 'Alinhar ao Centro',
  align_right: 'Alinhar à Direita',
  align_justify: 'Justificar',
  text_color: 'Cor do Texto',
  background_color: 'Cor de Fundo',
  horizontal_rule: 'Linha Horizontal',
  format_clear: 'Limpar Formatação',
  insertLink: 'Inserir Link',
  removeLink: 'Remover Link',
  insertImage: 'Inserir Imagem',
  indent: 'Aumentar Recuo',
  outdent: 'Diminuir Recuo',
  superscript: 'Sobrescrito',
  subscript: 'Subscrito',
  undo: 'Desfazer',
  redo: 'Refazer',

  url: 'URL',
  text: 'Texto',
  openInNewTab: 'Abrir em nova aba',
  insert: 'Inserir',
  altText: 'Texto Alternativo',
  title: 'Título',
  remove: 'Remover',
  enterValidUrl: 'Por favor, insira um URL válido',
};
