import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'cardLetter',
})
export class CardLetterPipe implements PipeTransform {

  transform(value: string, args?: any): string {
    return value.split(' ').map((n) => n).join('').slice(0, 2);
  }
}
