import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'description'
})
export class DescriptionPipe implements PipeTransform {

  transform(value: string, maxLength: number = 100): unknown {
    if (!value) {
      return '';
    }

    const newValue = value.replace(/<[^>]*>/g, '');
    if (value.length < maxLength) {
      return newValue;
    }
    return `${newValue.slice(0, maxLength)}...`;
  }
}
