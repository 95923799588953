import { BaseCrud } from '@core/services/api/routes/helpers/baseCrud';
import { ApiService } from '@core/services/api';
import { GetCategoryResult } from '@model/tenant';
import { GetStatsCategory } from '@model/tenant/categories/stats-category.model';

export class SettingsRoute extends BaseCrud {
    constructor(public apiService: ApiService) {
        super(apiService);
    }

    getUrl() {
        return `${this.apiService.getApiUrl()}/stats/settings`;
    }

    categories() {
        return this.apiService.getHttp().get<GetStatsCategory[]>(this.url('categories'));
    }

    toggleStatusCategory(categoryId: string) {
        return this.apiService.getHttp().put(this.url('categories', categoryId, 'toggle-status'), {});
    }
}
