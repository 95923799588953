<div [formGroup]="form">
    <ng-template *ngIf="(selectList | async) then showSelectField else showLoading;"></ng-template>
    <ng-template #showLoading>
        <mat-spinner [diameter]="25"></mat-spinner>
    </ng-template>
    <ng-template #showSelectField>
        <mat-form-field class="mat-form-field-fluid">
            <mat-label>{{fieldDisplayName}}</mat-label>
            <input type="text" [placeholder]="fieldDisplayName" formControlName="selectEntity" matInput
                [matAutocomplete]="auto">
            <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete"
                [displayWith]="displayEntityName.bind(this)">
                <mat-option *ngFor="let entity of filteredEntities" [value]="entity.id">
                    {{displayNameFunction ? displayNameFunction(entity): entity.name}}
                </mat-option>
            </mat-autocomplete>
            <mat-error><strong>Obrigatório</strong> selecionar</mat-error>
        </mat-form-field>
    </ng-template>
</div>
