export enum CommonPerm {
  Logs_View = 'logs.view',
  Logs_Create = 'logs.create',
  Logs_Update = 'logs.update',
  Logs_Delete = 'logs.delete',

  Roles_View = 'roles.view',
  Roles_Create = 'roles.create',
  Roles_Update = 'roles.update',
  Roles_Delete = 'roles.delete',

  Users_View = 'users.view',
  Users_Create = 'users.create',
  Users_Update = 'users.update',
  Users_Delete = 'users.delete',
}
