import { TenantPerm } from '@enum/tenant/permission/tenant-perm.enum';

export const FinanceSection = [
  {
    section: 'Financeiro',
    permission: [
      '*',
      TenantPerm.Accounts_View,
      TenantPerm.Cashboxes_View,
      TenantPerm.Cards_View,
      TenantPerm.BankRecons_View, TenantPerm.CardRecons_View,
    ],
  },
  {
    title: 'Contas a pagar',
    permission: ['*', TenantPerm.Accounts_View],
    root: true,
    icon: 'fas fa-file-invoice',
    page: '/painel/financas/contas-a-pagar',
    bullet: 'dot',
  },
  {
    title: 'Contas a receber',
    permission: ['*', TenantPerm.Accounts_View],
    root: true,
    icon: 'fas fa-file-invoice-dollar',
    page: '/painel/financas/contas-a-receber',
    bullet: 'dot',
  },
  {
    title: 'Caixas',
    permission: ['*', TenantPerm.Cashboxes_View],
    root: true,
    icon: 'fas fa-bank',
    page: '/painel/financas/caixas/listar',
    bullet: 'dot',
  },
  {
    title: 'Cartões de crédito',
    permission: ['*', TenantPerm.Cards_View],
    root: true,
    icon: 'fas fa-credit-card',
    page: '/painel/financas/cartoes',
    bullet: 'dot',
  },
  {
    title: 'Conciliação',
    root: true,
    icon: 'fas fa-check-to-slot',
    bullet: 'dot',
    permission: ['*', TenantPerm.BankRecons_View, TenantPerm.CardRecons_View],
    submenu: [
      {
        title: 'Bancária',
        permission: ['*', TenantPerm.BankRecons_View],
        root: true,
        page: '/painel/financas/conciliacao/bancaria',
        bullet: 'dot',
      },
      {
        title: 'Cartão de crédito',
        permission: ['*', TenantPerm.CardRecons_View],
        root: true,
        page: '/painel/financas/conciliacao/cartao-de-credito',
        bullet: 'dot',
      },
    ],
  },
];
