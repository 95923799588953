import { ApiService } from "../../api.service";
import { iTimezone } from "../../iResponse/iTimezone";
import { BaseCrud } from "../helpers/baseCrud";

export class Timezone extends BaseCrud {
  constructor(public api: ApiService) {
    super(api);
  }

  getUrl() {
    return `${this.api.getApiUrl()}/timezone`;
  }

  get() {
    let http = this.api.getHttp();
    return http.get<iTimezone>(`${this.getUrl()}`);
  }
}
