import { Observable } from 'rxjs';
import { EmailTypeModel } from 'src/app/core/model/email-type.model';
import { BaseCrud } from '../helpers/baseCrud';
import { ApiService } from '../../api.service';

export class EmailType extends BaseCrud {
  constructor(public api: ApiService) {
    super(api);
  }

  getUrl() {
    return `${this.api.getApiUrl()}/email-types`;
  }

  getAll(params): Observable<EmailTypeModel[]> {
    return this.api.getHttp().get<EmailTypeModel[]>(this.getUrl(), { params });
  }

  create(entityInfo: any): Observable<EmailTypeModel> {
    return this._create<EmailTypeModel>(entityInfo);
  }

  update(entityInfo: any): Observable<EmailTypeModel> {
    return this._update<EmailTypeModel>(entityInfo);
  }

  get(entityId: string) {
    return this._get<EmailTypeModel>(entityId);
  }

  delete(entityId: string) {
    return this._delete<EmailTypeModel>(entityId);
  }
}
