import { Observable } from 'rxjs';
import { BudgetTypeModel } from 'src/app/core/model/budget.model';
import { BaseCrud } from '../helpers/baseCrud';
import { ApiService } from '../../api.service';

export class BudgetTypes extends BaseCrud {
  constructor(public api: ApiService) {
    super(api);
  }
  getUrl() {
    return `${this.api.getApiUrl()}/budgets/types`;
  }

  getAll() {
    return this._getAll<BudgetTypeModel[]>();
  }

  create(newBudgetType: any): Observable<any> {
    return this._create<any>(newBudgetType);
  }
}
