import { ApiService } from 'src/app/core/services/api/api.service';
import { BaseCrud } from 'src/app/core/services/api/routes/helpers/baseCrud';
import { ProfitPreviewByCategory } from '@model/tenant/dashboard/profit';
import { Observable } from 'rxjs';
import { PeriodicValue } from '@model/tenant/dashboard';

export class ProfitRoute extends BaseCrud {
  constructor(
    public apiService: ApiService,
  ) {
    super(apiService);
  }

  getUrl(): string {
    return `${this.apiService.getApiUrl()}/stats/profits`;
  }

  getMonthlyProfitSnapshot(params: any): Observable<PeriodicValue[]> {
    return this.apiService.getHttp().get<PeriodicValue[]>(this.url('monthly-snapshot'), { params });
  }

  getDailyProfitSnapshot(params: any): Observable<PeriodicValue[]> {
    return this.apiService.getHttp().get<PeriodicValue[]>(this.url('daily-snapshot'), { params });
  }

  getMonthProfitPreview(): Observable<PeriodicValue> {
    return this.apiService.getHttp().get<PeriodicValue>(this.url('month-preview'));
  }

  getCategoriesProfitPreview(params: any): Observable<ProfitPreviewByCategory[]> {
    return this.apiService.getHttp().get<ProfitPreviewByCategory[]>(this.url('preview-by-category'), { params });
  }

  getCategoriesProfitPreviewPercentage(): Observable<ProfitPreviewByCategory[]> {
    return this.apiService.getHttp().get<ProfitPreviewByCategory[]>(this.url('percentage-by-category'));
  }
}
