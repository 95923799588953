import { Component, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AutoUnsubscribe } from 'src/app/shared/helpers/auto-unsubscribe';
import { AdvancedFilterService } from '../../advanced-filter.service';
import { FilterField } from '../../core/filterField';
import { FilterSelectField } from '../../core/filterSelectField';
import { FilterFieldType } from '../../enums/filterFieldType';

@Component({
  selector: 'kt-filter-chips',
  templateUrl: './filter-chips.component.html',
  styleUrls: ['./filter-chips.component.scss']
})
export class FilterChipsComponent extends AutoUnsubscribe implements OnInit {

  fieldsModified = new BehaviorSubject<FilterField[]>([]);
  filterFieldType = FilterFieldType;

  constructor(
    public advancedFilterService: AdvancedFilterService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.sub = this.advancedFilterService.onApplyFilters
      .pipe(
        tap(() => this.filterFields())
      )
      .subscribe();
  }
  remove(field: FilterField){
    this.advancedFilterService.resetField(field);
  }
  filterFields() {
    this.fieldsModified.next(
      this.advancedFilterService.fields.filter(field => field.value && field.value !== field.startValue)
    );
  }
}
