<mat-chip-listbox>
  <mat-chip *ngFor="let field of fieldsModified | async" [removable]="true" (removed)="remove(field)">
    <ng-container [ngSwitch]="field.type">
      <small *ngSwitchCase="filterFieldType.BRL">{{ field.label }}: {{ field.value | currency : 'BRL' }}</small>
      <small *ngSwitchCase="filterFieldType.Select">
        {{ field.label }}:
        {{ $any(field).functionForDisplay ? $any(field).functionForDisplay(field.value) : field.value }}
      </small>
      <small *ngSwitchCase="filterFieldType.Date">{{ field.label }}: {{ field.value | date }}</small>
      <small *ngSwitchDefault
        >{{ field.label }} {{ field.type !== filterFieldType.Checkbox ? ':' + field.value : '' }}</small
      >
    </ng-container>
    <mat-icon matChipRemove>cancel</mat-icon>
  </mat-chip>
</mat-chip-listbox>
