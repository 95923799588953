import { GetCurrentSessionResult } from "src/app/core/model/common";
import { BaseApi } from "../../base-api";
import { Observable } from "rxjs";
import { UploadAttachmentModel } from "src/app/core/model/uploadAttachment.model";

export abstract class AbstractCurrentSessionRoute {
    constructor(
        public apiService: BaseApi
    ) {
    }

    getUrl(): string {
        return `${this.apiService.getApiUrl()}/current-session`;
    }

    user(): Observable<GetCurrentSessionResult> {
        return this.apiService.getHttp().get<GetCurrentSessionResult>(`${this.getUrl()}/user`);
    }

    permissions(): Observable<string[]> {
        return this.apiService.getHttp().get<string[]>(`${this.getUrl()}/user/permissions`);
    }

    verifyTwoFactor(secret) {
        return this.apiService.getHttp().post(`${this.getUrl()}/verify-two-factor`, { secret });
    }

    attachment() {
        return this.apiService.getHttp().get(`${this.getUrl()}/attachment`);
    }

    uploadUserPhotoAttachment(fileAttachment: UploadAttachmentModel) {
        let formData = new FormData();
        formData.append(fileAttachment.fileKey, fileAttachment.fileData.files[0], fileAttachment.fileData.files[0].name);
        return this.apiService.getHttp().post(`${this.getUrl()}/user-photo-attachment`, formData);
    }

    updateUserInfo(userInfo) {
        return this.apiService.getHttp().put(`${this.getUrl()}/user`, userInfo);
    }

    changeUserPassword(passwordsInfo) {
        return this.apiService.getHttp().put(`${this.getUrl()}/user/change-password`, passwordsInfo);
    }
}