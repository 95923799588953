import { MatDatepicker } from '@angular/material/datepicker';
import { AbstractControl, FormControl } from '@angular/forms';
import { AfterViewInit, Component, Input, ViewChild } from '@angular/core';

@Component({
  selector: 'kt-custom-date-picker',
  templateUrl: './custom-date-picker.component.html',
})
export class CustomDatePickerComponent implements AfterViewInit {

  @ViewChild('picker') datePicker: MatDatepicker<any>;

  @Input() public control: AbstractControl;
  @Input() public required = false;
  @Input() public placeholder: string;
  @Input() public error = null;
  @Input() public minDate: Date;
  @Input() public maxDate: Date;

  ngAfterViewInit(): void {
    const dataPicker = this.datePicker as any;

    const saveOldInput = dataPicker.datepickerInput._onInput.bind(dataPicker.datepickerInput);
    dataPicker.datepickerInput._onInput = (value) => {
      const split = value.split('/');

      saveOldInput(`${split[1]}/${split[0]}/${split[2] ? split[2].substring(0, 4) : ''}`);
    };

    const saveBlur = dataPicker.datepickerInput._onBlur.bind(dataPicker.datepickerInput);
    dataPicker.datepickerInput._onBlur = () => {
      const value = dataPicker.datepickerInput._elementRef.nativeElement.value;

      if (!value.length && !this.error) {
        this.control.patchValue('');
        return;
      }

      saveBlur();
    };
  }
}
