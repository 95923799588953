import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ApiService } from '../../api.service';
import { CheckSubdomainRes } from '@core/services/api/iResponse/check-domain';

export class TenantRoute {
  constructor(public apiService: ApiService) { }

  checkSubdomain(subdomain: string): Observable<CheckSubdomainRes> {
    return this.apiService.getHttp().post<CheckSubdomainRes>(`${environment.centralApiUrl}/api/v1/check/${subdomain}`, {});
  }
}
