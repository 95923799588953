import { Observable } from 'rxjs';
import { LengthAwarePaginator } from '@model/common/length-aware-paginator.model';
import { BaseCrud } from '@core/services/api/routes/helpers/baseCrud';
import id from '../../../../../../../assets/plugins/formvalidation/src/js/validators/id';


export class TemplateRoute extends BaseCrud {

  getUrl(): string {
    return `${this.api.getApiUrl()}/templates`;
  }

  list<T>(params?: any): Observable<LengthAwarePaginator<T[]>> {
    return this.api.getHttp().get<LengthAwarePaginator<T[]>>(this.getUrl(), { params });
  }

  get<T>(templateId: string): Observable<T> {
    return this.api.getHttp().get<T>(this.url(templateId));
  }

  create(data: any): Observable<any> {
    return this.api.getHttp().post<any>(this.getUrl(), data);
  }

  update(templateId: string, data: any): Observable<any> {
    return this.api.getHttp().put<any>(this.url(templateId), data);
  }

  delete(templateId: string): Observable<void> {
    return this.api.getHttp().delete<void>(this.url(templateId));
  }

  emailPreview(data: any) {
    return this.api.getHttp().post<any>(this.url('mail-preview'), data);
  }

  wppPreview(data: any) {
    return this.api.getHttp().post<any>(this.url('wpp-preview'), data);
  }
}
