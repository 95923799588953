import { NgModule } from "@angular/core";
import { BaseComponent } from "./base/base.component";
import { FooterComponent } from "./footer/footer.component";
import { HeaderComponent } from "./header/header.component";
import { BrandComponent } from "./brand/brand.component";
import { HeaderMobileComponent } from "./header/header-mobile/header-mobile.component";
import { SubheaderComponent } from "./subheader/subheader.component";
import { TopbarComponent } from "./header/topbar/topbar.component";
import { AsideLeftComponent } from "./aside/aside-left.component";
import { MenuHorizontalComponent } from "./header/menu-horizontal/menu-horizontal.component";
import { HtmlClassService } from "./html-class.service";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { NgxPermissionsModule } from "ngx-permissions";
import { PartialsModule } from "../partials/partials.module";
import { CoreModule } from "src/app/_metronic/core";
import { PerfectScrollbarModule } from "ngx-om-perfect-scrollbar";
import { FormsModule } from "@angular/forms";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatTabsModule } from "@angular/material/tabs";
import { MatButtonModule } from "@angular/material/button";
import { MatTooltipModule } from "@angular/material/tooltip";
import { TranslateModule } from "@ngx-translate/core";
import { LoadingBarModule } from "@ngx-loading-bar/core";
import { NgxDaterangepickerMd } from "ngx-daterangepicker-material";
import { InlineSVGModule } from "ng-inline-svg";
import { NgbProgressbarModule, NgbTooltipModule } from "@ng-bootstrap/ng-bootstrap";
import { SharedModule } from "src/app/shared/shared.module";
import { CoreModule as DirectiveCoreModule } from 'src/app/core/core.module';

@NgModule({
  declarations: [
    BaseComponent,
    FooterComponent,
    HeaderComponent,
    BrandComponent,
    HeaderMobileComponent,
    SubheaderComponent,
    TopbarComponent,
    AsideLeftComponent,
    MenuHorizontalComponent,
  ],
  exports: [
    BaseComponent,
    FooterComponent,
    HeaderComponent,
    BrandComponent,
    HeaderMobileComponent,
    SubheaderComponent,
    TopbarComponent,
    AsideLeftComponent,
    MenuHorizontalComponent,
  ],
  providers: [
    HtmlClassService,
  ],
  imports: [
    CommonModule,
    RouterModule,
    NgxPermissionsModule.forChild(),
    PartialsModule,
    CoreModule,
    DirectiveCoreModule,
    PerfectScrollbarModule,
    FormsModule,
    MatProgressBarModule,
    MatTabsModule,
    MatButtonModule,
    MatTooltipModule,
    TranslateModule.forChild(),
    LoadingBarModule,
    NgxDaterangepickerMd,
    InlineSVGModule,
    NgbProgressbarModule,
    NgbTooltipModule,
    SharedModule,
  ]
})
export class ThemeModule {
}
