import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, iif, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { catchError, map, mergeMap, timeout } from 'rxjs/operators';
import { TenancyService } from '../tenancy.service';

@Injectable({
  providedIn: 'root',
})
export class TenantGuard {
  constructor(
    private tenancyService: TenancyService,
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    const subdomain = this.tenancyService.makeSubdomain(window.location.hostname);
    if (!subdomain) {
      const appUrl = this.tenancyService.appUrl(subdomain);
      window.location.href = `${appUrl}admin`;
      return false;
    }

    return this.tenancyService.checkSubdomain(subdomain).pipe(
      mergeMap((_result) => iif(() => _result, of(true), of(false))),
      timeout(15_000),
      map((result) => {
        if (!result) {
          window.location.href = `${this.tenancyService.appUrl(subdomain)}inativo/t`;
        }
        return result;
      }),
      catchError(() => {
        window.location.href = `${this.tenancyService.appUrl(subdomain)}invalido/i`;
        return of(false);
      }),
    );
  }
}
