import { Component, Input } from '@angular/core';

@Component({
  selector: 'kt-portlet-body',
  templateUrl: './portlet-body.component.html',
})
export class PortletBodyComponent {
  @Input() noPadding = true;
  @Input() dialog = false;
  @Input() isLoading = false;
}
