import { EmployeeBenefitsModel } from 'src/app/core/model/employeeBenefits.model';
import { ApiService } from '../../api.service';

export class Benefits {
  constructor(public api: ApiService) { }

  getAll() {
    let http = this.api.getHttp();
    return http.get<EmployeeBenefitsModel[]>(`${this.api.getApiUrl()}/benefits`);
  }
}
