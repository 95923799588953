import { CreateCompany, UpdateCompany } from 'src/app/core/model/central';
import { Observable } from 'rxjs';
import { LengthAwarePaginator } from 'src/app/core/model/common/length-aware-paginator.model';
import { BaseCrud } from '@core/services/api/routes/helpers/baseCrud';
import { SubsidiaryModel } from '@model/tenant/subsidiary/subsidiary.model';
import { ApiService } from '@core/services/api';
import { UploadAttachmentModel } from '@model/uploadAttachment.model';
import { FiscalRpsLoteModel } from '@model/fiscalRpsLote.model';

export class FiscalRpsLoteRoute extends BaseCrud {
  constructor(public api: ApiService) {
    super(api);
  }

  getUrl(): string {
    return `${this.api.getApiUrl()}/fiscal-rps-lote`;
  }

  getAll(params?: any): Observable<LengthAwarePaginator<FiscalRpsLoteModel[]>> {
    return this.api.getHttp().get<LengthAwarePaginator<FiscalRpsLoteModel[]>>(this.getUrl(), { params });
  }

}
