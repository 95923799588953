<ng-container *ngIf='currentSession as _'>
  <div ngbDropdown placement='bottom-right' class='topbar-item'>

    <div ngbDropdownToggle class='topbar-item'>
      <!-- Desktop -->
      <div class='btn btn-icon h-100 w-auto d-none d-md-flex align-items-center btn-lg px-2'>
        <span class='text-muted font-weight-bold font-size-base d-none d-md-inline mr-1'>Olá, &nbsp;</span>
        <span class='text-dark-50 font-weight-bolder font-size-base d-none d-md-inline mr-3'>
          {{ _.name }}
        </span>
      </div>

      <!-- Mobile -->
      <button class='btn d-flex d-md-none btn-hover-text-primary p-0'>
        <span [inlineSVG]="'./assets/media/svg/icons/General/User.svg'" class='svg-icon svg-icon-xl'></span>
      </button>
    </div>


    <div ngbDropdownMenu class='dropdown-menu p-0 m-0 dropdown-menu-right dropdown-menu-anim-up dropdown-menu-md p-0'>
      <div class='d-flex flex-column align-items-center p-8 rounded-top'>
        <div class='text-dark m-0 flex-grow-1 mr-3 font-size-h5'>
          {{ _.name }}
        </div>
        <div class='text-muted mt-1'>{{ _.role }}</div>
      </div>
      <ul class='nav flex-column'>
        <li class='nav-item'>
          <button ngbDropdownItem (click)='profile()' class='nav-link'>
            <span class='nav-icon'><i class='flaticon2-calendar-3'></i></span>
            <span class='nav-text'>Meu Perfil</span>
          </button>
        </li>
        <li class='nav-item'>
          <button ngbDropdownItem (click)='changePassword()' class='nav-link'>
            <span class='nav-icon'><i class='flaticon2-refresh'></i></span>
            <span class='nav-text'>Alterar Senha</span>
          </button>
        </li>
        <li class='nav-item mt-15'>
          <button ngbDropdownItem (click)='logout()' class='nav-link'>
            <span class='nav-icon'><i class='flaticon-logout'></i></span>
            <span class='nav-text'>Sair</span>
          </button>
        </li>
      </ul>
    </div>
  </div>
</ng-container>
