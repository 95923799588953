<mat-card-header class="mb-5">
  <div class="d-flex flex-row justify-content-between align-items-center flex-wrap gap-2 w-100">
    <mat-card-title>
      <div [hidden]="noTitle">
        	<span #refIcon [hidden]="hideIcon || !icon">
        		<ng-content *ngIf="!icon" select="[ktPortletIcon]"></ng-content>
        		<i *ngIf="icon" [ngClass]="icon"></i>
        	</span>
        <ng-content *ngIf="!headTitle" select="[ktPortletTitle]"></ng-content>
        <h3 *ngIf="headTitle" class="m-0" [innerHTML]="headTitle"></h3>
      </div>
    </mat-card-title>
    <div #refTools [hidden]="hideTools" class="d-flex flex-wrap gap-3">
      <ng-content select="[ktPortletTools]"></ng-content>
    </div>
  </div>
</mat-card-header>

<mat-divider />
