<div [formGroup]="form" class="row mb-1">
    <div class="col-sm-4 kt-margin-bottom-20-mobile mb-4">
        <mat-form-field class="mat-form-field-fluid">
            <mat-label>{{entityTypeFieldName}}</mat-label>
            <mat-select formControlName="owner_type">
                <mat-option *ngFor="let entityOwner of entitieType" [value]="entityOwner">
                    {{entitieNameFunction(entityOwner)}}
                </mat-option>
            </mat-select>
            <mat-error><strong>Obrigatório</strong> selecionar.</mat-error>
        </mat-form-field>
    </div>
    <div class="col-sm-8 kt-margin-bottom-20-mobile mb-4">
        <ng-template *ngIf="isLoadingEntities | async; then showLoading; else showAutocomplete;"></ng-template>
        <ng-template #showLoading>
            <mat-spinner [diameter]="25"></mat-spinner>
        </ng-template>
        <ng-template #showAutocomplete>
            <p *ngIf="errorMessageOnInput" class="text-danger">{{errorMessageOnInput}}</p>
            <mat-form-field *ngIf="!errorMessageOnInput && form.get('owner_type').value" class="mat-form-field-fluid">
                <mat-label>{{selectedEnityName}}</mat-label>
                <input type="text" [placeholder]="selectedEnityName" formControlName="owner_id" matInput
                    [matAutocomplete]="auto">
                <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete"
                    [displayWith]="displayEntityName.bind(this)">
                    <mat-option *ngFor="let entity of filteredEntities" [value]="entity.id" [disabled]="!!entity.user?.id">
                        {{ entity.name }} <span *ngIf="entity.user">| {{entity.user?.id ? 'Já existe usuário associado' : 'Sem usuário associado'}}</span>
                    </mat-option>
                </mat-autocomplete>
                <mat-error><strong>Obrigatório</strong> selecionar</mat-error>
            </mat-form-field>
        </ng-template>
    </div>
</div>
