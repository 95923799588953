import { CitiesModel } from 'src/app/core/model/cities.model';
import { StatesModel } from 'src/app/core/model/states.model';
import { ApiService } from '../../api.service';
import { Country } from '@model/country.model';

export class CitiesAndStates {
  constructor(public api: ApiService) {
  }

  getCities() {
    let http = this.api.getHttp();
    return http.get<CitiesModel[]>(`${this.api.getApiUrl()}/cities`);
  }

  getStates() {
    let http = this.api.getHttp();
    return http.get<StatesModel[]>(`${this.api.getApiUrl()}/states`);
  }

  getCountries() {
    return this.api
      .getHttp()
      .get<Country[]>(`${this.api.getApiUrl()}/countries`);
  }
}
