import { TenantPerm } from '@enum/tenant/permission/tenant-perm.enum';

export const AccountSection = [
  {
    section: 'Contabilidade',
    permission: [
      '*',
      TenantPerm.AccountingDocuments_View,
      TenantPerm.FiscalRps_View,
      TenantPerm.AccountingSheets_View,
      TenantPerm.AccountingReports_View,
    ],
  },
  {
    title: 'Documentos',
    permission: ['*', TenantPerm.AccountingDocuments_View],
    root: true,
    icon: 'fas fa-file',
    page: '/painel/financas/documentos',
    bullet: 'dot',
  },
  {
    title: 'Notas fiscais',
    permission: ['*', TenantPerm.FiscalRps_View],
    root: true,
    icon: 'fas fa-receipt',
    page: '/painel/fiscal/notas',
    bullet: 'dot',
  },
  {
    title: 'Planilha contábil',
    permission: ['*', TenantPerm.AccountingSheets_View],
    root: true,
    icon: 'fas fa-file-excel',
    page: '/painel/financas/planilha-contabil',
    bullet: 'dot',
  },
  {
    title: 'Relatórios',
    permission: ['*', TenantPerm.AccountingReports_View],
    root: true,
    icon: 'fas fa-chart-line',
    page: '/painel/financas/relatorios',
    bullet: 'dot',
  },
];
