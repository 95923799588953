import { AdministrativeSection } from '@core/_config/menu/tenant/administrative.section';
import { FinanceSection } from '@core/_config/menu/tenant/finance.section';
import { AccountSection } from '@core/_config/menu/tenant/accounting.section';
import { ConfigurationSection } from '@core/_config/menu/tenant/configuration.section';
import { CommonPerm } from '@enum/common/permission/common-perm.enum';

export const TenantMenu = [
  {
    title: 'Início',
    root: true,
    icon: 'fas fa-home',
    page: '/painel/dashboard',
    translate: 'MENU.DASHBOARD',
    bullet: 'dot',
  },

  ...AdministrativeSection,
  ...FinanceSection,
  ...AccountSection,
  ...ConfigurationSection,

  {
    section: 'Histórico',
    permission: ['*', CommonPerm.Logs_View],
  },
  {
    title: 'Ações',
    permission: ['*', CommonPerm.Logs_View],
    root: true,
    icon: 'fas fa-book',
    bullet: 'dot',
    page: '/painel/historicos/acoes',
  },
];
