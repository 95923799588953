import { BaseCrud } from '../../helpers/baseCrud';
import { ApiService } from '@core/services/api';
import { CardMatchDto } from './card-match-dto';
import { CardRecon } from '@model/tenant/conciliation/card-recon';
import { ConfirmDto } from 'src/app/views/pages/tenant/finances/pages/recon/pages/card/model/confirm-dto';

export class CardConciliationRoute extends BaseCrud {
  constructor(public api: ApiService) {
    super(api);
  }

  getUrl() {
    return `${this.api.getApiUrl()}/recons/card`;
  }

  list(params?: any) {
    return this.api.getHttp().get<CardRecon[]>(this.url(), { params });
  }

  get(id: string) {
    return this.api.getHttp().get<CardRecon>(this.url(id));
  }

  create(matchKey: string) {
    return this._create<CardRecon>({
      match_key: matchKey,
    });
  }

  conciliate<T>(reconId: string, value: number, comments?: string) {
    return this._create<T>({
      value,
      comments,
    }, [], this.url(reconId, 'conciliate'));
  }
}
