<!--begin::Subheader-->
<div class="subheader py-2 py-lg-4" [ngClass]="subheaderClasses" id="kt_subheader">
	<div [ngClass]="subheaderContainerClasses" class="d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
    <!--begin::Info-->
    <div class="d-flex align-items-center flex-wrap mr-1">
      <!--begin::Page Heading-->
      <div class="d-flex align-items-baseline mr-5">
        <ng-container *ngIf="title">
          <!--begin::Page Title-->
          <h5 class="text-dark font-weight-bold my-2 mr-5">
            {{title}}
          </h5>
        </ng-container>
        <!--end::Page Title-->

        <!--begin::Breadcrumb-->
        <ng-container *ngIf="breadcrumbs.length > 0">
          <ul class="breadcrumb breadcrumb-transparent breadcrumb-dot font-weight-bold p-0 my-2">
            <li class="breadcrumb-item"><a href="#"><i class="flaticon2-shelter text-muted icon-1x"></i></a></li>
            <ng-container *ngFor="let item of breadcrumbs">
              <li class="breadcrumb-item">
                <a [routerLink]="item.page" [queryParams]="item.queryParams" class="text-muted">
                  {{item.title}}
                </a>
              </li>
            </ng-container>
          </ul>
        </ng-container>
        <!--end::Breadcrumb-->
      </div>
      <!--end::Page Heading-->
    </div>
    <!--end::Info-->

	</div>
</div>
<!--end::Subheader-->
