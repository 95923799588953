import { Component, Input, signal } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AutoUnsubscribe } from 'src/app/shared/helpers/auto-unsubscribe';
import { DownloadService } from '../../../../core/services/common/network/download.service';
import { MaterialButtonColors } from '@core/types/material-button-colors';
import { i, OnPush } from '@core/macros/angular';
import { NotificationService } from '@core/services/notification.service';

@Component({
  selector: 'kt-button-download',
  templateUrl: './button-download.component.html',
  ...OnPush,
})
export class ButtonDownloadComponent extends AutoUnsubscribe {

  @Input() isDownloading = signal(false);
  @Input() filename: string;
  @Input() link: string;
  @Input() loading = false;
  @Input() onlyOpenNewTab = false;
  @Input() disabled = false;
  @Input() disabledMessage: string = 'Download está desabilitado';

  @Input() onlyIcon = false;
  @Input() textOnHove = false;
  @Input() text = 'Download';
  @Input() icon = 'fa-download';
  @Input() color: MaterialButtonColors = 'primary';
  @Input() colorDisabled: string = 'rgba(0, 0, 0, 0.26)';

  private notification = i(NotificationService);

  constructor(private download: DownloadService) {
    super();
  }

  onDownload()
  {
    if (this.disabled)
    {
      this.notification.error(this.disabledMessage);
      return;
    }

    if (this.onlyOpenNewTab) {
      window.open(this.link, '_blank');
      return;
    }
    this.isDownloading.set(true);
    this.sub = this.download.file(this.link, this.filename)
      .subscribe({
        next: () => this.isDownloading.set(false),
        error: () => this.isDownloading.set(false),
      });
  }

  getStyles(): object
  {
    const visibility = this.isDownloading() || this.loading ? 'hidden' : 'visible';

    if (this.disabled)
    {
      return {
        'visibility': visibility,
        'color': this.colorDisabled,
      };
    }

    return { 'visibility': visibility };
  }

  colorBtn(): string
  {
    return this.disabled ? this.colorDisabled : this.color;
  }
}
