import { Component, Input } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'kt-button-edit',
  templateUrl: './button-edit.component.html',
})
export class ButtonEditComponent {
  @Input() loading = new BehaviorSubject(false);
  @Input() isLoading = false;
  @Input() disabled = new BehaviorSubject(false);
  @Input() iconOnly = true;

}
