import { CommonModule, registerLocaleData } from '@angular/common';
// Angular
import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, DEFAULT_CURRENCY_CODE, Injector, LOCALE_ID, NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarConfigInterface } from 'ngx-om-perfect-scrollbar';
import { InlineSVGModule } from 'ng-inline-svg';
import { NgxPermissionsModule } from 'ngx-permissions';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { CoreModule } from '@core/core.module';
import { ThemeModule } from './views/theme/theme.module';
import { PartialsModule } from './views/partials/partials.module';
import {
  DataTableService,
  KtDialogService,
  LayoutConfigService,
  LayoutRefService,
  MenuAsideService,
  MenuConfigService,
  MenuHorizontalService,
  PageConfigService,
  SplashScreenService,
  SubheaderService,
} from '@core/_base/layout';
import { HttpUtilsService, LayoutUtilsService, TypesUtilsService } from '@core/_base/crud';
import { LayoutConfig } from '@core/_config/layout.config';
import { HIGHLIGHT_OPTIONS, HighlightModule } from 'ngx-highlightjs';
import xml from 'highlight.js/lib/languages/xml';
import json from 'highlight.js/lib/languages/json';
import scss from 'highlight.js/lib/languages/scss';
import typescript from 'highlight.js/lib/languages/typescript';
import { ApiInterceptor } from '@core/services/api/interceptor';
import { ApiService } from '@core/services/api';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';
import localeBr from '@angular/common/locales/pt';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { OverlayModule } from '@angular/cdk/overlay';
import { AuthService } from '@core/services/common';
import { Observable, tap } from 'rxjs';
import { CURRENCY_MASK_CONFIG, CurrencyMaskConfig } from 'ng2-currency-mask';
import { EmptyComponentComponent } from './empty-component/empty-component.component';
import moment from 'moment';
import 'moment/locale/pt-br';
import { MAT_FAB_DEFAULT_OPTIONS, MatFabDefaultOptions } from '@angular/material/button';
import { NotificationService } from '@core/services/notification.service';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { RetryInterceptor } from '@core/services/api/retry-interceptor';
import { NgxEditorModule } from 'ngx-editor';
import { locals } from '@configuration/charge-workflow/pages/templates/models/locals';
import 'hammerjs';

registerLocaleData(localeBr, 'pt');
// tslint:disable-next-line:class-name
const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  wheelSpeed: 0.5,
  swipeEasing: true,
  minScrollbarLength: 40,
  maxScrollbarLength: 300,
};

const DEFAULT_CURRENCY_MASK_CONFIG: CurrencyMaskConfig = {
  prefix: 'R$',
  thousands: '.',
  decimal: ',',
  align: 'left',
  allowNegative: true,
  precision: 2,
  suffix: '',
};

export function appInitializer(authService: AuthService, appConfig: LayoutConfigService): () => Observable<any> {
  return () =>
    authService.getUserByToken().pipe(
      tap(r => {
        !appConfig.getConfig() && appConfig.loadConfigs(new LayoutConfig().configs);
      }),
    );
}

/**
 * Import specific languages to avoid importing everything
 * The following will lazy load highlight.js core script (~9.6KB) + the selected languages bundle (each lang. ~1kb)
 */
export function getHighlightLanguages() {
  return [
    { name: 'typescript', func: typescript },
    { name: 'scss', func: scss },
    { name: 'xml', func: xml },
    { name: 'json', func: json },
  ];
}

@NgModule({
  declarations: [
    AppComponent,
    EmptyComponentComponent,
    // ChangePasswordDialogComponent
  ],
  imports: [
    BrowserAnimationsModule,
    CommonModule,
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    HighlightModule,
    CoreModule,
    PartialsModule,
    OverlayModule,
    TranslateModule.forRoot(),
    InlineSVGModule.forRoot(),
    ThemeModule,
    NgbModule,
    NgxPermissionsModule.forRoot(),
    NgxEditorModule.forRoot({ locals }),
  ],
  providers: [
    AuthService,
    { provide: LOCALE_ID, useValue: 'pt' },
    { provide: DEFAULT_CURRENCY_CODE, useValue: 'BRL' },
    LayoutConfigService,
    LayoutRefService,
    MenuConfigService,
    PageConfigService,
    KtDialogService,
    DataTableService,
    SplashScreenService,
    {
      provide: MAT_FAB_DEFAULT_OPTIONS,
      useValue: {
        color: 'primary',
      } as MatFabDefaultOptions,
    },
    {
      provide: CURRENCY_MASK_CONFIG,
      useValue: DEFAULT_CURRENCY_MASK_CONFIG,
    },
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializer,
      deps: [AuthService, LayoutConfigService],
      multi: true,
    },
    {
      provide: HIGHLIGHT_OPTIONS,
      useValue: {
        languages: getHighlightLanguages,
      },
    },
    // template services
    SubheaderService,
    MenuHorizontalService,
    MenuAsideService,
    HttpUtilsService,
    TypesUtilsService,
    LayoutUtilsService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RetryInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiInterceptor,
      deps: [ApiService, NotificationService],
      multi: true,
    },
    {
      provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
      useValue: {
        duration: 5000,
        horizontalPosition: 'end',
        verticalPosition: 'top',
        panelClass: ['snack'],
      },
    },
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'fill' } },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  static injector: Injector;

  constructor(injector: Injector) {
    AppModule.injector = injector;

    moment().locale('pt-br');
  }
}
