import { LogAction as CentralLogAction } from 'src/app/core/enums/central/logs.enum';
import { LogAction as TenantLogAction } from 'src/app/core/enums/tenant/logs.enum';
import { UserType } from '../../../../views/pages/tenant/histories/enum/user-type';

export type LogAction = CentralLogAction | TenantLogAction;

export class GetLogResult {
  id: string;
  action: LogAction;
  description: string;
  payload: object;
  ip: string;

  user: GetLogUserResult;
  created_at: Date;

  constructor(data: GetLogResult) {
    Object.assign(this, data);
  }
}

export class GetLogUserResult {
  id: string;
  name: string;
  type: UserType;

  constructor(data: GetLogUserResult) {
    Object.assign(this, data);
  }
}
