import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { TenancyService } from '../tenancy.service';

@Injectable({
  providedIn: 'root'
})
export class NotTenantGuard {
  constructor(
    private tenancyService: TenancyService,
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    const subdomain = this.tenancyService.makeSubdomain(window.location.hostname);
    const appUrl = this.tenancyService.appUrl(subdomain);
    if (!subdomain) {
      return true;
    }

    window.location.href = `${appUrl}/painel`;
    return false;
  }
}
