import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'plural',
})
export class PluralPipe implements PipeTransform {
  transform(amount: number, singularText: string, pluralText: string = null): string {
    const pluralWord = pluralText ? pluralText : `${singularText}s`;
    return amount > 1 ? `${amount} ${pluralWord}` : `${amount} ${singularText}`;
  }
}
