import { HttpErrorResponse, HttpEvent, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable, retry, tap, throwError, timer } from 'rxjs';
import { Injectable } from '@angular/core';
import { AppModule } from '../../../app.module';
import { NotificationService } from '@core/services/notification.service';
import { NotificationType } from '@enum/common/notification-type.enum';

@Injectable()
export class RetryInterceptor {

  private readonly MAX_NUMBER_OF_RETRY_NO_CONNECTION: number = 5 + 1;
  private readonly RETRY_DELAY: number = 500;

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      retry({
        count: this.MAX_NUMBER_OF_RETRY_NO_CONNECTION,
        delay: (error: HttpErrorResponse, retryAttempt: number): Observable<number> => {

          if (error.status !== 0) {
            return throwError(() => error);
          }

          if (retryAttempt >= this.MAX_NUMBER_OF_RETRY_NO_CONNECTION) {
            const notification = AppModule.injector.get(NotificationService);
            notification.open('Problemas de conexão. Verifique sua internet, por favor.', NotificationType.DANGER);
            return throwError(() => error);
          }

          console.log(`Attempt ${retryAttempt}: retrying in ${retryAttempt * this.RETRY_DELAY}ms`);
          // retry after 1s, 2s, etc...
          return timer(retryAttempt * this.RETRY_DELAY);
        },
      }),
    );
  }
}
