import { HttpErrorResponse } from '@angular/common/http';

export default class ErrorHandler {

  public static THERE_WAS_AN_UNEXPECTED_ERROR = 'Erro inesperado, tente novamente mais tarde.';
  public static YOU_ARE_NOT_AUTHORIZED_ACCESS_THIS_PAGE = 'Você não está autorizado a acessar esta página.';
  public static YOU_DO_NOT_HAVE_PERMISSION_ACCESS_THIS_PAGE = 'Você não tem permissão para acessar esta página.';

  private static DATA_INVALID = 'The given data was invalid.';

  static getErrors(error: HttpErrorResponse): string[] {
    const data = error.error;
    // console.log(error);
    switch (error.status) {
      case 422: {
        if (data && data?.message === this.DATA_INVALID) {
          return ErrorHandler.parseErrors(data.errors);
        }

        if (data && data.errors) {
          return ErrorHandler.parseErrors(data.errors);
        }

        return [this.THERE_WAS_AN_UNEXPECTED_ERROR];
      }
      case 400: {
        if (data && data.success === undefined || data && !data.success) {
          return [data.message];
        }

        return [this.THERE_WAS_AN_UNEXPECTED_ERROR];
      }
      case 401: {
        return [this.YOU_ARE_NOT_AUTHORIZED_ACCESS_THIS_PAGE];
      }
      case 403: {
        return [this.YOU_DO_NOT_HAVE_PERMISSION_ACCESS_THIS_PAGE];
      }
      default: {
        return [data?.message];
      }
    }
  }

  static mapResponse(data: any[]): ErrorModel[] {
    let result: ErrorModel[] = [];
    result = Object.keys(data).map(key => {
      const messages = data[key];
      return {
        input: key,
        message: messages,
      } as ErrorModel;
    });
    return result;
  }

  private static parseErrors(errors: unknown[]) {
    return Object.keys(errors).map(key => errors[key]);
  }
}

export class ErrorModel {
  input: string;
  message: string[];

  constructor(data: ErrorModel) {
    Object.assign(this, data);
  }
}
