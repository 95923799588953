import { Observable } from 'rxjs';
import { ProvidersModel } from 'src/app/core/model/providers.model';
import { EntityHasDocuments } from '../helpers/entityHasDocuments';
import { ApiService } from '../../api.service';
import { LengthAwarePaginator } from 'src/app/core/model/common/length-aware-paginator.model';

export class Providers extends EntityHasDocuments {
  documentPath = 'providers-documents';

  constructor(public api: ApiService) {
    super(api);
  }

  getUrl() {
    return `${this.api.getApiUrl()}/providers`;
  }

  create(providerInfo): Observable<any> {
    return this._create<any>(providerInfo);
  }

  update(providerInfo): Observable<any> {
    return this._update<any>(providerInfo);
  }

  getAll(params?: any): Observable<LengthAwarePaginator<ProvidersModel[]>> {
    return this.api.getHttp().get<LengthAwarePaginator<ProvidersModel[]>>(`${this.getUrl()}`, { params });
  }

  get(providerId: string) {
    return this._get<ProvidersModel>(providerId);
  }

  delete(providerId: string) {
    return this._delete<any>(providerId);
  }

  setInativeOrActive(providerId: string): Observable<any> {
    const http = this.api.getHttp();
    return http.put(`${this.getUrl()}/set-inactive-or-active/${providerId}`, {});
  }

  deleteDocument(providerId: string, documentId: string) {
    return this.api.getHttp().delete(this.url(providerId, 'documents', documentId));
  }
}
