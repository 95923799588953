import { Observable } from 'rxjs';
import { AccountPayableModel } from 'src/app/core/model/accountsPayable/accountPayable.model';
import { PaymentModel } from 'src/app/core/model/payment.model';
import { UploadAttachmentModel } from 'src/app/core/model/uploadAttachment.model';
import { ApiService } from '../../api.service';
import { iAccountStandardLaunchResponse } from '../../iResponse/iAccountStandardLaunchResponse';
import { BaseCrud } from '../helpers/baseCrud';
import { GetAccountStandardLaunchResult } from '@model/tenant/account-standard-launch';

export class AccountsPayable extends BaseCrud {
  constructor(public apiService: ApiService) {
    super(apiService);
  }

  getUrl() {
    return `${this.apiService.getApiUrl()}/accounts-payable`;
  }

  create(entityInfo: any, files?: UploadAttachmentModel[]): Observable<any> {
    return this._create<any>(entityInfo, files);
  }

  update(entityInfo: any, files?: UploadAttachmentModel[]): Observable<any> {
    return this._update<any>(entityInfo, files);
  }

  getAll(params?: any) {
    return this.apiService.getHttp().get<AccountPayableModel[]>(this.getUrl(), { params });
  }

  get(entityId: string) {
    return this._get<AccountPayableModel>(entityId);
  }

  receiptUrl(entityId: string) {
    return this.url(entityId, 'receipt');
  }

  invoiceUrl(entityId: string) {
    return this.url(entityId, 'invoice');
  }

  delete(entityId: string) {
    return this._delete<AccountPayableModel>(entityId);
  }

  getUrlGenerateSpreadSheet(startDate, endDate) {
    return `${this.getUrl()}/generate/spread-sheet?startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}`;
  }

  generateSpreadSheet(startDate, endDate) {
    return this._get<{ id: string; name: string }[]>(
      undefined,
      undefined,
      this.getUrlGenerateSpreadSheet(startDate, endDate),
    );
  }

  getUrlGenerateDocumentLote(startDate, endDate) {
    return `${this.getUrl()}/generate/documents-lote?startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}`;
  }

  generateGenerateDocumentLote(startDate, endDate) {
    return this._get<{ id: string; name: string }[]>(
      undefined,
      undefined,
      this.getUrlGenerateDocumentLote(startDate, endDate),
    );
  }

  getDevelopers() {
    return this._get<{ id: string; name: string }[]>(undefined, undefined, `${this.getUrl()}/developers`);
  }

  getInterns() {
    return this._get<{ id: string; name: string }[]>(undefined, undefined, `${this.getUrl()}/interns`);
  }

  getEmployees() {
    return this._get<{ id: string; name: string }[]>(undefined, undefined, `${this.getUrl()}/employees`);
  }

  getProviders() {
    return this._get<{ id: string; name: string }[]>(undefined, undefined, `${this.getUrl()}/providers`);
  }

  getAccountStandardLaunch(): Observable<GetAccountStandardLaunchResult[]> {
    return this.apiService.getHttp().get<GetAccountStandardLaunchResult[]>(`${this.getUrl()}/accounts/standard-launch`);
  }

  massDelete(ids: string[]): Observable<iAccountStandardLaunchResponse[]> {
    return this.apiService
      .getHttp()
      .delete<iAccountStandardLaunchResponse[]>(`${this.getUrl()}/mass-delete`, { body: { ids } });
  }

  //#region Invoices
  private getAccountPayablePaymentUrl(accountPayableId: string) {
    return `${this.getUrl()}/${accountPayableId}/payments`;
  }

  payments(accountPayableId: string) {
    return this._getAll<PaymentModel[]>(undefined, `${this.getAccountPayablePaymentUrl(accountPayableId)}`);
  }

  pay(
    accountPayableId: string,
    paymentInfo: PaymentModel,
    receipt: UploadAttachmentModel,
    invoice?: UploadAttachmentModel,
  ) {
    const files = [];
    if (receipt) {
      files.push(receipt);
    }
    if (invoice) {
      files.push(invoice);
    }
    return this._create<AccountPayableModel[]>(
      paymentInfo,
      files,
      `${this.getAccountPayablePaymentUrl(accountPayableId)}`,
    );
  }

  deletePayment(accountPayableId: string, paymentId: string) {
    return this._delete<PaymentModel[]>(
      undefined,
      `${this.getAccountPayablePaymentUrl(accountPayableId)}/${paymentId}`,
    );
  }

  //#endregion

  /**
   * Cancel an account.
   *
   * @param accountPayableId - The account id.
   * @param cancelRecurrent - If the account is recurrent, cancel all recurrent accounts.
   */
  cancel(accountPayableId: string, cancelRecurrent: boolean) {
    return this._get<AccountPayableModel[]>(
      undefined,
      `cancel_recurrent=${cancelRecurrent}`,
      `${this.getUrl()}/${accountPayableId}/cancel`,
    );
  }
}
