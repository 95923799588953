import { Observable } from 'rxjs';
import { BudgetModel, Comment } from 'src/app/core/model/budget.model';
import { UploadAttachmentModel } from 'src/app/core/model/uploadAttachment.model';
import { BaseCrud } from '../helpers/baseCrud';
import { ApiService } from '../../api.service';

export class Budgets extends BaseCrud {
  constructor(public api: ApiService) {
    super(api);
  }

  getUrl() {
    return `${this.api.getApiUrl()}/budgets`;
  }

  create(newBudget: BudgetModel): Observable<any> {
    return this._create<BudgetModel>(newBudget);
  }

  update(budget: BudgetModel): Observable<any> {
    return this._update<BudgetModel>(budget);
  }

  get(entityId: string) {
    return this._get<BudgetModel>(entityId);
  }

  createComment(budget: BudgetModel, newComment: Comment, files?: UploadAttachmentModel[]) {
    let customUrl = `${this.getUrl()}/${budget.id}/comment`;
    return this._create<BudgetModel>(budget, files, customUrl);
  }
}
