<!--<a *ngIf="isActive" (click)="clickOnButton()" title="Inativar" class="btn btn-icon btn-light btn-hover-default btn-sm">-->
<!--  <i class="fas fa-lock fas-primary"></i>-->
<!--</a>-->
<button mat-icon-button color='primary' *ngIf="isActive" (click)="clickOnButton()">
  <i class="fas fa-lock"></i>
</button>
<button mat-icon-button color='primary' *ngIf="!isActive" (click)="clickOnButton()">
  <i class="fas fa-lock-open"></i>
</button>
<!--<a *ngIf="!isActive" (click)="clickOnButton()" title="Ativar" class="btn btn-icon btn-light btn-hover-default btn-sm">-->
<!--  <i class="fas fa-lock-open fas-primary"></i>-->
<!--</a>-->
