import { BaseCrud } from '../../helpers/baseCrud';
import { ApiService } from '../../../api.service';
import { BankRecon } from '@model/tenant/conciliation/bank-recon';
import { UploadAttachmentModel } from '@model/uploadAttachment.model';
import { Ofx } from 'src/app/views/pages/tenant/finances/pages/recon/pages/bank/models/ofx/ofx';
import { OfxGroupsRoute } from '@core/services/api/routes/tenancy/conciliation/bank/ofx-groups.route';

export class BankConciliationRoute extends BaseCrud {

  public ofxGroups: OfxGroupsRoute;

  constructor(public api: ApiService) {
    super(api);

    this.ofxGroups = new OfxGroupsRoute(this);
  }

  getUrl() {
    return `${this.api.getApiUrl()}/recons/bank`;
  }

  list(params?: any) {
    return this.api.getHttp().get<BankRecon[]>(this.url(), { params });
  }

  get(id: string) {
    return this.api.getHttp().get<BankRecon>(this.url(id));
  }

  listOfx(id: string) {
    return this.api.getHttp().get<Ofx[]>(this.url(id, 'ofx'));
  }

  create(matchKey: string) {
    return this._create<BankRecon>({
      match_key: matchKey,
    });
  }

  store<T>(reconId: string, ofxFile: UploadAttachmentModel) {
    return this._create<T>({}, [ ofxFile ], this.url(reconId, 'ofx'));
  }

  link<T>(reconId: string, ofxId: string, ofxLinkId: string, ofxLinkType: string, paymentLinkId: string, paymentLinkType: string, justification: string) {
    return this.api.getHttp().post<T>(this.url(reconId, 'ofx', ofxId, 'links'), {
      ofx_link: {
        type: ofxLinkType,
        id: ofxLinkId,
      },
      payment_link: {
        type: paymentLinkType,
        id: paymentLinkId,
      },
      justification,
    });
  }

  multiLink<T>(reconId: string, ofxId: string, data: any[]) {
    return this.api.getHttp().post<T>(this.url(reconId, 'ofx', ofxId, 'links'), {
      multiple: true,
      data,
    });
  }

  unlink<T>(reconId: string, ofxId: string, linkId: string) {
    return this.api.getHttp().delete<T>(this.url(reconId, 'ofx', ofxId, 'links', linkId));
  }

  matchSuggestion<T>(reconId: string, ofxId: string) {
    return this.api.getHttp().get<T>(this.url(reconId, 'ofx', ofxId, 'match-suggestion'));
  }

  deleteOfx<T>(reconId: string, ofxId: string) {
    return this.api.getHttp().delete<T>(this.url(reconId, 'ofx', ofxId));
  }

  getOfx<T>(reconId: string, ofxId: string) {
    return this.api.getHttp().get<T>(this.url(reconId, 'ofx', ofxId));
  }

  viewRecon<T>(reconId: string) {
    return this.api.getHttp().get<T>(this.url(reconId, 'transactions'));
  }

  matched<T>(id: string) {
    return this.api.getHttp().get<T>(this.url(id, 'matched'));
  }

  diff<T>(id: string) {
    return this.api.getHttp().get<T>(this.url(id, 'diff'));
  }

  cancel(id: string, comments: string) {
    return this.api.getHttp().post<BankRecon>(this.url(id, 'cancel'), {
      comments,
    });
  }

  finish(id: string, comments: string) {
    return this.api.getHttp().post<BankRecon>(this.url(id, 'finish'), {
      comments,
    });
  }
}
