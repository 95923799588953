import { BaseCrud } from 'src/app/core/services/api/routes/helpers/baseCrud';
import { CardRoute } from '@core/services/api/routes/tenancy';
import { CardBill } from '@model/card/card-bill';
import { Bills, BillStat } from '@core/services/api/interfaces/card/card-bills';

export class CardBillStatRoute extends BaseCrud {
  constructor(public base: CardRoute) {
    super(base.api);
  }

  get http() {
    return this.base.api.getHttp();
  }

  getUrl() {
    return this.base.getUrl();
  }

  list(cardId: string, year?: number) {
    const query = !!year ? this.toQuery({ year }) : '';

    return this.http.get<CardBill[]>(
      this.url(cardId, 'bills' + query),
    );
  }

  stats(cardId: string, year?: number) {
    const query = !!year ? this.toQuery({ year }) : '';

    return this.http.get<Bills>(
      this.url(cardId, 'bills', 'stats' + query),
    );
  }

  show(cardId: string, billId: string) {
    return this.http.get<CardBill>(this.url(cardId, 'bills', billId));
  }

  movePayment(cardId: string, billId: string, data: any) {
    return this.http.post<CardBill>(this.url(cardId, 'bills', billId), data);
  }
}
