import { Component, Input, OnInit } from '@angular/core';
import { AuthService, TenancyService } from 'src/app/core/services/common';
import { GetCurrentSessionResult } from 'src/app/core/model/common';
import { Router } from '@angular/router';

@Component({
  selector: 'kt-user-profile',
  styleUrls: ['./user-profile.component.scss'],
  templateUrl: './user-profile.component.html',
})
export class UserProfileComponent implements OnInit {
  currentSession: GetCurrentSessionResult;

  @Input() avatar = true;
  @Input() greeting = true;
  @Input() badge: boolean;
  @Input() icon: boolean;

  constructor(
    private authService: AuthService,
    private tenancy: TenancyService,
    private router: Router,
  ) {
  }

  ngOnInit(): void {
    this.authService.currentUserObserver.subscribe((_) => this.currentSession = _);
  }

  logout(): void {
    this.authService.onLogoutHandler().subscribe(() => window.location.reload());
  }

  profile(): void {
    void this.router.navigateByUrl(this.tenancy.basePath + '/perfil');
  }

  changePassword(): void {
    void this.router.navigateByUrl(this.tenancy.basePath + '/perfil/mudar-senha');
  }
}
