import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { Subscription } from "rxjs";
import { AlertControl } from "src/app/helpers/alertControl";

@Component({ template: "" })
export abstract class BaseComponent implements OnInit {

    private _subscriptions: Subscription[] = [];
    public alertControl = new AlertControl();

    ngOnInit(): void {
    }

    ngOnDestroy(): void {
        this._subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    get formControls() {
        return undefined;
    }

    set sub(subscriptions: Subscription) {
        this._subscriptions.push(subscriptions);
    }
    /**
     * Check if the form control for an error.
     * @param controlName Control Name
     * @returns
     */
    isControlHasError(controlName: string) {
        if (!this.formControls) return false;
        let control: FormControl = this.formControls[controlName];
        if (!control) return false;
        return control.invalid && (control.touched || control.dirty);
    }

    /**
     * Catch FormControl Error
     * @param controlName Control Name
     * @param type Validation Type
     * @returns
     */
    isControlErrorType(controlName: string, type: string) {
        const control: FormControl = this.formControls[controlName];
        if (!control) return false;
        return control.hasError(type) && (control.dirty || control.touched);
    }

    /**
     * Validate the form information.
     * @param formControl
     * @returns
     */
    validSubmit(formGroup: FormGroup) {
        const controls = formGroup.controls;
        if (formGroup.invalid) {
            Object.keys(controls).forEach(controlName => {
                controls[controlName].markAsTouched();
            });
            return false;
        }
        return true;
    }

    subscriber(subscriptions: Subscription | Subscription[]) {
        if (subscriptions instanceof Subscription) {
            this._subscriptions.push(subscriptions);
            return;
        }

        this._subscriptions.push(...subscriptions);
    }
}
