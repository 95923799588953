import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'kt-button-create',
  templateUrl: './button-create.component.html',
})
export class ButtonCreateComponent implements OnInit {

  @Input() message = 'Criar';
  @Input() loading = false;

  constructor() {
  }

  ngOnInit(): void {
  }
}
