import { ApiService } from '../..';
import { BaseCrud } from '../helpers/baseCrud';
import { Cashbox, CashboxDto, CashboxLog } from '@model/tenant/cashbox';
import { LengthAwarePaginator } from '@model/common/length-aware-paginator.model';

export class CashboxRoute extends BaseCrud {
  constructor(public api: ApiService) {
    super(api);
  }

  getUrl() {
    return `${this.api.getApiUrl()}/cashboxes`;
  }

  list(params?: any) {
    return this.api.getHttp().get<LengthAwarePaginator<Cashbox[]>>(this.url(), { params });
  }

  get(id: string) {
    return this.api.getHttp().get<Cashbox>(this.url(id));
  }

  create(data: CashboxDto) {
    return this._create<Cashbox>(data);
  }

  setName(id: string, name: string) {
    return this.api.getHttp().post<Cashbox>(this.url([id, 'name']), { name });
  }

  setAsActive(id: string) {
    return this.api.getHttp().post<Cashbox>(this.url([id, 'active']), {});
  }

  setAsInactive(id: string) {
    return this.api.getHttp().post<Cashbox>(this.url([id, 'inactive']), {});
  }

  history(id: string, params?: any) {
    return this.api.getHttp().get<LengthAwarePaginator<CashboxLog[]>>(this.url([id, 'history']), { params });
  }

  deleteHistory(id: string, historyId: string) {
    return this.api.getHttp().delete<CashboxLog>(this.url([id, 'history', historyId]));
  }

  transfer(id: string, data: any) {
    return this.api.getHttp().post<Cashbox>(this.url([id, 'transfer']), data);
  }
}
