import { Injectable, OnDestroy } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { AlertControl } from 'src/app/helpers/alertControl';

@Injectable()
export abstract class AutoUnsubscribe implements OnDestroy {
  private _subscriptions: Subscription = new Subscription();
  public alertControl = new AlertControl();

  ngOnDestroy() {
    this._subscriptions.unsubscribe();
  }

  public add(...sub: Subscription[]) {
    for (const s of sub) {
      sub && this._subscriptions.add(s);
    }
  }

  set sub(subscription: Subscription | Observable<any>) {
    if (subscription instanceof Observable) {
      subscription = subscription.subscribe();
    }

    this.add(subscription);
  }
}
