import { Observable } from 'rxjs';
import { BaseCrud } from '../helpers/baseCrud';
import { ApiService } from '../../api.service';
import { LengthAwarePaginator } from '@model/common/length-aware-paginator.model';
import { DocumentProfile } from '@model/documents/documentProfile';

export class DocumentProfiles extends BaseCrud {
  constructor(public apiService: ApiService) {
    super(apiService);
  }

  getUrl(): string {
    return `${this.apiService.getApiUrl()}/documents-profiles`;
  }

  list(params?: any): Observable<LengthAwarePaginator<DocumentProfile[]>> {
    return this.apiService.getHttp().get<LengthAwarePaginator<DocumentProfile[]>>(this.url(), { params });
  }

  create(entityInfo: any): Observable<DocumentProfile> {
    return this.apiService.getHttp().post<DocumentProfile>(this.url(), entityInfo);
  }

  update(entityInfo: any): Observable<DocumentProfile> {
    return this.apiService.getHttp().put<DocumentProfile>(this.url(entityInfo.id), entityInfo);
  }

  delete(entityId: string): Observable<void> {
    return this.apiService.getHttp().delete<void>(this.url(entityId));
  }
}
