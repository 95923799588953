<mat-form-field class="mat-form-field-fluid">
  <mat-label>{{ placeholder }}</mat-label>
  <input
    (focus)="control.patchValue('')"
    [formControl]="control"
    [matDatepicker]="picker"
    [max]="maxDate"
    [min]="minDate"
    [required]="required"
    ktMaskDate
    matInput
  />

  <mat-datepicker-toggle matSuffix [for]="picker" />
  <mat-datepicker #picker />

  <mat-error *ngIf="error && error.show">
    <strong *ngIf="error.title">{{ error.title }}</strong> {{ error.message }}
  </mat-error>
</mat-form-field>
