import { GetPhoneTypeResult } from './get-phone-type.model';

export class GetPhoneResult {
  id: string;
  phone: string;
  description?: string;
  phone_type_id?: string;
  type: GetPhoneTypeResult;
  wpp: boolean;

  constructor(data: Partial<GetPhoneResult>) {
    Object.assign(this, data);
    this.type = new GetPhoneTypeResult(data.type);
  }
}
