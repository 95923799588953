import { Observable } from 'rxjs';
import { UploadAttachmentModel } from 'src/app/core/model/uploadAttachment.model';
import { BaseCrud } from '../helpers/baseCrud';
import { ApiService } from '../../api.service';
import { ResponseCurrentSessionUser } from '../../iResponse/iCurrentSessionResponse';
import { iResponseAttachmentResult } from '../../iResponse/iResponseAttachments';

export class CurrentSession extends BaseCrud {
  constructor(public api: ApiService) {
    super(api);
  }

  getUrl() {
    return `${this.api.getApiUrl()}/current-session`;
  }

  permissions(): Observable<string[]> {
    const http = this.api.getHttp();
    return http.get<string[]>(`${this.getUrl()}/user/permissions`);
  }

  user(): Observable<ResponseCurrentSessionUser> {
    const http = this.api.getHttp();
    return http.get<ResponseCurrentSessionUser>(`${this.getUrl()}/user`);
  }

  verifyTwoFactor(secret): any {
    const http = this.api.getHttp();
    return http.post(`${this.getUrl()}/verify-two-factor`, {
      secret,
    });
  }

  attachment() {
    const http = this.api.getHttp();
    return http.get<iResponseAttachmentResult>(`${this.getUrl()}/attachment`);
  }

  uploadUserPhotoAttachment(fileAttachment: UploadAttachmentModel) {
    return this._update<iResponseAttachmentResult>({}, [fileAttachment], `${this.getUrl()}/user-photo-attachment`);
  }

  updateUserInfo(userInfo): Observable<ResponseCurrentSessionUser> {
    const http = this.api.getHttp();
    return http.put<ResponseCurrentSessionUser>(`${this.getUrl()}/user`, userInfo);
  }

  changeUserPassword(passwordsInfo): Observable<ResponseCurrentSessionUser> {
    const http = this.api.getHttp();
    return http.put<ResponseCurrentSessionUser>(`${this.getUrl()}/user/change-password`, passwordsInfo);
  }
}
