<!-- begin:: Header Mobile -->
<div id="kt_header_mobile" class="header-mobile align-items-center" [ngClass]="headerMobileClasses">
  <!--begin::Logo-->
  <a routerLink="/" class='logo-margin'>
	  <img alt="logo" [attr.src]="headerLogo" />
  </a>
  <!--end::Logo-->

  <!--begin::Toolbar-->
  <div class="d-flex align-items-center gap-5">
    <ng-container *ngIf="asideSelfDisplay">
      <!--begin::Aside Mobile Toggle-->
      <button class="btn p-0 burger-icon burger-icon-left" id="kt_aside_mobile_toggle">
        <span></span>
      </button>
      <!--end::Aside Mobile Toggle-->
    </ng-container>

    <kt-user-profile></kt-user-profile>
  </div>
  <!--end::Toolbar-->
</div>
<!-- end:: Header Mobile -->
