<button mat-icon-button color='primary' *ngIf='iconOnly; else editButton'>
  <i class='fas fa-pen-to-square'></i>
</button>

<ng-template #editButton>
  <button
    [disabled]='(loading | async) || isLoading'
    class='mr-2'
    color='primary'
    [ngClass]="{ 'spinner spinner-center spinner-md spinner-primary': (loading | async) || isLoading }"
    mat-raised-button>
    <mat-icon>edit</mat-icon>
    Editar
  </button>
</ng-template>
