import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseApi } from './base-api';
import { TenancyService } from '../common';
import {
  AccountStandardLaunchRoute,
  AccountsPayable,
  AccountsReceivable,
  AuthRoute,
  Balance,
  Benefits,
  BudgetTypes,
  Budgets,
  CategoryRoute,
  CitiesAndStates,
  Clients,
  CurrencyQuotation,
  CurrentSessionRoute,
  Documents,
  EmailType,
  Employees,
  Interns,
  LogsRoute,
  Permissions,
  PhoneType,
  Providers,
  Recurrence,
  References,
  RolesRoute,
  Timezone,
  Users,
  CardRoute,
} from './routes/tenancy';
import { Documents as AdministrativeDocuments } from './routes/tenancy/administrative';
import { TenantRoute } from './routes/common';
import { CashboxRoute } from 'src/app/core/services/api/routes/tenancy/cashbox.route';
import {
  CashflowRoute,
  ExpenseRoute,
  ProfitRoute,
  RevenueRoute,
  CardRoute as CardRouteStats, SettingsRoute,
} from 'src/app/core/services/api/routes/tenancy/stats';
import { ReportsRoute } from './routes/tenancy/reports.route';
import { BankConciliationRoute } from './routes/tenancy/conciliation/bank-conciliation.route';
import { CardConciliationRoute } from './routes/tenancy/conciliation/card-conciliation.route';
import { SpreadsheetsRoute } from '@core/services/api/routes/tenancy/spreadsheets.route';
import { TaxCodeRoute } from '@core/services/api/routes/tenancy/tax-code.route';
import { DocumentsRoute } from '@core/services/api/routes/tenancy/documents.route';
import { IntegrationReportsRoute } from '@core/services/api/routes/tenancy/integration-reports.route';
import { ConfigurationRoute } from '@core/services/api/routes/tenancy/configuration.route';
import { DocumentCategories } from '@core/services/api/routes/tenancy/document-categories';
import { DocumentProfiles } from '@core/services/api/routes/tenancy/document-profiles';
import { SubsidiariesRoute } from '@core/services/api/routes/tenancy/subsidiaries.route';
import { FiscalRpsLoteRoute } from '@core/services/api/routes/tenancy/fiscal-rps-lote.route';
import { AccessKeyRoute } from '@core/services/api/routes/tenancy/access-key.route';
import { NotificationWorkflowRoute } from '@core/services/api/routes/tenancy/workflow/notification-workflow.route';
import { TemplateRoute } from '@core/services/api/routes/tenancy/workflow/template.route';
import { DataImportRoute } from './routes/tenancy/data-import.route';
import { DataExportRoute } from './routes/tenancy/data-export.route';
import { BackupDataRoute } from './routes/tenancy/backup-data.route';

type StatsRoute = {
  revenue: RevenueRoute;
  expense: ExpenseRoute;
  profit: ProfitRoute;
  cashflow: CashflowRoute;
  card: CardRouteStats;
  settings: SettingsRoute;
};

@Injectable({
  providedIn: 'root',
})
export class ApiService extends BaseApi {
  public accessKey: AccessKeyRoute;
  public accountsPayable: AccountsPayable;
  public accountsReceivable: AccountsReceivable;
  public accountStandardLaunch: AccountStandardLaunchRoute;
  public administrativeDocuments: AdministrativeDocuments;
  public backupData: BackupDataRoute;
  public balance: Balance;
  public bankConciliation: BankConciliationRoute;
  public benefits: Benefits;
  public budgets: Budgets;
  public budgetTypes: BudgetTypes;
  public cardConciliation: CardConciliationRoute;
  public card: CardRoute;
  public configuration: ConfigurationRoute;
  public cashbox: CashboxRoute;
  public categoryRoute: CategoryRoute;
  public citiesAndStates: CitiesAndStates;
  public clients: Clients;
  public currencyQuotation: CurrencyQuotation;
  public documents: Documents;
  public documentsLote: DocumentsRoute;
  public documentCategories: DocumentCategories;
  public documentProfiles: DocumentProfiles;
  public emailType: EmailType;
  public employees: Employees;
  public interns: Interns;
  public logsRoute: LogsRoute;
  public permissions: Permissions;
  public phoneType: PhoneType;
  public providers: Providers;
  public recurrence: Recurrence;
  public references: References;
  public reports: ReportsRoute;
  public integrationReports: IntegrationReportsRoute;
  public rolesRoute: RolesRoute;
  public spreadsheets: SpreadsheetsRoute;
  public subsidiaries: SubsidiariesRoute;
  public fiscalRpsLote: FiscalRpsLoteRoute;

  public statsRoute: StatsRoute;
  public taxCode: TaxCodeRoute;
  public tenantRoute: TenantRoute;
  public timeZone: Timezone;
  public users: Users;
  public notificationWorkflow: NotificationWorkflowRoute;
  public template: TemplateRoute;

  public dataImport: DataImportRoute;
  public dataExport: DataExportRoute;

  constructor(public http: HttpClient, public tenancyService: TenancyService) {
    super(http, tenancyService);

    this.accessKey = new AccessKeyRoute(this);
    this.accountsPayable = new AccountsPayable(this);
    this.accountsReceivable = new AccountsReceivable(this);
    this.accountStandardLaunch = new AccountStandardLaunchRoute(this);
    this.administrativeDocuments = new AdministrativeDocuments(this);
    this.auth = new AuthRoute(this);
    this.backupData = new BackupDataRoute(this);
    this.balance = new Balance(this);
    this.bankConciliation = new BankConciliationRoute(this);
    this.benefits = new Benefits(this);
    this.budgets = new Budgets(this);
    this.budgetTypes = new BudgetTypes(this);
    this.cardConciliation = new CardConciliationRoute(this);
    this.card = new CardRoute(this);
    this.configuration = new ConfigurationRoute(this);
    this.cashbox = new CashboxRoute(this);
    this.categoryRoute = new CategoryRoute(this);
    this.citiesAndStates = new CitiesAndStates(this);
    this.clients = new Clients(this);
    this.currencyQuotation = new CurrencyQuotation(this);
    this.currentSession = new CurrentSessionRoute(this);
    this.documents = new Documents(this);
    this.documentsLote = new DocumentsRoute(this);

    this.documentCategories = new DocumentCategories(this);
    this.documentProfiles = new DocumentProfiles(this);

    this.emailType = new EmailType(this);
    this.employees = new Employees(this);
    this.interns = new Interns(this);
    this.logsRoute = new LogsRoute(this);
    this.permissions = new Permissions(this);
    this.phoneType = new PhoneType(this);
    this.providers = new Providers(this);
    this.recurrence = new Recurrence(this);
    this.references = new References(this);
    this.reports = new ReportsRoute(this);
    this.integrationReports = new IntegrationReportsRoute(this);
    this.spreadsheets = new SpreadsheetsRoute(this);
    this.bankConciliation = new BankConciliationRoute(this);
    this.cardConciliation = new CardConciliationRoute(this);
    this.rolesRoute = new RolesRoute(this);
    this.tenantRoute = new TenantRoute(this);
    this.taxCode = new TaxCodeRoute(this);
    this.timeZone = new Timezone(this);
    this.users = new Users(this);

    this.dataImport = new DataImportRoute(this);
    this.dataExport = new DataExportRoute(this);

    this.subsidiaries = new SubsidiariesRoute(this);
    this.fiscalRpsLote = new FiscalRpsLoteRoute(this);
    this.notificationWorkflow = new NotificationWorkflowRoute(this);
    this.template = new TemplateRoute(this);

    this.statsRoute = {
      revenue: new RevenueRoute(this),
      expense: new ExpenseRoute(this),
      profit: new ProfitRoute(this),
      cashflow: new CashflowRoute(this),
      card: new CardRouteStats(this),
      settings: new SettingsRoute(this),
    };
  }
}
