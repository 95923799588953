<div class="elevatedFilter">
    <div class="row">
        <div class="col-md-12">
            <form [formGroup]="filterForm" class="form form-group-seperator-dashed">
                <div class="form-section form-section-first">
                    <div class="row justify-content-end align-items-center">
                        <ng-container *ngIf="!btnHidden">
                            <div class="col-sm-1">
                                <div class="mat-form-field-fluid mat-form-field-wrapper">
                                    <span [inlineSVG]="'./assets/media/svg/icons/General/Filter.svg'" cacheSVG="true"
                                          class="svg-icon svg-icon-sm svg-icon-primary"></span>
                                    Filtros
                                </div>
                            </div>
                        </ng-container>
                        <div *ngFor="let field of advancedFilterService.fields" class="col-md-2">
                            <ng-container [ngSwitch]="field.type">
                                <ng-container *ngSwitchCase="fieldType.Text" [ngTemplateOutlet]="textField"
                                    [ngTemplateOutletContext]="{field: field}">
                                </ng-container>
                                <ng-container *ngSwitchCase="fieldType.Date" [ngTemplateOutlet]="dateField"
                                    [ngTemplateOutletContext]="{field: field}">
                                </ng-container>
                                <ng-container *ngSwitchCase="fieldType.Select" [ngTemplateOutlet]="textSelect"
                                    [ngTemplateOutletContext]="{field: field}">
                                </ng-container>
                                <ng-container *ngSwitchCase="fieldType.BRL" [ngTemplateOutlet]="brlField"
                                    [ngTemplateOutletContext]="{field: field}">
                                </ng-container>
                                <ng-container *ngSwitchCase="fieldType.Checkbox" [ngTemplateOutlet]="checkBox"
                                    [ngTemplateOutletContext]="{field: field}">
                                </ng-container>
                            </ng-container>
                        </div>
                        <ng-container *ngIf="!btnHidden">
                            <div class="mat-form-field-fluid mat-form-field-wrapper">
                                <mat-icon (click)="close()" class="float-left closeButton">arrow_back_ios</mat-icon>
                                <div class="d-flex justify-content-center">
                                    <a href="javascript:void(0);" (click)="clearFilter()">Limpar filtros</a>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>

<ng-template #textField let-field="field">
    <div [formGroup]="filterForm">
        <mat-form-field class="mat-form-field-fluid">
            <mat-label>{{field.label}}</mat-label>
            <input [formControlName]="field.name" matInput type="text" maxlength="50" />
        </mat-form-field>
    </div>
</ng-template>


<ng-template #dateField let-field="field">
    <div [formGroup]="filterForm">
        <mat-form-field class="mat-form-field-fluid">
            <mat-label>{{field.label}}</mat-label>
            <input [formControlName]="field.name" matInput [matDatepicker]="pickerVenc" [placeholder]="field.label">
            <mat-datepicker-toggle matSuffix [for]="pickerVenc"></mat-datepicker-toggle>
            <mat-datepicker #pickerVenc></mat-datepicker>
        </mat-form-field>
    </div>
</ng-template>


<ng-template #textSelect let-field="field">
    <div [formGroup]="filterForm">
        <mat-form-field class="mat-form-field-fluid">
            <mat-label>{{field.label}}</mat-label>
            <mat-select [formControlName]="field.name" >
                <mat-option *ngFor="let option of field.selectOptions" [value]="option.value">
                    {{option.label}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
</ng-template>

<ng-template #brlField let-field="field">
    <div [formGroup]="filterForm">
        <mat-form-field class="mat-form-field-fluid">
            <mat-label>
                <mat-label>{{field.label}}</mat-label>
            </mat-label>
            <input [formControlName]="field.name" matInput currencyMask
                [options]="{ prefix: 'R$', thousands: '.', decimal: ',' }" />
        </mat-form-field>
    </div>
</ng-template>

<ng-template #checkBox let-field="field">
    <div [formGroup]="filterForm">
        <mat-checkbox [formControlName]="field.name" class="mat-form-field-fluid mat-form-field-wrapper">
            <div>
                {{field.label}}
            </div>
        </mat-checkbox>
    </div>
</ng-template>

