import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { NotTenantGuard, TenancyService, TenantGuard } from '@core/services/common';
import { CheckRedirectGuard } from '@core/services/common/guards/check-redirect.guard';
import { EmptyComponentComponent } from './empty-component/empty-component.component';
import { RedirectToTenantGuard } from '@core/services/common/guards/redirect-to-tenant.guard';

const routes: Routes = [
  {
    path: 'error',
    loadChildren: () => import('./views/pages/error/error.module').then(m => m.ErrorModule),
  },
  {
    path: '',
    component: EmptyComponentComponent,
    canActivate: [ CheckRedirectGuard ],
  },
  {
    path: 'admin',
    canActivate: [ NotTenantGuard ],
    loadChildren: () => import('./views/pages/central/central.module').then(m => m.CentralModule),
  },
  {
    path: 'painel',
    canActivate: [ TenantGuard ],
    loadChildren: () => import('./views/pages/tenant/tenant.module').then(m => m.TenantModule),
  },
  {
    path: 'invalido',
    loadChildren: () => import('./views/pages/error/error.module').then(m => m.ErrorModule),
  },
  {
    path: 'inativo',
    loadChildren: () => import('./views/pages/error/error.module').then(m => m.ErrorModule),
  },
  {
    path: '**',
    canActivate: [ RedirectToTenantGuard ],
    pathMatch: 'full',
    component: EmptyComponentComponent,
  },
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
    }),
  ],
  exports: [ RouterModule ],
})
export class AppRoutingModule {
  constructor(private tenancyService: TenancyService) {
    const subdomain = this.tenancyService.makeSubdomain(window.location.hostname);
    this.tenancyService.makeUrl(subdomain);
  }
}
