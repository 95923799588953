import { Observable } from 'rxjs';
import { ApiService } from '../..';
import { CreateAccountStandardLaunch, GetAccountStandardLaunchResult } from '@model/tenant/account-standard-launch';
import { LengthAwarePaginator } from '@model/common/length-aware-paginator.model';

export class AccountStandardLaunchRoute {
  constructor(public apiService: ApiService) {}

  getUrl(): string {
    return `${this.apiService.getApiUrl()}/account-standard-launches`;
  }

  getAll(params?: any): Observable<LengthAwarePaginator<GetAccountStandardLaunchResult[]>> {
    return this.apiService
      .getHttp()
      .get<LengthAwarePaginator<GetAccountStandardLaunchResult[]>>(this.getUrl(), { params });
  }

  get(id: string): Observable<GetAccountStandardLaunchResult> {
    return this.apiService.getHttp().get<GetAccountStandardLaunchResult>(`${this.getUrl()}/${id}`);
  }

  create(data: CreateAccountStandardLaunch): Observable<GetAccountStandardLaunchResult> {
    return this.apiService.getHttp().post<GetAccountStandardLaunchResult>(this.getUrl(), data);
  }

  update(id: string, data: CreateAccountStandardLaunch): Observable<GetAccountStandardLaunchResult> {
    return this.apiService.getHttp().put<GetAccountStandardLaunchResult>(`${this.getUrl()}/${id}`, data);
  }

  delete(id: string): Observable<GetAccountStandardLaunchResult> {
    return this.apiService.getHttp().delete<GetAccountStandardLaunchResult>(`${this.getUrl()}/${id}`);
  }
}
