import { ApiService } from '../..';
import { BaseCrud } from '../helpers/baseCrud';
import { TaxCodeModel } from '@model/taxCode.model';

export class TaxCodeRoute extends BaseCrud {
  constructor(public api: ApiService) {
    super(api);
  }

  getUrl() {
    return `${this.api.getApiUrl()}/tax-code`;
  }

  list() {
    return this._getAll<TaxCodeModel[]>();
  }

  get(id: string) {
    return this.api.getHttp().get<TaxCodeModel>(this.url(id));
  }
}
